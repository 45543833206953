import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PiattoProntoMagazzino} from "../../models/piattopronto";
import {InsdatiService} from "../../services/insdati/insdati.service";
import {DialogBasicComponent} from "../dialog-basic/dialog-basic.component";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-dialog-elimina-piatto',
  templateUrl: './dialog-elimina-piatto.component.html',
  styleUrls: ['./dialog-elimina-piatto.component.scss']
})
export class DialogEliminaPiattoComponent implements OnInit {
  qtaArr: number[] = [];
  qta_scelta: number = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      piatto: PiattoProntoMagazzino,
    },
    private insDatiService: InsdatiService,
    private dialogRef: MatDialogRef<DialogEliminaPiattoComponent>,
    private commonService : CommonService,
  ) {
  }


  ngOnInit(): void {

    this.qtaArr = [];
    for (let i = 1; i <= this.data.piatto.quantita; i++) {
      this.qtaArr.push(i);
    }

  }

  aggiornaQtaUsata() {

    let formData = {
      'quantita': this.qta_scelta,
      'id': this.data.piatto.id,
      'tipo': this.data.piatto.tipo,
    };

    this.insDatiService.post_aggiorna_acquisto(formData).then((data) => {
      console.log(data);
      if (data) {
        this.dialogRef.close(true);
         this.commonService.alertSuccess(data.messages);

      }
    }, err => {
      console.log(err);
    });

  }
}
