import {Component, Input, OnInit} from '@angular/core';
import {Notizia} from "../../models/notizia";
import {NewsService} from "../../services/news/news.service";

@Component({
  selector: 'app-news-latest',
  templateUrl: './news-latest.component.html',
  styleUrls: ['./news-latest.component.scss']
})
export class NewsLatestComponent implements OnInit {

  news !:Notizia ; // decorate the property with @Input()

  constructor(
    private newsService :  NewsService,
  ) { }

  ngOnInit(): void {
    this.loadLatestNews();
  }

  loadLatestNews(){
    this.newsService.news_ultima().then(risp=>{
      if(risp){
        this.news =  risp;
      }
    })
  }

}
