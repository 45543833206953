import {Component, OnInit} from '@angular/core';
import {UniversitaService} from "../../services/universita/universita.service";
import {Lezione} from "../../models/lezione";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-universita',
  templateUrl: './universita.component.html',
  styleUrls: ['./universita.component.scss']
})
export class UniversitaComponent implements OnInit {

  lezioni: Lezione[] | null = null;

  constructor(
    private universitaService: UniversitaService,
    private  commonService: CommonService,
  ) {
    this.commonService.setPageTitle('universita');


  }

  ngOnInit(): void {
    this.loadLezioni();
  }


  loadLezioni() {
    this.universitaService.get_lezioni().then(risp => {
      if (risp) {
        this.lezioni = risp;
      }
    }, err => {
      //errore chiamata
      console.log('err',err.status);
      //probabilmente 401 unauthorized (la pagina è visibile anche da guest)
      //imposto lezioni ad array vuoto, mostra il messaggio "nessuna lezione disponibile, chiama in segrateria"
      this.lezioni = [];

    })
  }
}
