<app-blocco-testo
  [titolo]="'grafici.titolo'|translate"
  [testo]="'grafici.descrizione'|translate"
></app-blocco-testo>



<div class="grafici-content" *ngIf="tipoGrafici">


  <mat-card class="card">

    <mat-card-content  >

      <div class="list" *ngIf="tipoGrafici.length">

        <mat-nav-list>
          <mat-list-item   *ngFor="let grafico of tipoGrafici"
                           [routerLink]="['/graph-detail', grafico]"
          >
            <div matLine  >
              {{'generico.misure.'+grafico+'.nome'|translate}}
            </div>
            <button mat-icon-button>
              <mat-icon>chevron_right</mat-icon>
            </button>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-nav-list>

      </div>
      <div class="no-result-list" *ngIf="!tipoGrafici.length">
        {{'generico.nessun_dato_disponibile'|translate}}
      </div>

    </mat-card-content>
  </mat-card>


</div>
