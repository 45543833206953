import {Component, OnInit} from '@angular/core';
import {NewsService} from "../../services/news/news.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-news-categorie',
  templateUrl: './news-categorie.component.html',
  styleUrls: ['./news-categorie.component.scss']
})
export class NewsCategorieComponent implements OnInit {

  categorie: any[] = [];
  iconlist: any = environment.icone_news ;
  constructor(
    private newsService: NewsService,
  ) {
  }

  ngOnInit(): void {

    this.caricaCategorie();

  }

  caricaCategorie() {

    this.newsService.lista_categorie().then(risp => {
      this.categorie.push(...risp);
     // console.log('risp123', this.categorie);
    }, errore => {
      console.log(errore);
    });


  }

}
