import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UniversitaService} from "../../services/universita/universita.service";
import {Lezione} from "../../models/lezione";

@Component({
  selector: 'app-universita-lezione',
  templateUrl: './universita-lezione.component.html',
  styleUrls: ['./universita-lezione.component.scss']
})
export class UniversitaLezioneComponent implements OnInit {
    lezione!: Lezione;
    private lezione_id !: string | null;

    constructor(
      private route: ActivatedRoute,
      private universitaService: UniversitaService,
      private router: Router,
    ) {
    }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      console.log('params', params);
      this.lezione_id = params.get('id_lezione');

      if (this.lezione_id) {
        this.loadLezione();
      } else {
        //non ho il parametro id, torno indietro a lezioni list
        this.router.navigate(['/universita']);
      }
    });
  }

  loadLezione() {
    if (this.lezione_id) {
      this.universitaService.get_lezione(this.lezione_id).then(risp => {
        this.lezione = risp;
      }, errore => {
        console.log(errore);
      });
    }


  }

}
