<div class="singolo-messaggio mb-3" [class]="messaggio.tipo?'dx':'sx'">

  <div class="singolo-messaggio-inner">
    <div class=" card">
      <div class="card-body">
        {{messaggio.testo}}
      </div>
    </div>
    <div class="data">{{messaggio.giorno | date}}</div>
  </div>

</div>
