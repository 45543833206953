import {Component, HostListener, OnInit} from '@angular/core';
import {DeviceService} from "../../services/device/device.service";
import {GeneralService} from "../../services/general/general.service";
import {CommonService} from "../../services/common/common.service";
import {AppComponent} from "../../app.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-banner-a2hs',
  templateUrl: './banner-a2hs.component.html',
  styleUrls: ['./banner-a2hs.component.scss']
})
export class BannerA2hsComponent implements OnInit {

  bannerLang: any;

  localShowButton: boolean = false;
  isInsideWebAppHomeScreen: boolean = false;

  constructor(
    private deviceService: DeviceService,
    private commonService: CommonService,
    private appComponent: AppComponent,
    private translateService: TranslateService,
  ) {


    //se il dispositivo è un telefono ios o andorid mostro il bottone sempre, cambia l'azione poi in fase di click (automatica o guida)
    if (this.deviceService.isIOS() || this.deviceService.isAndroid()) {
      this.localShowButton = true;
    }

    this.isInsideWebAppHomeScreen = this.deviceService.isInsideWebAppHomeScreen();

  }

  ngOnInit(): void {
  }

  closeA2HS() {
    console.log('closeA2HS CLOSE');
    //hai interagito con il bottone, quindi lo hai letto, posso toglierlo
    this.localShowButton = false;
  }

  async addToHomeScreenClick() {

    //chiamata finta per essere sicuri che le traduzioni siano caricate
    await this.translateService.get('dummyTranslation').toPromise().then();
    this.bannerLang = this.translateService.instant('components.bannerhomescreen');
    console.log('this.bannerLang', this.bannerLang);

    if (this.deviceService.isAndroid() && this.appComponent.deferredPrompt) {
      //è android ed è possibile fare l'azione automatica, triggero l'azione chrome
      this.appComponent.addToHomeScreenChrome();
    } else if (this.deviceService.isAndroid() && !this.appComponent.deferredPrompt) {
      //è android ma NON è possbile fare l'azione automaitca, apro dialogbox
      this.commonService.alertMsg(this.bannerLang.titolo_popup_spiegazione, this.bannerLang.spiegazione_android);
    } else if (this.deviceService.isIOS()) {

      this.commonService.alertMsg(this.bannerLang.titolo_popup_spiegazione, this.bannerLang.spiegazione_ios);
    }


    //hai interagito con il bottone, quindi lo hai letto, posso toglierlo
    this.localShowButton = false;

  }


}
