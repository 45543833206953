<!--rinnova consulenza-->
<div class="rinnova-box">

<mat-card class="          full  flat">

  <mat-card-content>

    <div  class="row nopaddingrow">
      <div class="col-8 align-self-center" >
        <div class="testo  ">
          <h3 class="titolo" [innerHTML]="'components.rinnova.titolo' | translate"></h3>

        </div>
      </div>
      <div class="col-4 align-self-end" >
        <div class="immagine">
          <img class="img-fluid FotoLemme" src="assets/images/homepage/lemme-banner.png">
        </div>
      </div>
    </div>


  </mat-card-content>

</mat-card>






  <app-bottone-lemme
    (click)="launchRinnova()"

    [titolo]="'components.rinnova.bottone'|translate"
    [sottotitolo]="'components.rinnova.bottone_sottotitolo'|translate"
    [icona]="'shop_two'"
    [color]="'third'"
  ></app-bottone-lemme>



</div>
