import {Component, Input, OnInit} from '@angular/core';
import {Notizia} from "../../models/notizia";
import {ActivatedRoute, Router} from "@angular/router";
import {NewsService} from "../../services/news/news.service";
import {environment} from "../../../environments/environment";
import {CommonService} from "../../services/common/common.service";
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {
  news!: Notizia;
  listicon: any = environment.icone_news;

  constructor(
    private route: ActivatedRoute,
    private newsService: NewsService,
    private router: Router,
    private commonService: CommonService,
    private title:Title,
  ) {
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      let id = params.get('id');
      console.log('params', params);

      if (id) {
        this.newsService.news_single(id).then(risp => {

          this.news = risp;
          //imposto opengraph

          this.title.setTitle( this.news.titolo );


        }, errore => {
          console.log(errore);
        });
      } else {
        //non ho il parametro id, torno indietro a news list
        this.router.navigate(['/news-list']);
      }
    })


  }


}
