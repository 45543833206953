import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-basic',
  templateUrl: './dialog-basic-confirm.component.html',
  styleUrls: ['./dialog-basic-confirm.component.scss']
})
export class DialogBasicConfirmComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<DialogBasicConfirmComponent>,

    @Inject(MAT_DIALOG_DATA) public data: {
    class:string,
    title:string,
    message:string,
    button:string,
      tipo : string,
  }
  ) {

  }

  icon:string = '';

  ngOnInit(): void {

    this.checkIcon();

  }
  checkIcon(){
    console.log(this.data);
    if(this.data.tipo == 'error'){
      this.icon = 'error_outline';
    }

    if(this.data.tipo == 'success'){
      this.icon = 'new_releases';
    }


  }

  conferma(){
    this.dialogRef.close(true);
  }

}

