<div class="audioricette-all">
  <app-blocco-testo
    [titolo]="'audioricette.titolo' | translate"
    [testo]="'audioricette.descrizione' | translate"

  ></app-blocco-testo>


  <div class="loader" *ngIf="audioricette && audioricette.length == 0">
    <mat-spinner></mat-spinner>
  </div>


  <div class="audioricette-all-content" *ngIf="audioricette && audioricette.length>0">


    <mat-card>
      <mat-card-content>

        <div class="row">
          <div class="col-md-12 col-12" *ngFor="let audioricetta of audioricette">
            <div class="audioricetta-all-content-item">
              <app-audioricetta-loop [audioricetta]="audioricetta"></app-audioricetta-loop>
            </div>
          </div>
        </div>

      </mat-card-content>
    </mat-card>


  </div>
</div>
