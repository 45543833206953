import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-chat-con-lemme',
  templateUrl: './chat-con-lemme.component.html',
  styleUrls: ['./chat-con-lemme.component.scss']
})
export class ChatConLemmeComponent implements OnInit {

  lemme_app_iframe_secure: SafeResourceUrl;
  lemme_app_iframe:string = '';
  constructor(
    public sanitizer:DomSanitizer,
    public commonService:CommonService,
  ) {

    this.commonService.setPageTitle('chat');



    this.lemme_app_iframe = environment.lemme_chat_link;
    this.lemme_app_iframe_secure = this.sanitizer.bypassSecurityTrustResourceUrl(this.lemme_app_iframe );

  }

  ngOnInit(): void {
  }

  openChat() {
    this.commonService.openURL(this.lemme_app_iframe);
  }

  openWA() {
    let urlWA = environment.lemme_whatsapp_url_prefix + environment.lemme_whastapp_number;
    this.commonService.openURL(urlWA);
  }

}
