<div class="piatto-box-cont">

  <h5 class=" portata text-uppercase">{{titolo}}</h5>


  <div class="row justify-content-center align-items-top">
    <div class="col-3 col-sm-12">


      <div class="img-cont">
        <img *ngIf="piatto.imgFull" [src]="piatto.imgFull" class="img-fluid" alt="">
        <img *ngIf="!piatto.imgFull" src="assets/images/piatti/noimage.jpg" class="img-fluid" alt="">
      </div>

    </div>

    <div class="col-9 col-sm-12">
      <h4 class="titolo">
        {{piatto.nome}}
      </h4>

      <div class="azioni">

        <a class="d-flex align-item-center"
           [routerLink]="['/ricetta-detail',piatto.id]"

           *ngIf="piatto.tipologia == 'ricetta'">
          <mat-icon>menu_book</mat-icon>
          <span>{{'generico.vedi_ricetta'  | translate}}</span>
        </a>

        <a class="d-flex align-item-center" (click)="showIngredienti()" *ngIf="piatto.ingredienti.length">
          <mat-icon>info</mat-icon>
          <span>{{'generico.ingredienti'  | translate}}</span>
        </a>

<!--
        <pre>{{(menu.cambioPiatto && !['ricetta','prodotto'].includes(piatto.tipologia))}}</pre>
    -->
        <a class="d-flex  align-item-center" (click)="cambioPiatto()"
           *ngIf="menu.cambioPiatto && !['ricetta','prodotto'].includes(piatto.tipologia)">
          <mat-icon>next_plan</mat-icon>
          <span>{{'components.piatto_box.cambio_piatto'  | translate}}</span>
        </a>
      </div>


      <div class="audioricetta" *ngIf="piatto.audio_ricetta_id">
        <button class="btn-lemme-full" [routerLink]="['/audio-ricette-detail',piatto.audio_ricetta_id]">
          {{'components.piatto_box.audioricetta'  | translate}}
        </button>
      </div>
    </div>
  </div>


</div>
