import {Component, OnInit} from '@angular/core';
import {ChartsService} from "../../services/charts/charts.service";
import {environment} from "../../../environments/environment";
import {ChartOptions} from "chart.js";
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "../../services/language/language.service";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss']
})
export class GraphsComponent implements OnInit {


  tipoGrafici = environment.misure_possibili;





  constructor(
    private commonService: CommonService,


  ) {
    this.commonService.setPageTitle('grafici');


  }

  ngOnInit(): void {

  }





}
