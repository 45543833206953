import {Component, OnInit} from '@angular/core';
import {InsdatiService} from "../../services/insdati/insdati.service";
import {UserService} from "../../services/user/user.service";
import {CommonService} from "../../services/common/common.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {User} from "../../models/user";
import {RicetteService} from "../../services/ricette/ricette.service";
import {BroadcasterService} from "../../services/broadcaster/broadcaster.service";
import {GeneralService} from "../../services/general/general.service";
import {Ricetta} from "../../models/ricetta";

@Component({
  selector: 'app-inserisci-ricetta',
  templateUrl: './inserisci-ricetta.component.html',
  styleUrls: ['./inserisci-ricetta.component.scss']
})
export class InserisciRicettaComponent implements OnInit {

  mode = 'new';

  ricetta: Ricetta | any = null;


  user!: User;
  formData: any = {
    'carica_foto':false,
    'foto': '',
    'foto_url': '',
    'titolo': '',
    'descrizione': '',
    'ingredienti': [],
    'passaggi': [],

    'abilitata': true,

    'tags': [],
    'pasti': [],

  }

  tags: any[] = [];
  pasti: string[] = [];

  returnZero() {
    return 0
  };


  constructor(
    private ricetteService: RicetteService,
    private userService: UserService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private broadcaster: BroadcasterService,
  ) {

    this.commonService.setPageTitle('ins_ricetta');

    this.init();


  }

  /* */
  showLoader() {
    //this.loaderComponent.showLoader();
    this.broadcaster.is_loader_shown(true)
  }

  hideLoader() {
    // this.loaderComponent.hideLoader();
    this.broadcaster.is_loader_shown(false)
  }


  init() {


    this.getInsRicettaInitInfo();
    this.userService.local_user().then(user => {
      this.user = user;
    })
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      let id = params.get('id');
      console.log('params', params);

      if (id) {
        this.ricetteService.ricette_single(id).then(risp => {

          this.mode = 'edit'
          this.ricetta = new Ricetta(risp);

          this.formData.titolo = this.ricetta.titolo;
          this.formData.descrizione = this.ricetta.descrizione;
          if (risp.has_foto) {
            this.formData.foto_url = risp.foto;
            this.formData.carica_foto = true;
          }

          this.formData.ingredienti = this.ricetta.ingredienti;
          this.formData.passaggi = this.ricetta.passaggi;

          this.formData.tags = this.ricetta.tagsAsArrayIDs();
          this.formData.pasti = this.ricetta.pasti;
          this.formData.abilitata = this.ricetta.abilitata;


        }, errore => {
          console.log(errore);
        });
      } else {
        //non ho il parametro id - è un edit normale non faccio nulla
      }
    })

  }

  imageError!: string;
  isImageSaved!: boolean;
  cardImageBase64!: string | null;

  fileChange(fileInput: any): void {
    this.imageError = '';
    if (fileInput.target.files && fileInput.target.files[0]) {
      //NON FACCIO CONTROLLI li fa tutti laravel


      const reader = new FileReader();
      reader.readAsDataURL(fileInput.target.files[0]);
      let that = this;

      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function (rs: any) {
          {


            const imgBase64Path = e.target.result;
            that.cardImageBase64 = imgBase64Path;
            that.isImageSaved = true;

            that.formData.foto = imgBase64Path;
            // this.previewImagePath = imgBase64Path;
            return true;

          }

        };


      }

      return;
    }
  }

  public removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  private getInsRicettaInitInfo() {
    console.log('insRicettaInit!!');
    this.ricetteService.init().then(res => {
      console.log('INITres', res);
      this.pasti = res.pasti;
      this.tags = res.tags;
    });
  }

  changeCheckObj($event: any, tag: any, tags: any[]) {

    let indx = tags.indexOf(tag.id);

    if ($event.checked == true) {
      tags.push(tag.id);
    } else {
      tags.splice(indx, 1);
    }
  }


  changeCheckStr($event: any, tag: any, tags: string[]) {

    let indx = tags.indexOf(tag);

    if ($event.checked == true) {
      tags.push(tag);
    } else {
      tags.splice(indx, 1);
    }
  }


  sendData() {

    this.commonService.showLoader();

    this.ricetteService.send_ricetta(this.formData).then(risp => {
      //success
      console.log('sendData-success', risp);


      if (risp && risp.messages) {
        this.commonService.alertSuccess(risp.messages);
        this.router.navigateByUrl('/lista-ricette');
      }


      this.commonService.hideLoader();


    }, err => {
      //reject
      console.log('sendData-error', err);
      this.commonService.hideLoader();

    });
  }


  sendDataEdit() {

    this.commonService.showLoader();

    this.ricetteService.send_ricetta_edit(this.ricetta.id, this.formData).then(risp => {
      //success
      console.log('sendData-success', risp);


      if (risp && risp.messages) {
        this.commonService.alertSuccess(risp.messages);
        this.router.navigateByUrl('/lista-ricette');
      }


      this.commonService.hideLoader();


    }, err => {
      //reject
      console.log('sendData-error', err);
      this.commonService.hideLoader();

    });
  }


  getImageBase64() {
    if (this.cardImageBase64) {
      return this.cardImageBase64;
    }
    if (this.formData && this.formData.foto_url) {
      return this.formData.foto_url
    }

  }
}

