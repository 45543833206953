<!--ultimi valori-->
<mat-card class="card-recap-valori  full flat  " *ngIf="is_user_logged && ultimi_valori && user">

  <mat-card-content>

    <div class="text-center">

      <div class="inner-text">
      <div class="head-recap">
        <div class="block-img">
          <img class="img-responsive icona centrata" src="assets/images/homepage/icona-utente.png">
        </div>
        <h2 class="titolo"> {{ 'generico.saluto' | translate }} {{user.nome}}  </h2>
      </div>
      <div *ngIf="ultimi_valori  " class="ultimo-valore">

        <h4 class="data">{{ (ultimi_valori.ultima.data | date)}}</h4>

        <h4 class="titolo">{{ 'components.ultimivalori.ultimo_peso_registrato' | translate }}
          <strong class="peso">
            {{ultimi_valori.ultima.peso}}  ({{ 'generico.misure.peso.um' | translate }})
          </strong>
        </h4>
      </div>
      <div class="datiUtente"
           *ngIf="user && user.percorso && user.percorso.nome  && user.scadenza">
        <div class="singolo-valore">

          <span class="titolo">{{'profilo.campi.percorso' | translate}}</span>
          &nbsp;
          <strong class="valore">{{user.percorso.nome}}</strong>

        </div>

        <div class="divisore"></div>

        <div class="singolo-valore">

          <span class="titolo">{{'profilo.campi.scadenza' | translate}}</span>
          &nbsp;
          <strong class="valore">{{  (user.scadenza)  | date }}</strong>

        </div>
      </div>
      </div>

      <div class="cta-inserisci">

      <app-bottone-lemme [titolo]="'generico.inserisci_dati'|translate"
                         [sottotitolo]="'generico.inserisci_dati_sottotitolo'|translate"
                         [icona]="'edit_note'"
                         [routerLink]="'/inserisci-dati'"
                         [color]="'primary'"
      ></app-bottone-lemme>

      </div>


    </div>


  </mat-card-content>

</mat-card>
