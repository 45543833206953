<div class="general-app-container" [class.app-is-mobile]="isMobile" [class.app-is-desktop]="!isMobile">

  <app-loader-pagina></app-loader-pagina>

  <mat-toolbar class="general-app-toolbar">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <h1 class="example-app-name">{{title}}</h1>

    <div class="toolbar-spacer"></div>

    <div class="language-selector" *ngIf="current_language_obj">

      <div class="language-selector-field">
        <span class="language-selector-inline"  >



          <span class="flag-cont" *ngFor="let lang of languages"  (click)="changeLanguage(lang.code)"  >
              <flag  size="s" [country]="lang.flag_code"></flag>
          </span>

        </span>
      </div>
    </div>

  </mat-toolbar>
  <mat-sidenav-container autosize="true" class="example-sidenav-container">
    <mat-sidenav #snav
                 [opened]="isMobile ?  false : true"
                 [mode]="isMobile ? 'over' : 'side'"
                 [fixedInViewport]="isMobile" fixedTopGap="56">


      <mat-nav-list>

        <ng-container *ngFor="let link of linksMenu">

          <mat-list-item [ngClass]="link.evidenza?'evidenza':''" *ngIf="link.canShow"  (click)="navigateMenu(link)" routerLinkActive="active">

            <a matLine (click)="clickLinkMenu( )">
              {{ 'titoli_menu.'+link.title|translate }}
            </a>

            <mat-icon>{{link.icon}}</mat-icon>

            <mat-divider></mat-divider>

          </mat-list-item>

        </ng-container>
        <mat-list-item (click)="logout()" *ngIf="is_user_logged">
          <a matLine (click)="clickLinkMenu( )">Logout</a>
          <mat-icon>logout</mat-icon>
          <mat-divider></mat-divider>
        </mat-list-item>

      </mat-nav-list>

      <div class="versioneApp">v{{versioneApp}}</div>

      <div class="sidebar-spacer">&nbsp;</div>




    </mat-sidenav>
    <mat-sidenav-content>

      <div [class]="'content-container'+' '+additionalContentClass">
        <router-outlet></router-outlet>
      </div>


    </mat-sidenav-content>
  </mat-sidenav-container>


  <div class="footer-app">
    <app-messaggio-personalizzato></app-messaggio-personalizzato>
  </div>

</div>






