import {Component, OnInit} from '@angular/core';
import {GeneralService} from "../../services/general/general.service";
import {CommonService} from "../../services/common/common.service";
import {BroadcasterService} from "../../services/broadcaster/broadcaster.service";
import {UserService} from "../../services/user/user.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-messaggio-personalizzato',
  templateUrl: './messaggio-personalizzato.component.html',
  styleUrls: ['./messaggio-personalizzato.component.scss']
})
export class MessaggioPersonalizzatoComponent implements OnInit {
  messaggio_personalizzato: any;


  constructor(
    private generalService: GeneralService,
    private commonService: CommonService,
    private broadcaster: BroadcasterService,
    private userService: UserService,
    private router: Router,
  ) {

      this.loadMsgPersonalizzato();

    //ascolto eventuali cambi stato utente
    this.broadcaster.is_user_logged$.subscribe(is_logged => {
      //è cambiato lo stato dell'utente, rifaccio la chiamata

        this.loadMsgPersonalizzato();

    });
  }

  ngOnInit() {


  }

  async loadMsgPersonalizzato() {
    this.generalService.messaggio_personalizzato().then(resp => {

      this.messaggio_personalizzato = resp;
    });
  }



  apriModalMsgPersonalizzato() {
    // console.log(this.messaggio_personalizzato);
    this.commonService.alertMsg(this.messaggio_personalizzato.titolo, this.messaggio_personalizzato.messaggio);
  }

  apriShareNotizia() {
    if (!this.messaggio_personalizzato.notizia_id) {
      return;
    }
    this.router.navigateByUrl('news-detail/' + this.messaggio_personalizzato.notizia_id)
  }
}
