
<div class="audioricetta-container" *ngIf="audioricetta">


  <h2 *ngIf="audioricetta.titolo" class="titolo d-flex justify-content-center align-items-center">
     <div class="testo">{{audioricetta.titolo}}</div>
  </h2>

  <div class="image-content" *ngIf="audioricetta.immagine  ">
    <img [src]="audioricetta.immagine" class="img-fluid">
  </div>




  <div class="descrizione-contenitore">
    <div class="descrizione-inner" [innerHTML]="audioricetta.descrizione"></div>
  </div>

  <div class="audio-content" *ngIf="audioricetta.audio">
    <app-audio-player [audio]="audioricetta.audio"></app-audio-player>
  </div>





  <div class="contattami-cont">
    <h2 class="text-center">{{'news_detail.contattami_titolo'|translate}}</h2>
    <app-contatti-home></app-contatti-home>
  </div>


</div>



