import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./pages/login/login.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {NewsComponent} from "./pages/news/news.component";
import {IsLoggedGuard} from "./guards/is-logged.guard";
import {ProfileComponent} from "./pages/profile/profile.component";
import {IsGuestGuard} from "./guards/is-guest.guard";
import {NewsDetailComponent} from "./pages/news-detail/news-detail.component";
import {SettingsComponent} from "./pages/settings/settings.component";
import {FaqComponent} from "./pages/faq/faq.component";
import {RegoleComponent} from "./pages/regole/regole.component";
import {InformazioniComponent} from "./pages/informazioni/informazioni.component";
import {TestComponent} from "./pages/test/test.component";
import {SupportComponent} from "./pages/support/support.component";
import {SupportDetailComponent} from "./pages/support-detail/support-detail.component";
import {HistoryComponent} from "./pages/history/history.component";
import {HistoryDetailComponent} from "./pages/history-detail/history-detail.component";
import {GraphsComponent} from "./pages/graphs/graphs.component";
import {GraphDetailComponent} from "./pages/graph-detail/graph-detail.component";
import {InserisciDatiComponent} from "./pages/inserisci-dati/inserisci-dati.component";
import {ProssimiMenuComponent} from "./pages/prossimi-menu/prossimi-menu.component";
import {UniversitaComponent} from "./pages/universita/universita.component";
import {UniversitaLezioneComponent} from "./pages/universita-lezione/universita-lezione.component";
import {UniversitaCapitoloComponent} from "./pages/universita-capitolo/universita-capitolo.component";
import {CambioPiattoComponent} from "./pages/cambio-piatto/cambio-piatto.component";
import {PiattiAcquistatiComponent} from "./pages/piatti-acquistati/piatti-acquistati.component";
import {ListaSpesaComponent} from "./pages/lista-spesa/lista-spesa.component";
import {ChatConLemmeComponent} from "./pages/chat-con-lemme/chat-con-lemme.component";
import {NewsCategoriesComponent} from "./pages/news-categories/news-categories.component";
import {PiattiProntiComponent} from "./pages/piatti-pronti/piatti-pronti.component";
import {ChiamaLemmeComponent} from "./pages/chiama-lemme/chiama-lemme.component";
import {AudioRicetteComponent} from "./pages/audio-ricette/audio-ricette.component";
import {AudioRicettaDetailComponent} from "./pages/audio-ricetta-detail/audio-ricetta-detail.component";
import {ListaRicetteComponent} from "./pages/lista-ricette/lista-ricette.component";
import {InserisciRicettaComponent} from "./pages/inserisci-ricetta/inserisci-ricetta.component";
import {IsSottofaseGuard} from "./guards/is-sottofase.guard";
import {RicettaDetailComponent} from "./pages/ricetta-detail/ricetta-detail.component";
import {ChatAiComponent} from "./pages/chat-ai/chat-ai.component";
import {GenioAiComponent} from "./pages/genio-ai/genio-ai.component";
import {CanAiGuard} from "./guards/can-ai.guard";


//const routes: Routes = [];


const routes: Routes = [

  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},

  {path: 'login', component: LoginComponent, canActivate: [IsGuestGuard]},
  {path: 'dashboard', component: DashboardComponent},

  {path: 'news', redirectTo: '/news-categories', pathMatch: 'full'},

  {path: 'news-categories', component: NewsCategoriesComponent},
  {path: 'news-list', component: NewsComponent},
  {
    path: 'news-list/:categoryid',
    component: NewsComponent,

  },


  {
    path: 'news-detail/:id',
    component: NewsDetailComponent,

  },


  {path: 'piatti-pronti', redirectTo: 'piatti-pronti/1'},
  {path: 'piatti-pronti/:fase', component: PiattiProntiComponent, canActivate: [IsLoggedGuard]},


  {path: 'inserisci-dati', component: InserisciDatiComponent, canActivate: [IsLoggedGuard]},
  {path: 'prossimi-menu', component: ProssimiMenuComponent, canActivate: [IsLoggedGuard]},
  {path: 'cambio-piatto', component: CambioPiattoComponent, canActivate: [IsLoggedGuard]},
  {path: 'piatti-acquistati', component: PiattiAcquistatiComponent, canActivate: [IsLoggedGuard]},

  {path: 'faq', component: FaqComponent},

  {path: 'regole', component: RegoleComponent},
  {path: 'regole/:fase', component: RegoleComponent},


  {path: 'informazioni', component: InformazioniComponent},


  {path: 'profile', component: ProfileComponent, canActivate: [IsLoggedGuard]},
  {path: 'settings', component: SettingsComponent, canActivate: [IsLoggedGuard]},
  {path: 'support', component: SupportComponent, canActivate: [IsLoggedGuard]},
  {path: 'support-detail/:id', component: SupportDetailComponent, canActivate: [IsLoggedGuard]},

  {path: 'history', component: HistoryComponent, canActivate: [IsLoggedGuard]},
  {path: 'history-detail/:id', component: HistoryDetailComponent, canActivate: [IsLoggedGuard]},

  {path: 'graphs', component: GraphsComponent, canActivate: [IsLoggedGuard]},
  {path: 'graph-detail/:stat', component: GraphDetailComponent, canActivate: [IsLoggedGuard]},

  {path: 'universita', component: UniversitaComponent},
  {path: 'universita/lezione/:id_lezione', component: UniversitaLezioneComponent},
  {path: 'universita/capitolo/:id_lezione/:id_capitolo', component: UniversitaCapitoloComponent},


  {path: 'lista-spesa', component: ListaSpesaComponent, canActivate: [IsLoggedGuard]},


  {
    path: 'lista-ricette',
    component: ListaRicetteComponent,
    canActivate: [IsLoggedGuard]
    /*
    canActivate: [IsSottofaseGuard],
    data: {
      sottofase: 5
    }
    */

  },
  {
    path: 'inserisci-ricetta',
    component: InserisciRicettaComponent,
    canActivate: [IsLoggedGuard]
    /*
    canActivate: [IsSottofaseGuard],
    data: {
      sottofase: 5
    }
    */

  },
  {
    path: 'modifica-ricetta/:id',
    component: InserisciRicettaComponent,
    canActivate: [IsLoggedGuard]
  },
  {path: 'ricetta-detail/:id', component: RicettaDetailComponent, canActivate: [IsLoggedGuard]},

  {path: 'genio-ai', component: GenioAiComponent, canActivate: [IsLoggedGuard, CanAiGuard]},
  {path: 'chat-ai', component: ChatAiComponent, canActivate: [IsLoggedGuard , CanAiGuard]},

  {path: 'chat-con-lemme', component: ChatConLemmeComponent},
  {path: 'chiama-lemme', component: ChiamaLemmeComponent},
  {path: 'audio-ricette', component: AudioRicetteComponent, canActivate: [IsLoggedGuard]},
  {path: 'audio-ricette-detail/:id', component: AudioRicettaDetailComponent, canActivate: [IsLoggedGuard]},

  {
    path: 'test',
    component: TestComponent,

  },

//404
  {path: '**', redirectTo: '/dashboard',}
];


@NgModule({
//imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(routes, {useHash: true})  // .../#/crisis-center/
  ],


  exports: [RouterModule]
})
export class AppRoutingModule {


}
