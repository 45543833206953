<mat-card *ngIf="lezione">
  <mat-card-title>{{lezione.titolo}}</mat-card-title>
  <mat-card-content>
    <div class="universita-lezione-cont">
      <div class="img-cont">
        <img [src]="lezione.immagine" class="img-fluid"/>
      </div>
      <div class="descrizione">
        {{lezione.descrizione}}
      </div>

      <div class="universita-menu-inner">


        <div class="list" *ngIf="lezione.capitoli.length">

          <mat-nav-list>
            <mat-list-item *ngFor="let capitolo of lezione.capitoli"
                           [routerLink]="['/universita/capitolo', lezione.id, capitolo.id]"
            >
              <div matLine>
                <span class="titolo">{{lezione.titolo}}</span>
              </div>
              <button mat-icon-button>
                <mat-icon>chevron_right</mat-icon>
              </button>
              <mat-divider></mat-divider>
            </mat-list-item>
          </mat-nav-list>

        </div>
        <div class="no-result-list" *ngIf="!lezione.capitoli.length">
          {{'generico.nessun_risultato'|translate}}
        </div>


      </div>

    </div>
  </mat-card-content>
</mat-card>
