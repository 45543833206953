<mat-form-field>
    <mat-label>{{ titolo  }}</mat-label>

    <div class="line-input">

        <input name="titolo" type="text" matInput
               [(ngModel)]="addValue"  (keyup.enter)="addSingleVal()" />
        <mat-icon matSuffix mat-icon-button (click)="addSingleVal()">add</mat-icon>
    </div>

    <div class="single-value" *ngFor="let singlevalue of value;  index as i;">

        <div class="singlevaluetext  "  >{{i+1}}. {{singlevalue}}</div>
        <mat-icon matSuffix mat-icon-button (click)="removeSingleVal(i)">remove</mat-icon>


    </div>


</mat-form-field>





