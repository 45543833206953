import {Component, Input, OnInit} from '@angular/core';
import { AssistenzaMessaggio} from "../../models/assistenza-messaggi";
import {Notizia} from "../../models/notizia";

@Component({
  selector: 'app-messaggio-ticket',
  templateUrl: './messaggio-ticket.component.html',
  styleUrls: ['./messaggio-ticket.component.scss']
})
export class MessaggioTicketComponent implements OnInit {


  @Input() messaggio !:AssistenzaMessaggio ; // decorate the property with @Input()

  constructor() { }

  ngOnInit(): void {
  }

}
