import {Component, OnInit} from '@angular/core';
import {UserService} from "../../services/user/user.service";
import {User} from "../../models/user";
import {CommonService} from "../../services/common/common.service";
import {SettingsService} from "../../services/settings/settings.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  profilo_online !: string;
  user!: User;
  percorsi_disponibili !: any;

  dieta_selezionata !: any;
  percorso_selezionato !: any;

  dieta_id !: number;
  percorso_id !: number;


  constructor(
    private commonService: CommonService,
    private userService: UserService,
    private settingsService: SettingsService,

  ) {

    this.commonService.setPageTitle('settings');

  }

  ngOnInit(): void {
    this.initSettingPage();

  }

  async initSettingPage() {
    await this.loadPercorsiDisponibili();
    await this.loadUtente();
    //ora che ho gli id dieta/percorso, carico le select
    await this.selectDieta();
  }

  async loadUtente() {
    await this.userService.local_user().then(user => {
      this.user = user;
      this.profilo_online = user.autologins.profilo;

      this.dieta_id = this.user.dieta.id;
      this.percorso_id = this.user.percorso.id;

    });
  }

  async loadPercorsiDisponibili() {
    await this.settingsService.get_percorsi_disponibili().then((percorsi_disponibili: any) => {
      //console.log('percorsi_disponibili', percorsi_disponibili);
      this.percorsi_disponibili = percorsi_disponibili;
    });
  }

  goToProfilo() {
    this.commonService.openURL(this.profilo_online);
  }

  async selectDieta($event?:any) {
    console.log('event', $event);
    console.log('selectDieta()',this.percorsi_disponibili);
    if (!this.percorsi_disponibili) {
      return;
    }
    let dieta_id = this.dieta_id;
    let dieta = this.percorsi_disponibili.find((dieta: any) => {
      // console.log('dieta.id', dieta.id);
      // console.log('dieta_id', dieta_id);
      console.log('res',dieta.id == dieta_id)
      return dieta.id == dieta_id
    });

    console.log('dietatrovata', dieta);
    if (dieta) {
      // console.log('selectDieta()1');
      this.dieta_selezionata = dieta;
      console.log('dieta_selezionata',this.dieta_selezionata)
      // console.log('selectDieta()2');

    } else {
      // alert('nessuna dieta trovata');
    }

  }

  selectPercorso($event?:any) {
    console.log('selectPercorso()');
  }

  salvaImpostazioni() {

    let formData = {
      'dieta': this.dieta_id,
      'percorso': this.percorso_id,
    }

    this.settingsService.salva_impostazioni(formData).then((messages: any) => {
      if (messages) {
        console.log('messages', messages);
        this.commonService.alertSuccess(messages)
      }
      //una volta salvato riprendo l'utente e lo aggiorno con i nuovi dati e impostazioni appena salvati
      this.userService.user().then((user: User) => {
        this.user = user;
      });

    }, err => {
      console.log('errore', err);
    });

    console.log('salvaImpostazioni()');
  }
}
