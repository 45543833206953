<!--messaggio del dottore-->
<div class="messaggio-del-giorno-cont" *ngIf="messaggio_home">

  <mat-card class="messaggio-del-giorno"  (click)="toggleDetailMSGHome()">

    <mat-card-content>


      <div class="MessaggioDottore">
        <h2 class="titolo">{{messaggio_home.titolo}}</h2>
        <br>
        <div class="testo" [innerHTML]="messaggio_home.descrizione"></div>
        <br>
        <div class="VideoCont" *ngIf="messaggio_home.video">
          <app-video-player [video]="messaggio_home.video" [poster]="messaggio_home.img"></app-video-player>
        </div>
      </div>


    </mat-card-content>
  </mat-card>


  <app-share-lemme *ngIf="messaggio_home.shareUrl" [url]="messaggio_home.shareUrl" ></app-share-lemme>

</div>

