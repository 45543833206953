import {Component, OnInit} from '@angular/core';
import {InsdatiService} from "../../services/insdati/insdati.service";
import {PiattoProntoMagazzino} from "../../models/piattopronto";
import {MatDialog} from "@angular/material/dialog";
import {DialogEliminaPiattoComponent} from "../../components/dialog-elimina-piatto/dialog-elimina-piatto.component";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-piatti-acquistati',
  templateUrl: './piatti-acquistati.component.html',
  styleUrls: ['./piatti-acquistati.component.scss']
})
export class PiattiAcquistatiComponent implements OnInit {

  piatti_acquistati !: PiattoProntoMagazzino[];

  constructor(
    private insDatiService: InsdatiService,
    private dialog: MatDialog,
    private commonService:CommonService,
  ) {
    this.commonService.setPageTitle('piatti_acquistati');
  }

  ngOnInit(): void {
    this.loadPiattiAcquistati();
  }

  loadPiattiAcquistati() {
    this.insDatiService.get_piatti_acquistati().then((data ) => {
       // console.log(data);
        this.piatti_acquistati = data;
      });
  }

  rimuoviPiatto(piatto: PiattoProntoMagazzino) {
    console.log('rimuoviPiatto', piatto);
    this.dialog.open(DialogEliminaPiattoComponent, {
      data: {
        piatto: piatto,
      }
    }).afterClosed().subscribe(result => {
      //dopo la chiusura del dialog ricalcolo i piatti acquistati
      this.loadPiattiAcquistati();
    });
  }
}
