<app-blocco-testo
  [titolo]="'piatti_acquistati.titolo'|translate"
  [testo]="'piatti_acquistati.descrizione'|translate"
></app-blocco-testo>

<mat-card>
  <mat-card-content>
    <div class="prossimi-menu-cont">


      <div class="lista-piatti" *ngIf="piatti_acquistati && piatti_acquistati.length">

        <mat-list class="lista-piatt-matlist">
          <mat-list-item class="piatto" *ngFor="let piatto of piatti_acquistati">

            <div class="piatto-inner   w-100">
              <div class="info-piatto d-flex align-items-end">
                <div class="blocco-dati flex-grow-1">
                  <div class="nome-piatto">
                    {{piatto.nome}}
                  </div>
                  <div class="codice-piatto">
                    {{piatto.codice}}
                  </div>

                </div>
                <div class="blocco-azioni">
                  <button mat-icon-button (click)="rimuoviPiatto(piatto)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
              <div class="info-qta">
                <div class="quantita">
                  {{'generico.disponibilita'|translate}} : {{piatto.quantita}}
                </div>
              </div>
            </div>

          </mat-list-item>
        </mat-list>


      </div>


      <div class="nessun-piatto" *ngIf="piatti_acquistati && !piatti_acquistati.length">
        {{'piatti_acquistati.nessun_piatto_disponibile'|translate}}
      </div>


    </div>
  </mat-card-content>
</mat-card>
