import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {StorageService} from '../storage/storage.service';


@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private api: ApiService,
    private storageService: StorageService,
  ) {
  }


  async news_all(categoria:string= '', page:number = 1): Promise<any> {
    return new Promise((resolve, reject): any => {

      let querystring =   '?page=' + page.toString() ;
      if (categoria) {
        querystring += '&categoria=' + categoria;
      }
      this.api.get('notizie' + querystring )
        .subscribe((risposta: any) => {


          console.log('OK News all');
          console.log('risposta', risposta);

          if (risposta) {
            //risolvo tutto, al componente serve sia data (notizie) che meta/links (paginazione)
            resolve(risposta);
          }


        }, (err: any) => {


          console.log('ERRORE news_all()');
          console.log(err);

          resolve(false);

        });
    });
  }

  async news_single(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.get('notizie/' + id)
        .subscribe((risposta: any) => {


          console.log('OK News1');
          console.log('risposta', risposta);

          if (risposta) {
            resolve(risposta['data']);
          }


        }, (err: any) => {


          console.log('ERRORE news_single()');
          console.log(err);

          resolve(false);

        });
    });
  }

  async news_ultima(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.get('notizie/ultima')
        .subscribe((risposta: any) => {


          console.log('OK News1');
          console.log('risposta', risposta);

          if (risposta) {
            resolve(risposta['data']);
          }


        }, (err: any) => {


          console.log('ERRORE news_ultima()');
          console.log(err);

          resolve(false);

        });
    });
  }


  async lista_categorie( ): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('notizie/categorie'   )
        .subscribe((risposta: any) => {

          if (risposta) {
             resolve(risposta.data);
          }
        }, (err: any) => {


          console.log('ERRORE lista_categorie()');
          console.log(err);

          resolve(false);

        });
    });
  }
}



