import {Component, OnInit} from '@angular/core';
import {User} from "../../models/user";
import {UserService} from "../../services/user/user.service";
import {Router} from "@angular/router";
import {GeneralService} from "../../services/general/general.service";

@Component({
  selector: 'app-acquista-dimagrire',
  templateUrl: './acquista-dimagrire.component.html',
  styleUrls: ['./acquista-dimagrire.component.scss']
})
export class AcquistaDimagrireComponent implements OnInit {
  user: User;
  gallery : string[]  = []

  constructor(
    private userService: UserService,
    private router: Router,
    private generalService : GeneralService,

  ) {
    this.user = new User();
  }


  ngOnInit(): void {
    this.loadUser();
    this.loadGallery();
  }

  loadGallery (){
    this.generalService.gallery_app('dimagrimento').then(risp=>{
      this.gallery =  risp;
    },err=>{
      this.gallery = [];
    });
  }


  loadUser() {
    this.userService.local_user().then(risp => {
      this.user = risp;
    });
  }

  openDimagrire() {
    this.router.navigate(['/piatti-pronti']);
  }
}
