<app-blocco-testo
  [titolo]="'prossimi_menu.titolo'|translate"
  [testo]="'prossimi_menu.descrizione'|translate"
  ></app-blocco-testo>
<div class="msg-menu nessun-menu text-center" *ngIf="prossimi_menu && !prossimi_menu.length">

  <div class="nessun-menu-inner">
    <div class="message" *ngIf="prossimi_menu_message">{{prossimi_menu_message}}</div>
    <div class="message" *ngIf="!prossimi_menu_message">{{'prossimi_menu.nessun_menu'|translate}}</div>
  </div>
  <div class="spacer"></div>
  <button class="btn-lemme-full" [routerLink]="'/inserisci-dati'">
    {{'generico.inserisci_dati'|translate}}
  </button>


</div>
<mat-card  *ngIf="prossimi_menu && prossimi_menu.length">

  <div class="pdf-link-cont" *ngIf="linkPdf">


    <app-bottone-lemme
      (click)="openLink(linkPdf)"
      [titolo]="'prossimi_menu.link_pdf'|translate"
      [icona]="'description'"
      [color]="'secondary'"
    ></app-bottone-lemme>


  </div>

   <mat-card-content>
    <div class="prossimi-menu-cont">

      <div class="prossimi-menu-inner">

        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-{{active_id}}" [closeOthers]="true">

          <ngb-panel id="ngb-panel-{{i}}" *ngFor="let menu of prossimi_menu;let i = index">
            <ng-template ngbPanelTitle>
              <span class="text-capitalize">
                <b>{{'generico.giorno'|translate}} {{(i + 1)}}</b>
                {{ menu.giorno | date  : 'EEEE dd MMMM' }}
              </span>
            </ng-template>
            <ng-template ngbPanelContent>

              <app-menu-box [menu]="menu"></app-menu-box>
            </ng-template>
          </ngb-panel>

        </ngb-accordion>


      </div>


    </div>
  </mat-card-content>
</mat-card>
