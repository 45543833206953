import {Injectable} from '@angular/core';
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class UniversitaService {

  constructor(
    private api: ApiService,
  ) {


  }

  async get_lezioni(): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('lezioni')
        .subscribe((risposta: any) => {


          if (risposta) {
            resolve(risposta.data);
          }


        }, (err: any) => {


          console.log('ERRORE get_lezioni()');
          console.log(err);

          reject(err);

        });
    });
  }

  async get_lezione(id_lezione: string): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('lezioni/' + id_lezione)
        .subscribe((risposta: any) => {


          if (risposta) {
            resolve(risposta.data);
          }


        }, (err: any) => {


          console.log('ERRORE get_lezioni()');
          console.log(err);

          reject(err.error);

        });
    });
  }

  async get_capitolo(id_lezione: string, id_capitolo: string): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('lezioni/' + id_lezione + '/capitolo/' + id_capitolo)
        .subscribe((risposta: any) => {


          if (risposta) {
            resolve(risposta.data);
          }


        }, (err: any) => {


          console.log('ERRORE get_capitolo()');
          console.log(err);

          reject(err.error);

        });
    });
  }


  async post_risposta(id_lezione: string, id_capitolo: string, dati: any): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.api.post('lezioni/' + id_lezione + '/capitolo/' + id_capitolo + '/risposta', dati)


        .subscribe((risposta: any) => {
          //console.log('post_risposta', risposta);
          if (risposta && risposta.data && risposta.data.success == true) {
            resolve(risposta.data.messages);
          } else {

            resolve(false);
          }
        }, (err: any) => {

          resolve(false);

        });
    });
  }


}
