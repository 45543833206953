import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-blocco-testo',
  templateUrl: './blocco-testo.component.html',
  styleUrls: ['./blocco-testo.component.scss']
})
export class BloccoTestoComponent implements OnInit {
  @Input() titolo = '';
  @Input() testo = '';


  constructor() { }

  ngOnInit(): void {
  }

}
