import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class BroadcasterService {


  /*servizio per ascoltare il login/logout dell'utente ed eventulemnte ricaricare alcune parti (es msg personalizzato)*/
  private is_user_loggedSubject = new Subject<boolean>();
  is_user_logged$ = this.is_user_loggedSubject.asObservable();

  is_user_logged(is_logged:boolean) {
    this.is_user_loggedSubject.next(is_logged);
  }



  /*servizio per ascoltare il httpStatus*/
  private httpStatusSubject = new Subject<HttpErrorResponse>();
  http_status$ = this.httpStatusSubject.asObservable();

  http_status(errorObj:HttpErrorResponse) {
    console.log('get broacast',errorObj.status);
    this.httpStatusSubject.next(errorObj);
  }



  /*servizio mostrare/nascondere il loader*/
  private is_loader_shownSubject = new Subject<boolean>();
  is_loader_shown$ = this.is_loader_shownSubject.asObservable();

  is_loader_shown(toshow:boolean) {
    this.is_loader_shownSubject.next(toshow);
  }



}
