<app-blocco-testo
  [titolo]="'storico.titolo'|translate"
  [testo]="'storico.descrizione'|translate"
></app-blocco-testo>


<div class="list-support-request " *ngIf="mesi && mesi.length">

  <mat-card  >
    <mat-card-content  >

      <div class="list" >

        <mat-nav-list>
          <mat-list-item   *ngFor="let mese of mesi"
                           [routerLink]="['/history-detail', mese.data]"
          >
            <div matLine  >

              <span class="data">{{mese.formatted}}</span>
            </div>
            <button mat-icon-button>
              <mat-icon>chevron_right</mat-icon>
            </button>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-nav-list>

      </div>
      <div class="no-result-list" *ngIf="mesi && !mesi.length">
         {{'generico.nessun_risultato' | translate}}
      </div>

    </mat-card-content>
  </mat-card>



</div>
