<app-blocco-testo
[titolo]="'profilo.titolo'|translate"
[testo]="'profilo.descrizione'|translate"
></app-blocco-testo>
<mat-card>

  <mat-card-content>
    <div class="user-profile" *ngIf="user">
      <div class="user-field">
        <label class="key">{{'profilo.campi.nome'|translate}}</label>
        <span class="value">{{user.nome}}</span>
      </div>

      <div class="user-field">
        <label class="key">{{'profilo.campi.cognome'|translate}}</label>
        <span class="value">{{user.cognome}}</span>
      </div>


      <div class="user-field">
        <label class="key">{{'profilo.campi.email'|translate}}</label>
        <span class="value">{{user.email}}</span>
      </div>


      <div class="user-field">
        <label class="key">{{'profilo.campi.sesso'|translate}}</label>
        <span class="value">{{user.sesso}}</span>
      </div>


      <div class="user-field">
        <label class="key">{{'profilo.campi.scadenza'|translate}}</label>
        <span class="value">{{user.scadenza | date  : 'EEEE dd MMMM'}}</span>
      </div>



      <div class="user-field">
        <label class="key">{{'profilo.campi.citta'|translate}}</label>
        <span class="value">{{user.citta }}</span>
      </div>

      <div class="user-field">
        <label class="key">{{'profilo.campi.occupazione'|translate}}</label>
        <span class="value">{{user.occupazione }}</span>
      </div>



    </div>
  </mat-card-content>
</mat-card>


<div class="spacer"></div>
<div class="profilo-online" *ngIf="profilo_online">
  <app-bottone-lemme
    [titolo]="'settings.profilo_online_button'|translate"
    icona="person"
    color="secondary"
    (click)="goToProfilo()"
  >
  </app-bottone-lemme>
</div>


