import {Component, OnInit} from '@angular/core';
import {Faq} from "../../models/faq";
import {GeneralService} from "../../services/general/general.service";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-domande',
  templateUrl: './informazioni.component.html',
  styleUrls: ['./informazioni.component.scss']
})
export class InformazioniComponent implements OnInit {

  faqs: Faq[] = [];

  constructor(
    private generalService: GeneralService,
    private commonService: CommonService,
  ) {
    this.commonService.setPageTitle('info');

  }

  ngOnInit(): void {
    this.generalService.informazioni().then(res => {
      this.faqs = res;
    }, err => {
      console.log('errore', err);
    });
  }

}
