import {Component, Input, OnInit} from '@angular/core';
import {Notizia} from "../../models/notizia";
import {Audioricetta} from "../../models/audioricetta";

@Component({
  selector: 'app-audioricetta-loop',
  templateUrl: './audioricetta-loop.component.html',
  styleUrls: ['./audioricetta-loop.component.scss']
})
export class AudioricettaLoopComponent implements OnInit {

  @Input() audioricetta !:Audioricetta ; // decorate the property with @Input()


  constructor() { }

  ngOnInit(): void {
  }

}
