import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private api: ApiService,
  ) {

  }

  async get_percorsi_disponibili(): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('percorsi/disponibili')
        .subscribe((risposta: any) => {


          if (risposta) {
            resolve(risposta.data.diete);
          }


        }, (err: any) => {


          console.log('ERRORE get_capitolo()');
          console.log(err);

          reject(err.error);

        });
    });
  }


  /**/
  async salva_impostazioni(formData:any): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.api.post('settings', formData)


        .subscribe((risposta: any) => {
          //console.log('post_risposta', risposta);
          if (risposta && risposta.data && risposta.data.success == true) {
            resolve(risposta.data.messages);
          } else {

            resolve(false);
          }
        }, (err: any) => {

          resolve(false);

        });
    });
  }



}
