import {Component, OnInit} from '@angular/core';
import {Faq} from "../../models/faq";
import {GeneralService} from "../../services/general/general.service";
import {CommonService} from "../../services/common/common.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-regole',
  templateUrl: './regole.component.html',
  styleUrls: ['./regole.component.scss']
})
export class RegoleComponent implements OnInit {

  faqs: Faq[] = [];
  fase !: number | null;
  langsufx:string = '_f1';

  constructor(
    private generalService: GeneralService,
    private commonService: CommonService,
    private route: ActivatedRoute,

  ) {
    // this.commonService.setPageTitle('regole');

  }

  ngOnInit(): void {


    this.route.paramMap.subscribe(params => {
      let faseRoute = params.get('fase');
      this.fase = Number(faseRoute);

      if (!this.fase) {
        this.fase = 1;
      }

      if (this.fase == 2) {
        this.langsufx = '_f2';
      }
      this.commonService.setPageTitle('regole_f' + this.fase);
      this.generalService.regole_fase(this.fase).then(res => {
        this.faqs = res;
      }, err => {
        console.log('errore', err);
      });


    });
  }
}
