import {Component, OnInit} from '@angular/core';
import {InsdatiService} from "../../services/insdati/insdati.service";
import {Menu} from "../../models/menu";
import * as moment from "moment";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-prossimi-menu',
  templateUrl: './prossimi-menu.component.html',
  styleUrls: ['./prossimi-menu.component.scss']
})
export class ProssimiMenuComponent implements OnInit {
  prossimi_menu: Menu[]|null = null;
  prossimi_menu_message:string = '';
  now    = moment().format('YYYYMMDD');
  active_id :number   = 0;
  public linkPdf: string = '';

  constructor(
    private insDatiService: InsdatiService,
    private commonService: CommonService,
  ) {
    this.commonService.setPageTitle('prossimi_menu');

    this.loadProssimiMenu();
    this.loadPdfMenu();

  }

  ngOnInit(): void {
  }

  loadPdfMenu(){
    this.insDatiService.pdf_menu().then((risp) => {

      if (risp) {
        console.log(risp);
        this.linkPdf = risp;
      }
    });
  }

  loadProssimiMenu() {
    this.insDatiService.prossimi_menu().then((risp) => {
      if (risp) {
        this.prossimi_menu = risp.data;
      this.prossimi_menu_message = risp.message;
        this.checkActiveID();
      }
    });
  }

  private checkActiveID() {
    if (this.prossimi_menu) {
      this.prossimi_menu.forEach((x, i) => {
        if (moment(x.giorno).format('YYYYMMDD') == this.now) {
          this.active_id = i;
        }
      });
    }
  }

  openLink(linkPdf: string) {
    this.commonService.openURL(linkPdf);
  }
}
