import {Component, OnInit} from '@angular/core';
import {HistoryService} from "../../services/history/history.service";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  mesi: any = null;

  constructor(
    private historyService: HistoryService,
    private commonService: CommonService,
  ) {
    this.commonService.setPageTitle('storico');

  }

  ngOnInit(): void {
    this.loadMesi();
  }

  loadMesi() {
    this.historyService.get_mesi().then(risp => {
      if (risp) {
        this.mesi = risp;
      }
    }, err => {

    });
  }

}
