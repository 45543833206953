
<div class="grafico-ext">
  <app-blocco-testo
    *ngIf="currentMisura"
    [titolo]="'generico.misure.' + currentMisura + '.nome' | translate"  >

  </app-blocco-testo>
  <mat-card>
    <mat-card-content>
      <div class="grafici-content" *ngIf="lineChartData">


        <div ngbDropdown class="d-inline-block">
          <span class="label">{{'generico.periodo'|translate}} : </span>
          <button type="button" class="btn btn-outline-primary" id="dropdownBasic1"
                  ngbDropdownToggle>{{currentGiorni}} {{'generico.giorni'|translate}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button *ngFor="let giorno of possibiliGiorni" (click)="setGiorniGrafico(giorno)"
                    ngbDropdownItem>{{giorno}}</button>
          </div>
        </div>


        <canvas #graficoDetail baseChart
                [data]="lineChartData"
                [options]="lineChartOptions"
                [type]="lineChartType"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"
        >
        </canvas>


      </div>


    </mat-card-content>
  </mat-card>
</div>
