import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Route, Router} from "@angular/router";
import {ChartsService} from "../../services/charts/charts.service";
import {TranslateService} from "@ngx-translate/core";
import {ChartConfiguration, ChartEvent, ChartType} from 'chart.js';
import {BaseChartDirective} from "ng2-charts";
import {UserService} from "../../services/user/user.service";


@Component({
  selector: 'app-graph-detail',
  templateUrl: './graph-detail.component.html',
  styleUrls: ['./graph-detail.component.scss']
})
export class GraphDetailComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  currentMisura: string | null = null;
  currentGiorni = 7;
  possibiliGiorni = [7, 15, 30, 60];


  datiGrafico: any = {};

  public lineChartData!: ChartConfiguration['data'];

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      'y-axis-0':
        {
          position: 'left',
        },

    },

    plugins: {
      legend: {display: true},

    }
  };


  public lineChartType: ChartType = 'line';

  userSesso!: string;

  constructor(
    private route: ActivatedRoute,
    private chartService: ChartsService,
    private router: Router,
    private translationService: TranslateService,
    private userService: UserService,
  ) {

    this.resetDataSet();
  }

  resetDataSet(): void {
    this.lineChartData = {
      datasets: [],
      labels: [],
    };
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.currentMisura = params.get('stat');
      this.loadSingoloGrafico()

    });

    this.userService.get_user_sesso().then(risp => {
      this.userSesso = risp;
    });


  }


  loadSingoloGrafico() {
    this.chartService.get_singolo_grafico(this.currentMisura ?? '', this.currentGiorni).then(risp => {
      this.datiGrafico = risp;
      console.log('risp123213312', risp);

      this.resetDataSet();

      let valoriMisura = risp.values;

      this.lineChartData.datasets.push({
        data: valoriMisura,
        label: this.translationService.instant('generico.misure.' + this.currentMisura + '.nome'),

      });

      /*aggiunta grafico ciclo*/
      if(this.userSesso == 'F'){
        let valoriCiclo = risp.ciclo;



        let maxValore = Math.max(...valoriMisura);
        let minValore = Math.min(...valoriMisura);
        valoriCiclo = valoriCiclo.map(function (val: number) {
          if (val) {
            return maxValore
          }
          return minValore

        });

        this.lineChartData.datasets.push({
          data: valoriCiclo,
          label: this.translationService.instant('generico.ciclo'),
          fill: true,
          tension: 0,
        });




      }







      this.lineChartData.labels = (risp.labels);

      //caricato i dati faccio update del grafico
      this.chart?.update();


    }, err => {

    });
  }

  // events
  public chartClicked({event, active}: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({event, active}: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }

  setGiorniGrafico(giorno: number) {
    this.currentGiorni = giorno;
    this.loadSingoloGrafico();
  }
}
