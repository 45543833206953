import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {StorageService} from '../storage/storage.service';
import {environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ApiService {


  url = environment.api_url;

  private customHeader: HttpHeaders = new HttpHeaders();

  //private accessToken: string | string[];


  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {
    //this.createAuthorizationHeader();
  }


  get(endpoint: string): any {


    // console.log('GET');
    return this.http.get(this.url + '/' + endpoint);


    // console.log('headercustom',this.customHeader.getAll('Pippo'));


  }

  post(endpoint: string, data: any): any {
    // console.log('POST');
    return this.http.post(this.url + '/' + endpoint, data);
  }

  //TODO da finire - non è compatibile con laravel
  postMultipart(endpoint: string, data: any): any {
    // console.log('POST');






    let headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json' });
    let options = { headers: headers };

    console.log('OPZIONI', options);

    return this.http.post(this.url + '/' + endpoint, data, options);
  }


  patch(endpoint: string, data: any): any {
    // console.log('PATCH');
    return this.http.patch(this.url + '/' + endpoint, data);
  }
  delete(endpoint: string): any {


    // console.log('GET');
    return this.http.delete(this.url + '/' + endpoint);


    // console.log('headercustom',this.customHeader.getAll('Pippo'));


  }

}
