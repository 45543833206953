<div class="news-loop-list">

  <div class="news-loop-list-inn" [routerLink]="['/news-detail', news.id]">

    <div class="d-flex justify-content-between align-items-center">


        <div class="image">
          <img mat-card-image [src]="news.img" *ngIf="news.img">
        </div>



        <div class="testo flex-grow-1">
          <h4 class="titolo">{{news.titolo}}</h4>
          <div class="data">{{news.data_notizia|date}}</div>
        </div>



        <div class="icon">
          <mat-icon>chevron_right</mat-icon>
        </div>



    </div>




  </div>


</div>
