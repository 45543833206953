

<mat-card *ngIf="capitolo">
  <mat-card-title>{{capitolo.titolo}}</mat-card-title>
  <mat-card-content>
    <div class="universita-capitolo-cont">

      <div class="lista-audio" *ngIf="capitolo.audio">
        <div class="singolo-audio" *ngFor="let audio of capitolo.audio ; let i = index">
          <span class="numero">#{{(i+1)}}</span>
          <app-audio-player [audio]="audio"></app-audio-player>
        </div>
      </div>


      <div class="blocco-domanda-risposta">

        <div class="domanda" *ngIf="capitolo.domanda || capitolo.domanda_audio">
          <h4 class="titolo">{{'singolo_capitolo.domanda'|translate}}</h4>
          <div class="domanda-text" *ngIf="capitolo.domanda" [innerHTML]="capitolo.domanda"></div>
          <app-audio-player  *ngIf="capitolo.domanda_audio" [audio]="capitolo.domanda_audio"></app-audio-player>


        </div>




      </div>

    </div>
  </mat-card-content>
</mat-card>


<div class="spacer"></div>

<div class="interazioni">

  <div class="domanda-button">

    <app-bottone-lemme
      (click)="lanciaModalRispost('risposta')"
      [titolo]="'singolo_capitolo.rispondi_alla_domanda' | translate"
      icona="lightbulb"
      color="secondary"
    ></app-bottone-lemme>

  </div>

  <div class="risposta-button">
    <app-bottone-lemme
      (click)="lanciaModalRispost('domanda')"
      [titolo]="'singolo_capitolo.fai_una_domanda' | translate"
      icona="quiz"
    ></app-bottone-lemme>
   </div>
</div>


