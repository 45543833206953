<div class="support-content" *ngIf="support">
  <h2 class="titolo text-center">Ticket #{{support.id}}</h2>
  <div class="messaggio-apertura mb-5">
    <mat-card class="card">

      <mat-card-title-group>
        <mat-card-title>{{support.oggetto}}</mat-card-title>
        <mat-card-subtitle>{{support.giorno | date}}</mat-card-subtitle>
      </mat-card-title-group>
      <mat-card-content class="card-body">
        {{support.testo}}
      </mat-card-content>
    </mat-card>
  </div>

  <div class="messaggi" *ngIf="support.messaggi.length">
    <div class="messaggi-list" *ngFor="let messaggio of support.messaggi">
      <app-messaggio-ticket [messaggio]="messaggio"></app-messaggio-ticket>
    </div>
  </div>
  <div class="messaggi-empty text-center" *ngIf="!support.messaggi.length">
    <h4>{{'assistenza.nessun_messaggio_aggiuntivo'|translate}}</h4>
  </div>


  <div class="new-support-request mb-5">
    <mat-card class="card">
      <mat-card-title-group>
        <mat-card-title>{{'generico.rispondi'|translate}}</mat-card-title>
      </mat-card-title-group>

      <mat-card-content  >
        <form class="assistenza-form " [formGroup]="form" (ngSubmit)="onSubmit()">

          <div class="form-group">

            <mat-form-field class=" w-100">
              <mat-label for="testo">{{'assistenza.campi.messaggio'|translate}}</mat-label>
              <textarea matInput formControlName="testo" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.testo.errors }"
                        placeholder="{{'assistenza.campi.messaggio_placeholder'|translate}}"
              ></textarea>
              <div *ngIf="submitted && f.testo.errors" class="invalid-feedback">
                <mat-error *ngIf="f.testo.errors.required">{{'assistenza.campi.messaggio_required'|translate}}</mat-error>
              </div>

            </mat-form-field>

          </div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{'generico.invio'|translate}}
            </button>
            <!--
            <a (click)="svuotaForm()">
              svuota

            </a>
            -->

          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

</div>


