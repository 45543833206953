<app-blocco-testo
  [titolo]="'settings.titolo'|translate"
  [testo]="'settings.descrizione'|translate"
></app-blocco-testo>

<mat-card>
  <mat-card-content>
    <div class="settings-cont">


      <div class="impostazioni-form">
        <h3>{{'settings.scegli_percorso'|translate}}</h3>


        <mat-form-field appearance="fill"
                        *ngIf="percorsi_disponibili && percorsi_disponibili && percorsi_disponibili.length">
          <!-- <mat-label>Select an option</mat-label>-->
          <mat-select (selectionChange)="selectDieta($event)" name="select_dieta" [(ngModel)]="dieta_id">
            <mat-option [value]="dieta.id" *ngFor="let dieta of percorsi_disponibili">{{dieta.nome}}</mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field appearance="fill" *ngIf="dieta_selezionata">
          <!-- <mat-label>Select an option</mat-label>-->
          <mat-select (selectionChange)="selectPercorso($event)" name="select_percorso" [(ngModel)]="percorso_id">
            <mat-option [value]="percorso.id"
                        *ngFor="let percorso of dieta_selezionata.percorsi_dieta">{{percorso.nome}}</mat-option>
          </mat-select>
        </mat-form-field>


      </div>


    </div>
    <div class="salva-cont sovrapponi">
      <app-bottone-lemme
        [titolo]="'settings.salva_impostazioni'|translate"
        icona="save"
        (click)="salvaImpostazioni()"
        color="secondary"
      ></app-bottone-lemme>


    </div>
  </mat-card-content>
</mat-card>


<div class="spacer"></div>
<div class="profilo-online" *ngIf="profilo_online">
  <app-bottone-lemme
    [titolo]="'settings.profilo_online_button'|translate"
    icona="person"

    (click)="goToProfilo()"
  >
  </app-bottone-lemme>
</div>
