import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";
import {StorageService} from "../storage/storage.service";

@Injectable({
  providedIn: 'root'
})
export class AudioricetteService {

  constructor(
    private api: ApiService,
  ) {
  }

  async audioricette_all(): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('audio_ricette'   )
        .subscribe((risposta: any) => {


          console.log('OK Audioricette all');
          console.log('risposta', risposta);

          if (risposta) {
            //risolvo tutto, al componente serve sia data (notizie) che meta/links (paginazione)
            resolve(risposta.data);
          }


        }, (err: any) => {


          console.log('ERRORE news_all()');
          console.log(err);

          resolve(false);

        });
    });
  }

  async audioricette_single(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.get('audio_ricette/' + id)
        .subscribe((risposta: any) => {


          console.log('OK News1');
          console.log('risposta', risposta);

          if (risposta) {
            resolve(risposta['data']);
          }


        }, (err: any) => {


          console.log('ERRORE news_single()');
          console.log(err);

          resolve(false);

        });
    });
  }

}
