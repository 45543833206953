import {Injectable} from '@angular/core';
import {ApiService} from "../api/api.service";
import {PiattoProntoMagazzino} from "../../models/piattopronto";

@Injectable({
  providedIn: 'root'
})
export class InsdatiService {

  constructor(
    private api: ApiService,
  ) {

  }

  async post_dati(formData: any): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.post('registrazioni/salva', formData)

        .subscribe((risposta: any) => {


          // console.log('risposta123',risposta)
          if (risposta) {
            resolve(risposta.data);
          }


        }, (err: any) => {


          console.log('ERRORE post_dati()');
          console.log(err);

          reject(err);

        });
    });
  }

  async registrazioni_init(): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('registrazioni/init')
        .subscribe((risposta: any) => {

          console.log('registrazioniinit', risposta);

          if (risposta) {
            resolve(risposta.data);
          } else {
            resolve(false)
          }


        }, (err: any) => {


          console.log('ERRORE post_support()');
          console.log(err);

          reject(err.error);

        });
    });
  }

  async lista_spesa(): Promise<any> {
    return new Promise((resolve, reject): any => {
      //menu/lista-spesa

      this.api.get('menu/lista-spesa')
        .subscribe((risposta: any) => {
          console.log('lista-spesa', risposta);
          if (risposta && risposta.data) {
            resolve(risposta.data);
          } else {
            resolve(false)
          }


        }, (err: any) => {


          console.log('ERRORE listaspesa()');
          console.log(err);

          reject(err.error);

        });
    });
  }
  async prossimi_menu(): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('menu/prossimi')
        .subscribe((risposta: any) => {
          console.log('prossimimenu', risposta);
          if (risposta) {
            resolve(risposta);
          } else {
            resolve(false)
          }


        }, (err: any) => {


          console.log('ERRORE prossimimenu()');
          console.log(err);

          reject(err.error);

        });
    });
  }


  async  pdf_menu(): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('menu/link')
        .subscribe((risposta: any) => {
          console.log('menulink-risp', risposta);
          if (risposta) {
            resolve(risposta.data);
          } else {
            resolve(false)
          }


        }, (err: any) => {


          console.log('ERRORE pdf_menu()');
          console.log(err);

          reject(err.error);

        });
    });
  }
  async piatti_st_disponibili(): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('menu/sceglitu')
        .subscribe((risposta: any) => {
          console.log('piatti_st_disponibili', risposta);
          if (risposta) {
            resolve(risposta.data);
          } else {
            resolve(false)
          }


        }, (err: any) => {


          console.log('ERRORE prossimimenu()');
          console.log(err);

          reject(err.error);

        });
    });
  }



  async post_cambiopiatto(tipo:string, formData: any): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.post('menu/cambio/'+tipo, formData)

        .subscribe((risposta: any) => {


          // console.log('risposta123',risposta)
          if (risposta) {
            resolve(risposta.data);
          }


        }, (err: any) => {


          console.log('ERRORE post_dati()');
          console.log(err);

          reject(err.error);

        });
    });
  }

  async get_piatti_acquistati(): Promise<PiattoProntoMagazzino[]> {
    return new Promise((resolve, reject): any => {


      this.api.get('storico/acquisti')
        .subscribe((risposta: any) => {

          if (risposta) {
            resolve(risposta.data);
          } else {
            resolve([])
          }


        }, (err: any) => {


          console.log('ERRORE get_piatti_acquistati()');
          console.log(err);

          reject(err.error);

        });
    });
  }

  async post_aggiorna_acquisto( formData: any): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.post('aggiorna/ordini', formData)

        .subscribe((risposta: any) => {


          // console.log('risposta123',risposta)
          if (risposta) {
            resolve(risposta.data);
          }


        }, (err: any) => {

          console.log('ERRORE post_dati()');
          console.log(err);
          reject(err.error);

        });
    });
  }



}
