<div class="reverse-content-space-top"></div>

<div class="img-cont">
<img class="img-fluid" src="assets/images/chiamalemme/LEMME-RIGHE-SCONTORNATO-arancio.jpg"/>
</div>

<app-blocco-testo
  [titolo]="'chiamalemme.titolo'|translate"
  [testo]="'chiamalemme.descrizione'|translate"
></app-blocco-testo>

<div class="bottoni-chiama">
  <app-bottone-lemme [titolo]="'chiamalemme.chiamacell1'|translate"
                     [icona]="'call'"
                     (click)="chiamaCell1()"
                     [color]="'primary'"
  ></app-bottone-lemme>

  <app-bottone-lemme [titolo]="'chiamalemme.chiamacell2'|translate"
                     [icona]="'call'"
                     (click)="chiamaCell2()"
                     [color]="'secondary'"
  ></app-bottone-lemme>
</div>
