
<div class="ricetta-ext" *ngIf="ricetta">


<app-blocco-testo
        [titolo]="ricetta.titolo"
        [testo]="ricetta.descrizione"
        class="mb-5"
></app-blocco-testo>

    <div class="foto text-center mb-5" *ngIf="ricetta.foto">
        <img class="img-fluid full" [src]="ricetta.foto"/>
    </div>


    <div class="container">
<div class="ricetta-content  mb-5 " >

    <div class="ricetta-inner">

         <div class="tags" *ngIf="ricetta.tagsAsArray()">
            <h5 class="sottotitoli">{{ 'inserisci_ricetta.form.tags' | translate }}</h5>
            <div class="value">
                {{ricetta.tagsAsArray().join(',')}}
            </div>
        </div>

        <div class="pasti" *ngIf="ricetta.pasti">
            <h5 class="sottotitoli">{{ 'inserisci_ricetta.form.pasti' | translate }}</h5>
            <div class="value">
                {{ricetta.pasti.join(',')}}
            </div>

        </div>
        <div class="spacer"></div>

        <div class="ingredienti" *ngIf="ricetta.ingredienti">
            <h5 class="sottotitoli">{{ 'inserisci_ricetta.form.ingredienti' | translate }}</h5>
            <ul>
                <li *ngFor="let passaggio of ricetta.ingredienti  ">
                    {{passaggio}}
                </li>
            </ul>
        </div>
        <div class="spacer"></div>

        <div class="passaggi" *ngIf="ricetta.passaggi">
            <h5 class="sottotitoli">{{ 'inserisci_ricetta.form.passaggi' | translate }}</h5>
            <ul>
                <li *ngFor="let passaggio of ricetta.passaggi  ">
                    {{passaggio}}
                </li>
            </ul>
        </div>





    </div>

</div>

<div class="cta-ricetta">


    <div class="row">
        <div class="col">
            <app-bottone-lemme
                    [titolo]="'vedi_ricetta.buttons.modifica' | translate"
                    (click)="editRicetta()"
                    icona="edit"
                    color="secondary"
                    class="full mb-3"
            ></app-bottone-lemme>
        </div>
        <div class="col">
            <app-bottone-lemme
                    [titolo]="'vedi_ricetta.buttons.cancella' | translate"
                    (click)="deleteRicetta()"
                    icona="cancel"
                    color="red"
                    class="full mb-3"
            ></app-bottone-lemme>
        </div>
    </div>





</div>

</div>


</div>