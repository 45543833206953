<div class="audioricette-box-cont">
  <mat-card>
    <mat-card-content>

      <div class="audioricette-inner text-center">

        <img class="img-uni" src="assets/images/audioricette/audioricettaicona.png" />
        <h2 class="titolo  py-4 mb-1">{{'components.audioricette.titolo'|translate}}</h2>

      </div>


    </mat-card-content>
  </mat-card>


  <app-bottone-lemme
    [routerLink]="['/audio-ricette']"

    [titolo]="'components.audioricette.bottone'|translate"

    [icona]="'mic'"
    [color]="'secondary'"
  ></app-bottone-lemme>
</div>
