import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PlyrComponent} from "ngx-plyr";
import {GeneralService} from "../../services/general/general.service";

@Component({
  selector: 'app-audio-required-player',
  templateUrl: './audio-required-player.component.html',
  styleUrls: ['./audio-required-player.component.scss']
})
export class AudioRequiredPlayerComponent implements OnInit {
  @Input() audio = '';
  @Output() fileEndedEvent = new EventEmitter<boolean>();


  // get the component instance to have access to plyr instance
  @ViewChild(PlyrComponent)
  plyr !: PlyrComponent;

// or get it from plyrInit event
  player !: Plyr;

  audioSources: Plyr.Source[] = [];
  audioOptions: Plyr.Options;

  played(event: Plyr.PlyrEvent) {
    console.log('played1-Required', event);



  }


  constructor(
    private generalService : GeneralService
  ) {
    this.audioOptions = {

      /* autoplay: true,
        muted: true,*/

      controls : [
        // 'play-large',
        'play',
       // 'progress',
        'current-time',
     //   'mute',
        //'volume',
        // 'captions',
        //'settings',
        //'pip',
        // 'airplay',
        //'fullscreen'
      ],


    };
    console.log('audioOptions', this.audioOptions);

  }
  ngOnInit(): void {
    this.audioSources = [
      {
        src: this.audio,
        provider: 'html5',
        type :'audio/mp3'
      },
    ]
  }

  play(): void {
    this.player.play(); // or this.plyr.player.play()
  }


  assignPlayer($event: Plyr) {
    this.player = $event
  }

  fileEnded($event: Plyr.PlyrEvent) {
    console.log('Ended required',$event);
    this.fileEndedEvent.emit(true);
  }
}
