import {Component, OnInit} from '@angular/core';
import {Notizia} from "../../models/notizia";
import {PageEvent} from "@angular/material/paginator";
import {ActivatedRoute, Router} from "@angular/router";
import {NewsService} from "../../services/news/news.service";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  titoloNews: string = '';
  notizie: Notizia[] = [];
  categorie: any[] = [];
  pagemeta: any;



  current_categoria = '';
  current_page = 1;


  iconlist: any = environment.icone_news ;

  constructor(
    private newsService: NewsService,
    private router: Router,
    private translationService: TranslateService,
    private route: ActivatedRoute,
    private commonService: CommonService,
  ) {
    this.commonService.setPageTitle('novita');
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      let categoryid = params.get('categoryid');

      if (categoryid) {
        this.current_categoria = categoryid;
      }

    })

   this.init();


  }

  async init() {
    await this.caricaCategorie();
    await this.caricaNews();
  }

  async caricaCategorie() {

    //chiamata finta per essere sicuri che le traduzioni siano caricate
    await this.translationService.get('dummyTranslation').toPromise().then();

    let allnews = {
      id: "",
      nome: this.translationService.instant('news.tutte_news'),
    };
    this.categorie.push(allnews);

   // this.titoloNews = allnews.nome;

    this.newsService.lista_categorie().then(risp => {
      this.categorie.push(...risp);

      let filtrate = this.categorie.filter(categoria => {
        return categoria.id == this.current_categoria
      });
      // this.titoloNews = filtrate.nome ?? '';
      if (filtrate && filtrate.length > 0) {
        this.titoloNews = filtrate[0].nome;
      }


    }, errore => {
      console.log(errore);
    });


  }

  async caricaNews() {
    //CARICO NUOVE NOTIZIE;
    this.notizie = [];


    this.newsService.news_all(this.current_categoria, this.current_page).then(risp => {

      this.notizie = risp.data as Array<Notizia>;
      this.pagemeta = risp.meta;


    }, errore => {
      console.log(errore);
    });

  }

  paginateNews($event :any ) {
  console.log('$event',$event);
   this.current_page = $event;
    this.caricaNews();

  }

  setCategoria(categoria: any) {
    this.current_categoria = categoria.id;
    this.titoloNews = categoria.nome;


    this.caricaNews();

  }
}
