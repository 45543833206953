import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../../app.component";
import {UntypedFormBuilder, FormControl, UntypedFormGroup, NgForm, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {UserService} from "../../services/user/user.service";
import {BroadcasterService} from "../../services/broadcaster/broadcaster.service";
import {CommonService} from "../../services/common/common.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup;
  loading = false;
  submitted = false;


  constructor(
    public appComponent: AppComponent,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private broadcaster: BroadcasterService,
    private commonService: CommonService,
  ) {

    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });


  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {


  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }


  onSubmit() {

    this.submitted = true;

    console.log('form', this.form);


    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.userService.login(
      {
        email: this.form.value.username,
        password: this.form.value.password
      }
    ).then((res) => {

      console.log('loginpageLogin', res);
      if (res) {
        //login ok
        console.log('naviga home');


        this.broadcaster.is_user_logged(true);

        this.router.navigateByUrl('/');

      } else {
        //errore login
        alert('Errore login');

      }


    }, errors => {


      console.log('loginpage errors', errors);

      if (errors) {


        this.commonService.alertError(errors);

      }


    });

    this.loading = false;


  }

  openResetPwd() {
    this.commonService.openURL(environment.resetpassword_url);

  }

  openRegistrati() {
    this.commonService.openURL(environment.sito_fa);
  }
}
