<app-blocco-testo
  [titolo]="'chat_con_lemme.titolo'|translate"
  [testo]="'chat_con_lemme.descrizione'|translate">

</app-blocco-testo>



<div class="errore text-center" *ngIf="!lemme_app_iframe">
  {{'chat_con_lemme.errore_recupero_chat'|translate}}
</div>

<app-bottone-lemme [titolo]="'chat_con_lemme.button'|translate" icona="chat"    (click)="openChat()">
</app-bottone-lemme>

<div class="spacer"></div>

<app-bottone-lemme [titolo]="'chat_con_lemme.button_wa'|translate" icona="whatsapp"  color="secondary" tipo="fab"   (click)="openWA()">
</app-bottone-lemme>



