


<div class="messaggio-menu" *ngIf="menu.infoMenu">
  <div class="messaggio-menu-inner" [innerHTML]="menu.infoMenu"></div>
</div>

<div class="row">
  <div class="col-sm-4">
    <app-piatto-box [menu]="menu" [chiave]="'colazione'" [piatto]="menu.colazione"></app-piatto-box>
  </div>
  <div class="col-sm-4">
    <app-piatto-box [menu]="menu" [chiave]="'pranzo'" [piatto]="menu.pranzo"></app-piatto-box>
  </div>
  <div class="col-sm-4">
    <app-piatto-box [menu]="menu" [chiave]="'cena'" [piatto]="menu.cena"></app-piatto-box>
  </div>
</div>
