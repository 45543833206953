<div class="universita-box-cont">
<mat-card>
  <mat-card-content>

    <div class="universita-inner text-center">

      <img class="img-uni" src="assets/images/universita/libro.png" />
      <h2 class="titolo  py-4 mb-1">{{'components.universita.titolo'|translate}}</h2>

    </div>


  </mat-card-content>
</mat-card>


<app-bottone-lemme
  [routerLink]="['/universita']"

  [titolo]="'components.universita.bottone'|translate"

  [icona]="'play_lesson'"
  [color]="'primary'"
></app-bottone-lemme>
</div>
