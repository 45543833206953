import {Component, OnInit} from '@angular/core';
import {StorageService} from "../../services/storage/storage.service";
import {InsdatiService} from "../../services/insdati/insdati.service";
import {LanguageService} from "../../services/language/language.service";
import {CommonService} from "../../services/common/common.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-lista-spesa',
  templateUrl: './lista-spesa.component.html',
  styleUrls: ['./lista-spesa.component.scss']
})
export class ListaSpesaComponent implements OnInit {
  ingredienti_salvati: string[] = [];

  lista_spesa: any = false;
  link_pdf: string = '';
  giorno_generazione: any = false;

  private currentLang!: string;
  private ProssimiMenu!: any;
  private GiornoGenerazione!: Date;


  constructor(
    private localStorage: StorageService,
    private insDatiService: InsdatiService,
    private languageService: LanguageService,
    private commonService: CommonService,
    private translateService: TranslateService,
  ) {
    this.commonService.setPageTitle('lista_spesa');

    this.recuperaListaSpesa();
  }

  ngOnInit(): void {
  }

  async recuperaListaSpesa() {

    this.currentLang = this.languageService.currentLanguage;

    this.insDatiService.lista_spesa().then((risp: any) => {

      if (risp) {
        this.lista_spesa = risp.lista;
        this.link_pdf = risp.link;
        this.giorno_generazione = risp.data_invio;

        this.generaListaSpesa();
      }


    });

  }

  openLink(link: string) {
    this.commonService.openURL(link);
  }

  generaListaSpesa() {
    let listaSalvata = this.localStorage.get('ListaSpesa');
    if (listaSalvata && listaSalvata.data == this.giorno_generazione && listaSalvata.lang == this.currentLang) {
      //la lista salvata corrisponde al menu arrivato dal server
      //posso caricare gli ingredienti checkati

      this.ingredienti_salvati = listaSalvata.ingredienti;
      return;
    } else {
      //la lista salvata è sbagliata, creo una nuova lista e la salvo
      this.salvaListaSpesa();

    }


  }


  openPasti(pasti: any) {
    if (!pasti) {
      return;
    }
    let pastiString = '';
    let pastiArr: any[] = [];
    pasti.forEach((elem: any) => {
      pastiArr.push(elem.nome);
    });
    let titoloMsg = this.translateService.instant('lista_spesa.titolo');
    this.commonService.alertMsg(titoloMsg, pastiArr.join(', '));
  }

  toggleCheck(itemid: any) {
    console.log('toggle.check', itemid);

    let index = this.ingredienti_salvati.indexOf(itemid);
    console.log(index)
    if (index > -1) {
      this.ingredienti_salvati.splice(index, 1);
    } else {
      this.ingredienti_salvati.push(itemid);
    }
console.log(this.ingredienti_salvati)

    this.salvaListaSpesa();


  }

  salvaListaSpesa() {
    let new_listaInStorage = {
      ingredienti: this.ingredienti_salvati,
      data: this.giorno_generazione,
      lang: this.currentLang,
    };
    console.log('sto salvando ', new_listaInStorage);

    this.localStorage.set('ListaSpesa', new_listaInStorage);

  }


}
