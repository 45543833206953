<!--
<div class="lista-categorie" *ngIf="categorie.length>0">

  <div ngbDropdown class="d-block d-md-none">
    <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{'news.seleziona_categoria'|translate}}</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button  ngbDropdownItem   *ngFor="let categoria of categorie">
        <div   [className]="categoria.id == current_categoria ? 'active' : ''" class="singola-categoria" (click)="setCategoria(categoria)">{{categoria.nome}}</div>
      </button>
    </div>
  </div>




  <div class="switch-desktop  d-none  d-md-block ">
    <div class="row">
      <div [class]="categoria.id == current_categoria ? 'active' : ''" class="col " *ngFor="let categoria of categorie">
        <a class="singola-categoria" (click)="setCategoria(categoria)">{{categoria.nome}}</a>
      </div>
    </div>

  </div>

</div>
-->

<div class="news-all">
  <div class="titolo-categoria">

    <h2 *ngIf="titoloNews"  class="titolo d-flex align-items-center justify-content-center">
      <mat-icon class="icona">{{iconlist[current_categoria]}}</mat-icon>
      <span class="testo">{{titoloNews}}</span>
    </h2>

  </div>


  <div class="loader" *ngIf="notizie && notizie.length == 0">
    <mat-spinner></mat-spinner>
  </div>


  <div class="notizie-all-content" *ngIf="notizie && notizie.length>0">


    <mat-card>
      <mat-card-content>

        <div class="row">
          <div class="col-md-12 col-12" *ngFor="let notizia of notizie">
            <div class="notizie-all-content-item">
              <app-news-loop-list [news]="notizia"></app-news-loop-list>
            </div>
          </div>
        </div>

      </mat-card-content>
    </mat-card>


    <!--
    <mat-paginator

      *ngIf="pagemeta"

      #paginator
      [length]="pagemeta.total"
      [pageIndex]="(pagemeta.current_page) - 1"
      [pageSize]="pagemeta.per_page"

      (page)="paginateNews($event)">

      hidePageSize="true"
      showFirstLastButtons="true"

      aria-label="Select page"
      >
    </mat-paginator>
-->

    <div class="pagination-cont">

      <ngb-pagination
        *ngIf="pagemeta"
        [maxSize]="4"
        [rotate]="true"
        [(page)]="pagemeta.current_page"
        [pageSize]="pagemeta.per_page"
        [collectionSize]="pagemeta.total"
        (pageChange)="paginateNews($event)"
      ></ngb-pagination>

    </div>


  </div>
</div>
