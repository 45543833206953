import {Component, OnInit} from '@angular/core';
import {User} from "../../models/user";
import {InsdatiService} from "../../services/insdati/insdati.service";
import {UserService} from "../../services/user/user.service";
import {CommonService} from "../../services/common/common.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {RicetteService} from "../../services/ricette/ricette.service";
import {Ricetta} from "../../models/ricetta";

@Component({
  selector: 'app-lista-ricette',
  templateUrl: './lista-ricette.component.html',
  styleUrls: ['./lista-ricette.component.scss']
})
export class ListaRicetteComponent implements OnInit {
  lista_ricette: Ricetta[] = [];
  user !: User;


  constructor(
    private insDatiService: InsdatiService,
    private userService: UserService,
    private commonService: CommonService,
    private route: Router,
    private ricetteService: RicetteService,
  ) {

    this.commonService.setPageTitle('lista_ricette');

    this.init();


  }

  init() {


    this.userService.local_user().then(user => {
      this.user = user;
      if(this.userCanRicettario()){
        this.getRicetteUser();
      }
    })
  }

  ngOnInit(): void {
  }

  userCanRicettario() {
    return this.user.permessi.ricette == true;
  }

  getRicetteUser(){
    this.ricetteService.ricette_all().then(risp=>{
      //sucess
      console.log('ricette sucecss',risp);

      this.lista_ricette = risp  ;
      console.log(typeof  this.lista_ricette);
      console.log('listaricetet',this.lista_ricette);

    },err=>{
      //rejected
      console.log('ricette rejected',err);
    })
  }
}
