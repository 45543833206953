import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class GeneralService {


  constructor(
    private api : ApiService,

  ) { }

  async messaggio_home(    ): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.api.get('home' )
        .subscribe((risposta: any) => {
          if (risposta) {

            resolve(risposta.data);
          }
        }, (err: any) => {
          console.log(err);
          resolve(false);
        });
    });
  }


  async messaggio_personalizzato(    ): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.api.get('messaggio_personalizzato' )
        .subscribe((risposta: any) => {
          if (risposta && risposta.data) {
            resolve(risposta.data);
          }
        }, (err: any) => {
          console.log(err);
          resolve(false);
        });
    });
  }

  async messaggio_personalizzato_track( id:string   ): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.api.get('messaggio_personalizzato_listened/'+id )
        .subscribe((risposta: any) => {
          if (risposta && risposta.data) {
            resolve(risposta.data);
          }
        }, (err: any) => {
          console.log(err);
          resolve(false);
        });
    });
  }


  async faq(): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.api.get('faq' )
        .subscribe((risposta: any) => {
          if (risposta) {

            resolve(risposta.data);
          }
        }, (err: any) => {
          console.log(err);
          resolve(false);
        });
    });
  }
  async regole(): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.api.get('regole' )
        .subscribe((risposta: any) => {
          if (risposta) {

            resolve(risposta.data);
          }
        }, (err: any) => {
          console.log(err);
          resolve(false);
        });
    });
  }

  async regole_fase(fase:number): Promise<any> {
    return new Promise((resolve, reject): any => {

      let fasestring = 'regole-dieta-lemme';
      if(fase == 2){
        fasestring = 'regole-viaggio-culinario';
      }
      this.api.get(''+fasestring )
        .subscribe((risposta: any) => {
          if (risposta) {

            resolve(risposta.data);
          }
        }, (err: any) => {
          console.log(err);
          resolve(false);
        });
    });
  }

  async informazioni(): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.api.get('informazioni' )
        .subscribe((risposta: any) => {
          if (risposta) {

            resolve(risposta.data);
          }
        }, (err: any) => {
          console.log(err);
          resolve(false);
        });
    });
  }

  async load_settings(): Promise<any> {

    return new Promise((resolve, reject): any => {
      this.api.get('settings' )
        .subscribe((risposta: any) => {
          if (risposta) {
            resolve(risposta.data);
          }
        }, (err: any) => {
          console.log(err);
          resolve(false);
        });
    });
  }

  async gallery_app(  gallery:string  ): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.api.get('gallerie/'+gallery )
        .subscribe((risposta: any) => {
          if (risposta) {

            resolve(risposta.data);
          }
        }, (err: any) => {
          console.log(err);
          reject(false);
        });
    });
  }

}
