<div class="box-contatti-home">

  <app-bottone-lemme
    *ngIf="fissoLemme" (click)="openTel()"


    [titolo]="'components.contatti_home.chiama'|translate"
    [sottotitolo]="'components.contatti_home.chiama_sottotitolo'|translate"
    [icona]="'call'"
    [color]="'secondary'"
  ></app-bottone-lemme>


  <app-bottone-lemme
    *ngIf="waLemme" (click)="openChat()"

    [titolo]="'components.contatti_home.chat'|translate"
    [sottotitolo]="'components.contatti_home.chat_sottotitolo'|translate"
    [icona]="'message'"
    [color]="'third'"
  ></app-bottone-lemme>

  <app-bottone-lemme
    *ngIf="urlChat" (click)="openWhatsapp()"


    [titolo]="'components.contatti_home.wa'|translate"
    [sottotitolo]="'components.contatti_home.wa_sottotitolo'|translate"

    [icona]="'whatsapp'"
    [tipo]="'fab'"

    [color]="'green'"
  ></app-bottone-lemme>


  <app-bottone-lemme
    *ngIf="urlChat" (click)="openWebFA()"


    [titolo]="'components.contatti_home.sito'|translate"
    [sottotitolo]="'components.contatti_home.sito_sottotitolo'|translate"

    [icona]="'language'"


    [color]="'aqua'"
  ></app-bottone-lemme>


</div>
