import {Component, HostListener, OnInit} from '@angular/core';
import {DeviceInfo} from "ngx-device-detector";
import {DeviceService} from "../../services/device/device.service";
import {CommonService} from "../../services/common/common.service";
import {environment} from "../../../environments/environment";
import {BUILD_INFO} from "../../../environments/build-info";
import Bugsnag from "@bugsnag/js";

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  deviceInfo !: DeviceInfo;
  buildInfo: any = BUILD_INFO;
  env: any = environment;

  constructor(
    private deviceService: DeviceService,
    private commonService: CommonService,
  ) {
  }

  ngOnInit(): void {

    this.deviceInfo = this.deviceService.getDeviceInfo();

  }

  apriModal(tipo = '') {

    let titolo = 'Lorem ipsum';
    let testo = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque arcu ligula, fermentum ut mi consequat, tincidunt efficitur nulla. In sed rhoncus neque, vel molestie urna. ';

    switch (tipo) {
      case 'successo':
        this.commonService.alertSuccess(testo);
        break;
      case 'errore':
        this.commonService.alertError(testo);
        break;

      default:
        this.commonService.alertMsg(titolo, testo);
        break;
    }

  }

  inviaErroreJS() {
    throw new Error('errore manuale js - errore inviato manualmente da /#/test');
  }

  inviaBugsnag() {
    Bugsnag.notify(new Error('Test error - errore inviato manualmente da /#/test'))
  }
}
