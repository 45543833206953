import {Component, OnInit} from '@angular/core';
import {CommonService} from "../../services/common/common.service";
import {UserService} from "../../services/user/user.service";
import {User} from "../../models/user";
import {environment} from "../../../environments/environment";
import {Settings} from "../../models/settings";
import {GeneralService} from "../../services/general/general.service";

@Component({
  selector: 'app-acquista-lab-famiglia',
  templateUrl: './acquista-lab-famiglia.component.html',
  styleUrls: ['./acquista-lab-famiglia.component.scss']
})
export class AcquistaLabFamigliaComponent implements OnInit {
  user!: User;
  gallery : string[] = []


  constructor(
    private commonService: CommonService,
    private userService: UserService,
    private generalService : GeneralService,
  ) {
    this.user = new User();


  }

  ngOnInit(): void {
    this.loadUser();
    this.loadGallery();
  }

  loadUser() {
    this.userService.local_user().then(risp => {
      this.user = risp;
    });
  }
  loadGallery (){
    this.generalService.gallery_app('famiglia').then(risp=>{
      this.gallery =  risp;
    },err=>{
      this.gallery = [];
    });
  }

  openLabFamiglia() {

    if (this.user) {
      this.commonService.openURL(this.user.autologins.laboratorio_famiglia);

    } else {
      this.commonService.openURL(environment.lab_famiglia_url);
    }
  }
}
