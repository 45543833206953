<div class="chat-ai-external  ">

  <div id="chat-box" class="storico  " *ngIf="messaggi">
    <div class="" [className]="messaggio.ruolo+' messaggio'"  *ngFor="let messaggio of messaggi">

      <div   [className]="'inner-messaggio'"  *ngIf="messaggio">
        <div class="testo">{{messaggio.testo}}</div>
        <div class="data">{{messaggio.data | date :'d/M HH:MM'}}</div>
      </div>

    </div>
  </div>
  <form class="form-chat-ai"  >






        <div class="form-group">

          <div class="cta">

          <mat-form-field class="w-100 input-messaggio">


            <input type="text"
                   [disabled]="is_loading"
                   matInput [placeholder]=" 'chat_ai.messaggio' | translate "
                   [(ngModel)]="messaggio_utente"
                   name="messaggio"
            >
            <button mat-icon-button [disabled]="is_loading"  (click)="sendMessage()" matSuffix>
              <mat-icon>send</mat-icon>
            </button>

          </mat-form-field>





          </div>


        </div>



  </form>

</div>
