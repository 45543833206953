<div class="informazioni-container">


  <app-blocco-testo
    [titolo]="'infoutili.titolo'|translate"
    [testo]="'infoutili.descrizione'|translate"
  ></app-blocco-testo>

  <mat-card class="">
    <mat-card-content>

      <ngb-accordion *ngIf="faqs.length>0" [closeOthers]="true">
        <ngb-panel *ngFor="let faq of faqs">
          <ng-template ngbPanelTitle>
            <span>{{faq.titolo}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="innerText" [innerHTML]="faq.descrizione"></div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>

    </mat-card-content>
  </mat-card>

</div>
