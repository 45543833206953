import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../storage/storage.service";
import {registerLocaleData} from "@angular/common";
import {timestamp} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  currentLanguage !: string;
  currentLanguageObj !: any;


  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,

  ) {


  //  this.setDefaultLang();


  }

  setDefaultLang() {

    console.log('setDefaultLang()');

    //TODO - controllo lingua browser
    let browserLang = this.translateService.getBrowserLang();
    console.log('browserLang', browserLang);
    let localLang = this.storageService.get('language');
    console.log('localLang', localLang);
    let LangToUse = environment.default_language;
    let possibileLang = environment.possibile_languages.map(o => o['code']);
    console.log('possibileLang',possibileLang);

    if (browserLang && browserLang.includes(LangToUse)) {
      //console.log('######browserLang',browserLang);
      LangToUse = browserLang;
    }
    if (localLang) {
      LangToUse = localLang;
    }

   // console.log('STO PER USARE', LangToUse)
    this.changeLanguage(LangToUse);

  }

  changeLanguage(newlang: string) {

    console.log('changeLanguage()');

    this.currentLanguage = newlang;
    this.currentLanguageObj = environment.possibile_languages.filter(item => item.code == this.currentLanguage)[0];


    this.translateService.use(this.currentLanguage);

    console.log('cambio lingua in ' + this.currentLanguage);


    this.storageService.set('language', this.currentLanguage);



  }



  getLanguageForLocale( ) {

    this.setDefaultLang();
    console.log(this.currentLanguageObj) ;
    return this.currentLanguageObj.locale;


}

}


// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.language_translation_url, environment.language_translation_suffix);
}


