import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {SupportService} from "../../services/support/support.service";
import {Assistenza} from "../../models/assistenza";
import {CommonService} from "../../services/common/common.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  loading: boolean = false;
  submitted: boolean = false;
  form!: UntypedFormGroup;

  // convenience getter for easy access to form fields
  supportRequestList: Assistenza[] = [];


  constructor(
    private formBuilder: UntypedFormBuilder,
    private supportService: SupportService,
    private commonService: CommonService,
    private translateService:TranslateService,
  ) {
    this.commonService.setPageTitle('assistenza');

    this.setFormData();

  }


  ngOnInit(): void {
    this.loadAllSupport();
  }


  get f() {
    return this.form.controls;
  }

  async loadAllSupport() {
    this.supportService.get_supports().then(risp => {
      this.supportRequestList = risp;
    }, err => {

    });
  }

  async onSubmit() {

    this.submitted = true;

    console.log('form', this.form);


    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    //this make call

    await this.supportService.post_support(this.form.value).then(risp => {

      // console.log('rispostapostassistneza', risp);

      if (risp) {
        //risp è un ogggetto, contiene la richiesta ssistenza

        //mostro modal successo
        let assistenzaText = this.translateService.instant('assistenza.assistenza_successo');
        this.commonService.alertSuccess(assistenzaText);

        this.loadAllSupport();


      }

    }, err => {

      console.log('err', err);
      this.commonService.alertError(err.messages);

    });

    this.svuotaForm();


    this.loading = false;

  }

  setFormData() {
    this.form = this.formBuilder.group({
      oggetto: ['', [Validators.required]],
      testo: ['', Validators.required],
      tipo: ['1']
    });
  }

  svuotaForm() {
    console.log('svuotaTesto');
    //this.form.value.oggetto.setValue('') ;

    this.setFormData();
  }

}
