<app-blocco-testo
  [titolo]="'login.titolo'|translate"
  [testo]="'login.descrizione'|translate"

></app-blocco-testo>


<form [formGroup]="form" (ngSubmit)="onSubmit()">


  <mat-card class="card">
    <mat-card-content>


      <div class="card-body">


        <div class="form-group">

          <mat-form-field>
            <mat-label for="username">{{'login.username'|translate}}</mat-label>
            <input matInput type="text" formControlName="username" class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <mat-error *ngIf="f.username.errors.required">{{'login.username_required'|translate}}</mat-error>
              <mat-error *ngIf="f.username.errors.email">{{'login.username_email'|translate}}</mat-error>
            </div>
          </mat-form-field>

        </div>


        <div class="form-group">


          <mat-form-field>
            <mat-label for="password">{{'login.password'|translate}}</mat-label>
            <input matInput type="password" formControlName="password" class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <mat-error *ngIf="f.password.errors.required">{{'login.password_required'|translate}}</mat-error>
            </div>
          </mat-form-field>


        </div>

      </div>
    </mat-card-content>
  </mat-card>


  <div class="form-group link-group">

    <div class="login-btn-cont">
    <button [disabled]="loading" class="btn btn-lemme-full">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{'login.login' | translate}}
    </button>
    </div>

    <div class="register-btn-cont">
      <a (click)="openRegistrati()" class="btn btn-lemme-link"> {{'login.registrati' | translate}}</a>
    </div>

    <div class="passowrd-btn-cont">
      <a (click)="openResetPwd()" class="btn btn-lemme-link"> {{'login.reset_password' | translate}}</a>
    </div>

  </div>
</form>

