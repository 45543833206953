import {Component, HostListener, ViewChild} from '@angular/core';
import {BreakpointObserver, BreakpointState, Breakpoints, MediaMatcher} from "@angular/cdk/layout";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {UserService} from "./services/user/user.service";
import {GeneralService} from "./services/general/general.service";
import {BroadcasterService} from "./services/broadcaster/broadcaster.service";
import {environment} from "../environments/environment";
import {LanguageService} from "./services/language/language.service";
import {CommonService} from "./services/common/common.service";
import {BUILD_INFO} from "../environments/build-info";
import {THREE} from "@angular/cdk/keycodes";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  versioneApp = BUILD_INFO.version;
  title = 'Dr. Lemme';
  is_user_logged: boolean = false;
  languages = environment.possibile_languages;
  current_language = this.languageService.currentLanguage;
  current_language_obj = this.languageService.currentLanguageObj;

  linksMenu: any = [];
  messaggio_personalizzato: any;

  isMobile = false;

  @ViewChild('snav') public snav: any;
  chat_ai_abilitata:boolean =  false;
  additionalContentClass: string = '';

  constructor(
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    private userService: UserService,
    private generalService: GeneralService,
    private broadcaster: BroadcasterService,
    private languageService: LanguageService,
    private commonService: CommonService,

  ) {


    this.userService.init().then(risp => {
      this.initApp();
    });


    this.current_language = this.languageService.currentLanguage;
    // alert(this.current_language );
    this.current_language_obj = this.languageService.currentLanguageObj;
    //sul back button mobile ricarico pagina , se no perdo le chiamate api
    /* TODO - finire blocco di codice per evitare che sul back del telefono chrome perda i dati

    this.router.events
      .subscribe((event: any) => {
        console.log('routevent')
        if (event.navigationTrigger === 'popstate') {
          // Perform actions
         // alert('balc');
        }
      });
    */


  }


  ngOnInit() {


  }

  async initApp() {
    this.loadAppSettings();
    this.listenAppRouteChange();
    this.listenWindowBreakpoints();




    this.listenLoginLogout();
    this.listenHttpStatus();
    this.loadUserData();
    this.canViewChatGpt();


    this.loadMenuItems();


  }

  //ad ogni esecuzione app carico l'utente,
  // per evitare che sia cambiato qualcosa da pannello e io continuo a caricare l'utente in localstorage
  loadUserData() {
    console.log('loadUserData APP');
    this.userService.user().then(resp => {
      console.log('user', resp);
    });
  }

  clickLinkMenu(): void {

    if (this.isMobile) {
      this.snav.close();
    }

  }


  async logout() {

    this.userService.logout().then(resp => {

      this.broadcaster.is_user_logged(false);
      this.clickLinkMenu();
      this.initApp();

      this.router.navigateByUrl('/');
      window.location.reload();

    });

  }

  async loadMenuItems() {
    this.is_user_logged = this.userService.is_user_logged;
    this.chat_ai_abilitata = await this.userService.local_user().then(risp=>{
      return risp.chat_ai_abilitata;
    });

    console.log('this.is_user_logged', this.is_user_logged);
    this.linksMenu = [
      {
        title: 'dashboard',
        route: '/dashboard',
        icon: 'dashboard',
        canShow: true,

      },
      {
        title: 'login',
        route: '/login',
        icon: 'login',
        canShow: !this.is_user_logged,


      },
      {
        title: 'profilo',
        route: '/profile',
        icon: 'person',
        canShow: this.is_user_logged,
      },
      {
        title: 'novita',
        route: '/news',
        icon: 'article',
        canShow: true,
      },
      {
        title: 'audioricette',
        route: '/audio-ricette',
        icon: 'mic',
        canShow: this.is_user_logged,
        evidenza: true,
      },
      {
        title: 'universita',
        route: '/universita',
        icon: 'school',
        canShow: true,
      },
      {
        title: 'ins_dati',
        route: '/inserisci-dati',
        icon: 'edit',
        canShow: this.is_user_logged,
      },


      {
        title: 'prossimi_menu',
        route: '/prossimi-menu',
        icon: 'restaurant',
        canShow: this.is_user_logged,
      },

      {
        title: 'piatti_acquistati',
        route: '/piatti-acquistati',
        icon: 'inventory_2',
        canShow: this.is_user_logged,
      },

      {
        title: 'lista_spesa',
        route: '/lista-spesa',
        icon: 'list_alt',
        canShow: this.is_user_logged,
      },
      {
        title: 'lista_ricette',
        route: '/lista-ricette',
        icon: 'list',
        canShow: this.is_user_logged,
      },
      {
        title: 'piatti_f1',
        route: '/piatti-pronti/1',
        icon: 'set_meal',
        canShow: this.is_user_logged,
        evidenza: true,
      },

      {
        title: 'piatti_f2',
        route: '/piatti-pronti/2',
        icon: 'set_meal',
        canShow: this.is_user_logged,
        evidenza: true,
      },


      {
        title: 'faq',
        route: '/faq',
        icon: 'quiz',
        canShow: true,

      },


      {
        title: 'regole_f1',
        route: '/regole/1',
        icon: 'list',
        canShow: this.is_user_logged,
        evidenza: true,
      },

      {
        title: 'regole_f2',
        route: '/regole/2',
        icon: 'list',
        canShow: this.is_user_logged,
        evidenza: true,
      },



      {
        title: 'info',
        route: '/informazioni',
        icon: 'info',
        canShow: this.is_user_logged,
      },
      {
        title: 'settings',
        route: '/settings',
        icon: 'settings',
        canShow: this.is_user_logged,
      },
      {
        title: 'assistenza',
        route: '/support',
        icon: 'report_problem',
        canShow: this.is_user_logged,
      },
      {
        title: 'storico',
        route: '/history',
        icon: 'calendar_month',
        canShow: this.is_user_logged,
      },


      {
        title: 'grafici',
        route: '/graphs',
        icon: 'insights',
        canShow: this.is_user_logged,
      },

      {
        title: 'chat',
        route: '/chat-con-lemme',
        icon: 'chat',
        canShow: true,
      },

      {
        title: 'genio_ai',
        route: '/genio-ai',
        icon: 'quickreply',
        canShow: this.chat_ai_abilitata,
      },

      {
        title: 'chat_ai',
        route: '/chat-ai',
        icon: 'reply',
        canShow: this.chat_ai_abilitata,
        additional_class: 'full-height-fixed-content',
      },





      /*
            {

              title: 'TEST',
              route: '/test',
              icon: 'help',

              canShow: true,

            },
            */

    ];
    this.activateCurrentClass();
  }

  activateCurrentClass(){
    let currentUrl = window.location.href;



    this.linksMenu.forEach((link:any) => {
      if(  currentUrl.includes(link.route) ){
        console.log('controllo => ',currentUrl, link.route);
        this.additionalContentClass = link.additional_class??'';
      }
    });
  }

  changeLanguage(value: any) {

    console.log('changeLanguage', value);
    this.languageService.changeLanguage(value);
    window.location.reload();
    //this.router.navigate(['/']);

  }


  /*FUNZIONI PER ADD2HOMESCREEN CHROME>67 a2hs*/

  deferredPrompt: any;
  showButton = false;

  @HostListener('window:beforeinstallprompt', ['$event'])

  onbeforeinstallprompt(e: any) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }


  addToHomeScreenChrome() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });


  }

  private listenWindowBreakpoints() {
    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((state: BreakpointState) => {

        console.log('state', state);
        if (state.matches) {

          console.log(
            'SMALL'
          );
          this.isMobile = true;

        } else {

          console.log(
            'LARGE'
          );

          this.isMobile = false;
        }
      });
  }

  private listenLoginLogout() {
    //ascolto eventuali cambi stato utente
    this.broadcaster.is_user_logged$.subscribe(is_logged => {
      // some code
      if (is_logged) {
        // this.title = 'Loggtato';
      } else {
        // this.title = 'Non loggato'
      }

      this.loadMenuItems();
    });
  }


  private listenHttpStatus() {
    //ascolto eventuali cambi stato utente
    this.broadcaster.http_status$.subscribe(errorObj => {

      console.log('Visto http status', errorObj.status);
      let messaggi = [];


      switch (errorObj.status) {
        case  401 :

          break;

        case 400:
          if (errorObj && errorObj.error && errorObj.error.messages) {
            messaggi = errorObj.error.messages;
          }
          break;

        case  422   :


          //console.log('mostro errore alert');
          //caso errore validator
         // console.log('err.error.errors', errorObj.error.errors);
          let messaggierrore = Object.keys(errorObj.error.errors).map(it => errorObj.error.errors[it]);
         // console.log('messaggierrore', messaggierrore);
          /* */
          for (let messaggio of messaggierrore) {
            //console.log('messaggio', messaggio);
            for (let msg of messaggio) {
              //console.log('msg', msg);
              messaggi.push(msg);
            }
          }
         // console.log('messaggi', messaggi);


          break;


        default :

          break;
      }


      if (messaggi && messaggi.length) {
        this.commonService.alertError(messaggi);
      }


    });
  }


  private listenAppRouteChange() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        //console.log('ROUTE CHANGED');
        //console.log(event.url)
        //  this.routerChangeMethod(event.url);
      }
    })
  }


  private loadAppSettings() {
    this.generalService.load_settings().then(risp => {
      //console.log('SETTINGS', risp);
      if (risp) {
        this.commonService.AppSettings = risp;
        //console.log('SETTINGS2', this.commonService.AppSettings);
      }
    });
  }

  private   canViewChatGpt() {

    this.userService.local_user().then( user => {
      if (user && user.chat_ai_abilitata) {
        this.chat_ai_abilitata =  true;
      } else {
        this.chat_ai_abilitata =  false;
      }
      console.log('this.chat_ai_abilitata',this.chat_ai_abilitata);
      console.log(user);

      this.commonService.reloadDOM();

    });

  }

  navigateMenu(link: any) {
    //[routerLink]="link.route"
    this.additionalContentClass = link.additional_class??'';
    this.router.navigateByUrl(link.route);
  }
}
