import {Component, Input, OnInit} from '@angular/core';
import {Menu, Piatto} from "../../models/menu";
import {CommonService} from "../../services/common/common.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
  selector: 'app-piatto-box',
  templateUrl: './piatto-box.component.html',
  styleUrls: ['./piatto-box.component.scss']
})
export class PiattoBoxComponent implements OnInit {

  titolo !: string;

  @Input() piatto !: Piatto; // decorate the property with @Input()
  @Input() chiave !: string; // decorate the property with @Input()
  @Input() menu !: Menu; // decorate the property with @Input()


  constructor(
    private commonService: CommonService,
    private translateService : TranslateService,
    private router : Router,
  ) {
   // alert(this.chiave);

  }

  ngOnInit(): void {

    this.titolo =  this.translateService.instant('generico.portate.'+this.chiave);
  }

  showIngredienti() {

    let ingredienti = '';
    if(this.piatto.tipologia == 'ricetta'){
      ingredienti = this.piatto.ingredienti.join(', ')
    }else{
      ingredienti = this.piatto.ingredienti.map(x=>x.nome).join(', ')
    }

    this.commonService.alertMsg(
      this.piatto.nome,
      ingredienti,
  )
  }

  cambioPiatto() {
    //alert('cambioPiatto');
    this.router.navigate(['/cambio-piatto'], {
      state:{
        portata : this.chiave,
        idmenu: this.menu.id,
      }
    });

  }
}
