<div class="chat-ai-external">

  <div class="risposta-ext" *ngIf="risposta">

    <div class="risposta text">
      {{risposta?.testo}}
    </div>
    <button class="btn btn-lemme-full"
            (click)="resetDomanda()">
      {{'genio_ai.reset'|translate}}
    </button>

  </div>


  <form class="form-chat-ai" *ngIf="!risposta">

    <div class="spiegazione">
      {{ 'genio_ai.testo_introduttivo' | translate }}
    </div>

    <mat-card class="chat-card">
      <mat-card-content>


        <div class="form-group">

          <mat-form-field>
            <mat-label>{{ 'genio_ai.messaggio' | translate }}</mat-label>

            <textarea name="messaggio" matInput rows="4"
                      [(ngModel)]="messaggio_utente"></textarea>

          </mat-form-field>


          <div class="cta">


            <button class="btn btn-lemme-full"
                    [disabled]="is_loading"
                    (click)="sendMessage()">
              <span *ngIf="is_loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{'genio_ai.invia'|translate}}
            </button>
          </div>


        </div>



      </mat-card-content>
    </mat-card>

  </form>

</div>
