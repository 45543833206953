export class Ricetta {

  id!: number;
  titolo !: string;
  descrizione !: string;
  ingredienti !: string[];
  passaggi !: string[];
  tags !: {
    id:number,
    nome:string,
  }[];
  pasti !: string[];

  foto !: string;
  abilitata !: boolean;
  ricettario !: boolean;


  has_foto !: boolean;

  //TODO controllare
  public constructor(init?:Partial<Ricetta>) {
    Object.assign(this, init);
  }
  tagsAsArray() {
    let res = this.tags.map(o => o['nome']);
   // console.log(res);
    return res;
  }
  tagsAsArrayIDs() {
    let res = this.tags.map(o => o['id']);
    // console.log(res);
    return res;
  }

}
