import {Component, OnInit} from '@angular/core';
import {GeneralService} from "../../services/general/general.service";
import {Faq} from "../../models/faq";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqs: Faq[] = [];

  constructor(
    private generalService: GeneralService,
    private commonService: CommonService,
  ) {
    this.commonService.setPageTitle('faq');

  }

  ngOnInit(): void {
    this.generalService.faq().then(res => {
      this.faqs = res;
    }, err => {
      console.log('errore', err);
    });


  }

}
