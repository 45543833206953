<div class="audioricetta-loop-list">

  <div class="audioricetta-loop-list-inn" [routerLink]="['/audio-ricette-detail', audioricetta.id]">

    <div class="d-flex justify-content-between align-items-center">


      <div class="image">
        <img mat-card-image [src]="audioricetta.immagine" *ngIf="audioricetta.immagine">
      </div>



      <div class="testo flex-grow-1">
        <h4 class="titolo">{{audioricetta.titolo}}</h4>

      </div>



      <div class="icon">
        <mat-icon>chevron_right</mat-icon>
      </div>



    </div>




  </div>


</div>
