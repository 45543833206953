import {Component, Input, OnInit} from '@angular/core';
import * as moment from "moment";
import {DatePipe} from "@angular/common";


@Component({
  selector: 'app-bottone-lemme',
  templateUrl: './bottone-lemme.component.html',
  styleUrls: ['./bottone-lemme.component.scss']
})
export class BottoneLemmeComponent implements OnInit {
  @Input() titolo = '';
  @Input() sottotitolo = '';
  @Input() icona = '';
  @Input() iconaimg = '';
  @Input() color = '';
  @Input() tipo = 'mat';


  constructor(
    private datePipe: DatePipe
  ) {


  }

  ngOnInit(): void {
    let date = moment(this.sottotitolo);
    console.log('date', date);
    console.log('sottotitolo', this.sottotitolo);

    if (date.isValid()) {

      this.sottotitolo = this.datePipe.transform(this.sottotitolo)??''.toString() ;
    }
  }

}
