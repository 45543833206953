import { Component, OnInit } from '@angular/core';
import {CommonService} from "../../services/common/common.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-chiama-lemme',
  templateUrl: './chiama-lemme.component.html',
  styleUrls: ['./chiama-lemme.component.scss']
})
export class ChiamaLemmeComponent implements OnInit {

  constructor(
    private commonService: CommonService ,
  ) { }

  ngOnInit(): void {
  }
  chiamaCell1(){
    this.commonService.openTelURL(environment.lemme_cell1);
  }
  chiamaCell2(){
    this.commonService.openTelURL(environment.lemme_cell2);
  }
}
