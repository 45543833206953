<h2 mat-dialog-title>{{'piatti_acquistati.modal_titolo'|translate}}</h2>
<mat-dialog-content class="mat-typography">
  <div class="spiegazione">{{'piatti_acquistati.modal_descrizione'|translate}}</div>

  <mat-form-field appearance="fill">
    <mat-select [(ngModel)]="qta_scelta">
      <mat-option *ngFor="let num of qtaArr" [value]="num">
        {{num}}
      </mat-option>
    </mat-select>
  </mat-form-field>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'generico.annulla'|translate}}</button>
  <button mat-button (click)="aggiornaQtaUsata()">{{'generico.conferma'|translate}}</button>

</mat-dialog-actions>


