import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CommonService} from "../../services/common/common.service";
import {UserService} from "../../services/user/user.service";
import {User} from "../../models/user";

@Component({
  selector: 'app-piatti-pronti',
  templateUrl: './piatti-pronti.component.html',
  styleUrls: ['./piatti-pronti.component.scss']
})
export class PiattiProntiComponent implements OnInit {
  fase !: string | null;
  langsufx = '';
  elementi: any[] = [];
  user !: User;


  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private commonService: CommonService,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.fase = params.get('fase');

      if (!this.fase) {
        this.fase = '1';
      }

      if (this.fase == '2') {
        this.langsufx = '_f2';
      }
      this.commonService.setPageTitle('piatti_f' + this.fase);
      this.loadUser();
      this.caricaElementi();

    });
  }

  private async caricaElementi() {

    //chiamata finta per essere sicuri che le traduzioni siano caricate
    await this.translateService.get('dummyTranslation').toPromise().then();
    this.elementi = [];


    if(this.user.autologins.scegli_tu){
      this.elementi.push({
        nome: this.translateService.instant('piatti_pronti' + this.langsufx + '.tipi.scegli_tu.nome'),
        desc_corta: this.translateService.instant('piatti_pronti' + this.langsufx + '.tipi.scegli_tu.desc_corta'),
        desc_lunga: this.translateService.instant('piatti_pronti' + this.langsufx + '.tipi.scegli_tu.desc_lunga'),
        url: this.user.autologins.scegli_tu,
      });
    }



    if(this.user.autologins.laboratorio_famiglia){
      this.elementi.push({
        nome: this.translateService.instant('piatti_pronti' + this.langsufx + '.tipi.laboratorio_famiglia.nome'),
        desc_corta: this.translateService.instant('piatti_pronti' + this.langsufx + '.tipi.laboratorio_famiglia.desc_corta'),
        desc_lunga: this.translateService.instant('piatti_pronti' + this.langsufx + '.tipi.laboratorio_famiglia.desc_lunga'),
        url: this.user.autologins.laboratorio_famiglia,
      });
    }




    if(this.user.autologins.percorso_salute){
      this.elementi.push({
        nome: this.translateService.instant('piatti_pronti' + this.langsufx + '.tipi.progetto_salute.nome'),
        desc_corta: this.translateService.instant('piatti_pronti' + this.langsufx + '.tipi.progetto_salute.desc_corta'),
        desc_lunga: this.translateService.instant('piatti_pronti' + this.langsufx + '.tipi.progetto_salute.desc_lunga'),
        url: this.user.autologins.percorso_salute,
      });
    }


  }

  openUrl(url: string) {
    this.commonService.openURL(url);

  }

  private loadUser() {
    this.userService.local_user().then(user => {
      this.user = user;
    })
  }
}
