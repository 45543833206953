import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  constructor(
    private api : ApiService,
  ) { }

  async get_singolo_grafico(tipo:string, giorni:number): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('storico/grafici/'+tipo+'/'+giorni.toString())

        .subscribe((risposta: any) => {

          if (risposta) {
            resolve(risposta.data);
          }
        }, (err: any) => {


          console.log('ERRORE lista_categorie()');
          console.log(err);

          resolve(false);

        });
    });
  }


}
