<div class="messaggio-personalizzato-ext" *ngIf="messaggio_personalizzato">

  <div class="messaggio-personalizzato-cont">

    <div class="blocco blocco-foto">
      <img src="assets/images/msgaudio/lemme.png"/>
    </div>
    <div class="blocco blocco-audio">

      <h3 class="titolo" *ngIf="!messaggio_personalizzato.titolo_box">
        {{'components.messaggiopersonalizzatobox.titolo' | translate }}
      </h3>
      <h3 class="titolo" *ngIf="messaggio_personalizzato.titolo_box">
        {{messaggio_personalizzato.titolo_box}}
      </h3>

      <app-audio-player  [tracking_id]="messaggio_personalizzato.id"  [audio]="messaggio_personalizzato.audio"></app-audio-player>
    </div>
    <div class="blocco blocco-share">



      <div class="condividi-cont" *ngIf="messaggio_personalizzato.notizia_id">


        <div class="testo">

          <svg viewBox="0 0 130 130">
            <path id="MyPath" fill="none" stroke="lightblue"
                  d="m10,50c50,-50 96,3 96,3" />
            <text><textPath href="#MyPath">{{'generico.condividi_base'|translate}}</textPath></text>
          </svg>

        </div>

        <button color="primary" mat-mini-fab (click)="apriShareNotizia()">
          <mat-icon>share</mat-icon>
        </button>
      </div>


    </div>

  </div>
</div>


