import {Component, OnInit} from '@angular/core';
import {GeneralService} from "../../services/general/general.service";

@Component({
  selector: 'app-messaggio-giorno',
  templateUrl: './messaggio-giorno.component.html',
  styleUrls: ['./messaggio-giorno.component.scss']
})
export class MessaggioGiornoComponent implements OnInit {
  messaggio_home: any;

  constructor(
    private generalService: GeneralService,
  ) {
  }

  ngOnInit(): void {
    this.loadMessaggioHome();
  }

  loadMessaggioHome() {
    this.generalService.messaggio_home().then(risp => {
      this.messaggio_home = risp;
    }).catch(err => {

    });
  }

  toggleDetailMSGHome() {
    console.log('toggleDetailMSGHome');
  }
}
