<div class="news-categories-cont">

  <div class="categories-desc">
    {{'news.descrizione' | translate}}
  </div>

  <mat-card *ngIf="categorie" class=" ">
    <mat-card-content>

      <div class="category-list" *ngIf="categorie && categorie.length>1">
        <div class="category-list-item" *ngFor="let categoria of categorie;let i = index">

          <app-bottone-lemme
            [routerLink]="['/news-list/'+categoria.id]"
            [titolo]="categoria.nome"
            [sottotitolo]="categoria.data_ultima_notizia?categoria.data_ultima_notizia:''  "
            [icona]="iconlist[categoria.id]"
            [color]="i==0?'third':'grey'"
          ></app-bottone-lemme>
        </div>
      </div>


    </mat-card-content>
  </mat-card>

</div>
