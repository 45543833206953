import { Injectable } from '@angular/core';
import {DeviceDetectorService, DeviceInfo} from "ngx-device-detector";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  deviceInfo !: DeviceInfo;
  constructor(
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.deviceInfo =  this.deviceDetectorService.getDeviceInfo();
    console.log('deviceInfo',this.deviceInfo);
  }

  getDeviceInfo(){
    return this.deviceInfo;
  }
  canShowA2HSDirect(){

  }
  isIOS(){
       return this.deviceInfo.os == "iOS";
  }
  isAndroid(){
    return this.deviceInfo.os == "Android";
  }





  private newVariable: any;


  isInsideWebAppHomeScreen(){
    this.newVariable = window.navigator;
    if (window.matchMedia('(display-mode: standalone)').matches || this.newVariable.standalone === true) {
      return true;
    }else{
      return false;
    }
  }

}
