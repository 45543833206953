import {Component, OnInit} from '@angular/core';
import {UserService} from "../../services/user/user.service";
import {BroadcasterService} from "../../services/broadcaster/broadcaster.service";

@Component({
  selector: 'app-recap-ultimi-dati',
  templateUrl: './recap-ultimi-dati.component.html',
  styleUrls: ['./recap-ultimi-dati.component.scss']
})
export class RecapUltimiDatiComponent implements OnInit {
  ultimi_valori: any;
  user: any;
  is_user_logged: boolean = false;

  constructor(
    private userService: UserService,
    private broadcaster: BroadcasterService,
  ) {
  }

  ngOnInit(): void {

    this.is_user_logged = this.userService.is_user_logged;
    if (this.is_user_logged) {
      this.loadUtente();
      this.loadUltimiValori();
    }


    //ascolto eventuali cambi stato utente
    this.broadcaster.is_user_logged$.subscribe(is_logged => {
      this.broadcaster.is_user_logged$.subscribe(is_logged => {
        //è cambiato lo stato dell'utente, rifaccio la chiamata
        if (is_logged) {
          this.loadUtente();
          this.loadUltimiValori();
        } else {
          this.ultimi_valori = this.user = null
        }
      });
    });


  }

  loadUtente() {
    this.userService.local_user().then(risp => {
      this.user = risp;
    }, err => {

    });
  }

  loadUltimiValori() {


    this.userService.user_ultime().then(risp => {
      this.ultimi_valori = risp;
    }, err => {

    });
  }


}
