import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-share-lemme',
  templateUrl: './share-lemme.component.html',
  styleUrls: ['./share-lemme.component.scss']
})
export class ShareLemmeComponent implements OnInit {
  @Input() url = '';

  constructor() { }

  ngOnInit(): void {
  }

}
