import {Component, Input, OnInit} from '@angular/core';
import {Notizia} from "../../models/notizia";
import {Menu} from "../../models/menu";

@Component({
  selector: 'app-menu-box',
  templateUrl: './menu-box.component.html',
  styleUrls: ['./menu-box.component.scss']
})
export class MenuBoxComponent implements OnInit {
  @Input() menu !:Menu ; // decorate the property with @Input()


  constructor() { }

  ngOnInit(): void {
  }

}
