import {Injectable} from '@angular/core';
import {LocalStorageService} from "ngx-localstorage";


@Injectable({
  providedIn: 'root'
})
export class StorageService {


  constructor(private storage: LocalStorageService) {

  }


  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    //

    this.storage.set(key, value);

  }


  public get(key: string) {
    return this.storage.get(key);

  }


  public remove(key: string) {
    this.storage.remove(key);
  }


}
