import {Component, OnInit} from '@angular/core';
import {NewsService} from "../../services/news/news.service";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-news-categories',
  templateUrl: './news-categories.component.html',
  styleUrls: ['./news-categories.component.scss']
})
export class NewsCategoriesComponent implements OnInit {
  categorie: any[] = [];
  iconlist: any = environment.icone_news ;

  constructor(
    private newsService: NewsService,
    private translationService: TranslateService,
    private commonService:CommonService,
  ) {

  }

  ngOnInit(): void {
    this.commonService.setPageTitle('novita_categorie');
    this.caricaCategorie();

  }

  async caricaCategorie() {
    //chiamata finta per essere sicuri che le traduzioni siano caricate
    await this.translationService.get('dummyTranslation').toPromise().then();

    let allnews = {
      id: "",
      nome: this.translationService.instant('news.tutte_news'),
    };
    this.categorie.push(allnews);
    this.newsService.lista_categorie().then(risp => {
      this.categorie.push(...risp);
      // console.log('risp123', this.categorie);
    }, errore => {
      console.log(errore);
    });


  }
}
