import { Component, OnInit } from '@angular/core';
import {GeneralService} from "../../services/general/general.service";
import {CommonService} from "../../services/common/common.service";
import {environment} from "../../../environments/environment";



@Component({
  selector: 'app-rinnova-app',
  templateUrl: './rinnova-app.component.html',
  styleUrls: ['./rinnova-app.component.scss']
})
export class RinnovaAppComponent implements OnInit {

  constructor(
    private  commonService: CommonService,

  ) {

  }


  ngOnInit(): void {
  }

  launchRinnova( ){

    this.commonService.openURL(environment.rinnova_app_url);

  }
}
