import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UniversitaService} from "../../services/universita/universita.service";
import {Capitolo} from "../../models/lezione";
import {DialogBasicComponent} from "../../components/dialog-basic/dialog-basic.component";
import {MatDialog} from "@angular/material/dialog";
import {
  DialogRispostaUniversitaComponent
} from "../../components/dialog-risposta-universita/dialog-risposta-universita.component";

@Component({
  selector: 'app-universita-capitolo',
  templateUrl: './universita-capitolo.component.html',
  styleUrls: ['./universita-capitolo.component.scss']
})
export class UniversitaCapitoloComponent implements OnInit {
  private lezione_id: any;
  private capitolo_id: any;
  capitolo !: Capitolo;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private universitaService: UniversitaService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.lezione_id = params.get('id_lezione');
      this.capitolo_id = params.get('id_capitolo');
      if (this.lezione_id) {
        this.loadCapitolo();
      } else {
        //non ho il parametro id, torno indietro a lezioni list
        this.router.navigate(['/universita']);
      }
    });
  }


  loadCapitolo() {
    //console.log('loadCapitolo', this.capitolo_id);
    this.universitaService.get_capitolo(this.lezione_id, this.capitolo_id).then(risp => {
      if (risp) {
        this.capitolo = risp;
      }
    })
  }

  lanciaModalRispost(domanda: string) {
    // alert('risposta==>: ' + domanda);
    this.dialog.open(DialogRispostaUniversitaComponent, {

      data: {
        tipo: domanda,
        id_lezione: this.lezione_id,
        id_capitolo: this.capitolo_id,
      },
      //panelClass : 'error-dialog',
    });


  }
}
