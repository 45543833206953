import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PlyrComponent} from "ngx-plyr";

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @Input() video = '';
  @Input() poster = '';

    // get the component instance to have access to plyr instance
  @ViewChild(PlyrComponent)

  plyr !: PlyrComponent;

// or get it from plyrInit event
  player !: Plyr;

  videoOptions!: Plyr.Options;

  videoSources: Plyr.Source[] = [];



  constructor() {
    this.videoOptions = {

    /* autoplay: true,
      muted: true,*/

      controls : [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
       // 'captions',
        //'settings',
        //'pip',
        // 'airplay',
        'fullscreen'
      ],


    };
    console.log('videoOptions', this.videoOptions);
  }

  ngOnInit(): void {

    this.videoSources = [
      {
        src: this.video,
        provider: 'html5',
      },
    ];


  }



  played(event: Plyr.PlyrEvent) {
    console.log('played2', event);
  }

  play(): void {
    this.player.play(); // or this.plyr.player.play()
  }


  assignPlayer($event: Plyr) {
    this.player = $event
  }
}
