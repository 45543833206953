<div class="box-acquista box-acquista-lab"  >


  <div class="immagine" *ngIf="gallery.length">
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let img of gallery">
        <div class="picsum-img-wrapper">
          <img class="img-fluid" [src]="img" alt="">
        </div>
      </ng-template>

    </ngb-carousel>
  </div>


  <app-bottone-lemme
    (click)="openLabFamiglia()"

    [titolo]="'components.acquistafamiglia.bottone'|translate"
    [sottotitolo]="'components.acquistafamiglia.bottone_sottotitolo'|translate"
    [icona]="'shopping_cart'"
    [color]="'secondary'"
  ></app-bottone-lemme>

</div>


