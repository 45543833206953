

<div class="piatti-pronti-cont">

  <app-blocco-testo
    [titolo]="'piatti_pronti'+langsufx+'.nome'|translate"
    [testo]="'piatti_pronti'+langsufx+'.desc_lunga'|translate"
  ></app-blocco-testo>

  <mat-card class="">
    <mat-card-content>
      <div class="dati">
        <ngb-accordion class="piatti-pronti-acc" *ngIf="elementi.length>0" [closeOthers]="true"  >
          <ngb-panel    *ngFor="let elemento of elementi">
            <ng-template ngbPanelTitle>
              <div class="inner-titolo">
                <h3 class="titolo">{{elemento.nome}}</h3>

                <!--
                <div class="descrizione-breve" [innerHTML]="elemento.desc_corta"></div>
                -->
              </div>

            </ng-template>
            <ng-template ngbPanelContent>
              <div class="innerText" [innerHTML]="elemento.desc_lunga"></div>
              <app-bottone-lemme
                [titolo]="elemento.nome"
                icona="shopping_cart"
                (click)="openUrl(elemento.url)"
              ></app-bottone-lemme>
            </ng-template>
          </ngb-panel>

        </ngb-accordion>
      </div>


    </mat-card-content>
  </mat-card>









</div>
