<mat-card  class="   "  *ngIf="messaggio_personalizzato" #messaggiopersonalizzatoboxRef >
  <mat-card-content>

    <div class="messaggio-personalizzato-box-inner text-center">

      <h2 class="titolo">{{'components.messaggiopersonalizzatobox.titolo' | translate }}</h2>

      <h4 class="titolomsg" *ngIf="messaggio_personalizzato.titolo">{{messaggio_personalizzato.titolo}}</h4>
      <div class="testomsg" *ngIf="messaggio_personalizzato.messaggio" [innerHTML]="messaggio_personalizzato.messaggio"> </div>


      <div class="spacer"></div>
      <app-bottone-lemme *ngIf="messaggio_personalizzato.notizia_id"  [titolo]="'generico.condividi_base'|translate"
                         icona="share" (click)="apriNews()"></app-bottone-lemme>

      <div class="spacer"></div>
      <p class="disclaimer">{{'components.messaggiopersonalizzatobox.clicca_sotto'|translate}}</p>




    </div>


  </mat-card-content>
</mat-card>
