import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UniversitaService} from "../../services/universita/universita.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CommonService} from "../../services/common/common.service";
import {LanguageService} from "../../services/language/language.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-dialog-risposta-universita',
  templateUrl: './dialog-risposta-universita.component.html',
  styleUrls: ['./dialog-risposta-universita.component.scss']
})
export class DialogRispostaUniversitaComponent implements OnInit {
  form!: UntypedFormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  messaggio: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      tipo: string,
      id_lezione: string,
      id_capitolo: string,

    },
    private universitaService: UniversitaService,
    private formBuilder: UntypedFormBuilder,
    private commonService : CommonService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<DialogRispostaUniversitaComponent >
  ) {

    this.setFormData();
  }

  ngOnInit(): void {
  }

  get f() {
    return this.form.controls;
  }

  sendRisposta() {
    alert('sendirsposta');

  }

  async onSubmit() {

    this.submitted = true;

    console.log('form', this.form);


    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    //this make call

    let dataToSend = {
      'tipo': this.data.tipo,
      'testo': this.form.value.testo,
    }

    await this.universitaService.post_risposta(this.data.id_lezione, this.data.id_lezione, dataToSend).then(risp => {

     console.log('rispostaposta-uni', risp);

      if (risp) {
        //risp è un ogggetto, contiene la richiesta ssistenza
        //mostro modal successo


        this.commonService.alertSuccess(this.translateService.instant('generico.dati_salvati_successo'));
        this.svuotaForm();
        this.dialogRef.close();
      }

    }, err => {

      alert('ERRORE ');
     // this.dialogRef.close();

    });
    this.loading = false;

  }

  setFormData() {
    this.form = this.formBuilder.group({
      testo: ['', Validators.required],
    });
  }
  svuotaForm() {
    //console.log('svuotaTesto');
    //this.form.value.oggetto.setValue('') ;

    this.setFormData();
  }
}
