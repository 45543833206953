import {Component, OnInit} from '@angular/core';
  import {User} from "../../models/user";
import {UserService} from "../../services/user/user.service";
import {CommonService} from "../../services/common/common.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user !: User ;
  profilo_online !: string;
  constructor(
    private userService: UserService,
    private commonService : CommonService,
    private title: Title,
  ) {


    this.commonService.setPageTitle('profilo')

  }

  ngOnInit(): void {


 this.loadUtente()


  }
  async loadUtente() {
    await this.userService.local_user().then(user => {
      this.user = user;
      this.profilo_online = user.autologins.profilo;


    });
  }
  goToProfilo() {
    this.commonService.openURL(this.profilo_online);
  }
}
