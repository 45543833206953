<div class="titolo-content d-flex justify-content-between align-items-center">
  <mat-icon *ngIf="icon">{{icon}}</mat-icon>
  <h2   class="titolo flex-grow-1"  >{{data.title}}</h2>
</div>


<mat-dialog-content class="mat-typography" [innerHTML]="data.message">
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close> {{data.button}}</button>

</mat-dialog-actions>



