
<app-blocco-testo *ngIf="mese"
  [titolo]="mese"
></app-blocco-testo>

<mat-card class="mese-history-detail">
  <mat-card-content class="mese-history-list" *ngIf="meseHistory.length">


    <ngb-accordion [closeOthers]="true">
      <ngb-panel *ngFor="let giorno of meseHistory">
        <ng-template ngbPanelTitle>
          <span>{{giorno.formatted}}</span>
        </ng-template>
        <ng-template ngbPanelContent>

          <div class="history-day-detail">

            <div class="singolo-valore" *ngFor="let key of keysToShow">


              <div *ngIf="giorno[key]" class="inn-{{key}} blocco">

                <div class="valore-numerico" *ngIf="!is_boolean_key(key)">
                  <strong>{{'generico.misure.'+key+'.nome'|translate}}</strong>
                  :
                  <span class="valore">
                      {{giorno[key]}}
                </span>
                  <span class="um">
                      {{'generico.misure.'+key+'.um'|translate}}
                </span>
                </div>


                <div class="valore-bool" *ngIf="is_boolean_key(key)">
                  <strong>{{'inserisci_dati.spunte.'+key+''|translate}}</strong>
                  :
                  <span class="valore" [innerHTML]="giorno[key] ? ('generico.si'|translate) : ('generico.no'|translate)" ></span>


                </div>


              </div>



            </div>





          </div>

        </ng-template>
      </ngb-panel>

    </ngb-accordion>


  </mat-card-content>
</mat-card>

