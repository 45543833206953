import { Injectable} from '@angular/core';


import {StorageService} from "../storage/storage.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogBasicComponent} from "../../components/dialog-basic/dialog-basic.component";
import {LoaderPaginaComponent} from "../../components/loader-pagina/loader-pagina.component";
import {BroadcasterService} from "../broadcaster/broadcaster.service";
import {TranslateService} from "@ngx-translate/core";
import {Settings} from "../../models/settings";
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public AppSettings: Settings = new Settings();



  constructor(

  private storageService: StorageService,
    private dialog: MatDialog,
    private broadcaster: BroadcasterService,
    private translateService: TranslateService,
    private title: Title,
  ) {

  }

  /* */
  showLoader() {
    //this.loaderComponent.showLoader();
    this.broadcaster.is_loader_shown(true)
  }

  hideLoader() {
    // this.loaderComponent.hideLoader();
    this.broadcaster.is_loader_shown(false)
  }


  openURL(url: string) {
    window.open(url, '_blank');
  }


  openTelURL(tel: string) {
    window.open('tel:' + tel, '_blank');
  }


  alertSuccess(messaggio: string | string[]) {

    if (this.dialog.openDialogs.length != 0) {
      //con altri dialog aperti non ne apro uno nuovo
      console.log('ci sono dialog aperti, non ne apro di nuovi');
      return;
    }


    if (Array.isArray(messaggio)) {
      messaggio = messaggio.join('<br>')
    }

    this.dialog.open(DialogBasicComponent, {
      data: {
        tipo: 'success',
        title: this.translateService.instant('generico.successo'),
        message: messaggio,
        button: this.translateService.instant('generico.chiudi'),
      },
      panelClass: 'success-dialog',
    });

  }

  alertError(messaggio: string | string[]) {
    if (this.dialog.openDialogs.length != 0) {
      //con altri dialog aperti non ne apro uno nuovo
      console.log('ci sono dialog aperti, non ne apro di nuovi');
      return;
    }

    if (Array.isArray(messaggio)) {
      messaggio = messaggio.join('<br>')
    }


    this.dialog.open(DialogBasicComponent, {

      data: {
        tipo: 'error',
        title: this.translateService.instant('generico.errore'),
        message: messaggio,
        button: this.translateService.instant('generico.chiudi'),
      },
      panelClass: 'error-dialog',
    });


  }


  alertMsg(titolo: string, messaggio: string | string[]) {
    if (this.dialog.openDialogs.length != 0) {
      //con altri dialog aperti non ne apro uno nuovo
      console.log('ci sono dialog aperti, non ne apro di nuovi');
      return;
    }

    if (Array.isArray(messaggio)) {
      messaggio = messaggio.join('<br>')
    }


    this.dialog.open(DialogBasicComponent, {
      data: {

        title: titolo,
        message: messaggio,
        button: this.translateService.instant('generico.ok'),
      },
      panelClass: 'standard-dialog',
    });


  }


 async setPageTitle(titolo: string) {
   //prendo la traduzione dal file di lingua
   //chiamata finta per essere sicuri che le traduzioni siano caricate
   await this.translateService.get('dummyTranslation').toPromise().then();


   let titolotrad = this.translateService.instant('titoli_menu.' + titolo);
   if (!titolo) {
     console.log('---TITOLO MANCANTE---' + titolo)
   }

   this.title.setTitle(titolotrad);

 }


  reloadDOM() {
   console.log('##reloadDOM');
    // this.cdr.detectChanges();
  }
}
