import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Assistenza} from "../../models/assistenza";
import {SupportService} from "../../services/support/support.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-support-detail',
  templateUrl: './support-detail.component.html',
  styleUrls: ['./support-detail.component.scss']
})
export class SupportDetailComponent implements OnInit {
  loading: boolean = false;
  submitted: boolean = false;
  support_id: string | null = null;
  support!: Assistenza;

  form!: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private supportService: SupportService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
  ) {
    this.setFormData();
  }


  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.support_id = params.get('id');
      console.log('params', params);
      this.loadCurrentTicket();


    })
  }

  async loadCurrentTicket() {
    if (this.support_id) {
      this.supportService.get_support(this.support_id).then(risp => {

        this.support = risp;

      }, errore => {
        console.log(errore);
      });
    } else {
      //non ho il parametro id, torno indietro a news list
      this.router.navigate(['/support']);
    }
  }

  get f() {
    return this.form.controls;
  }


  async onSubmit() {

    this.submitted = true;

    console.log('form', this.form);


    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    //this make call

    await this.supportService.post_risposta_support(this.support_id as string, this.form.value).then(risp => {

      // console.log('rispostapostassistneza', risp);

      if (risp) {
        //risp è un ogggetto, contiene la richiesta ssistenza
        //mostro modal successo
        this.commonService.alertSuccess('Risposta assistenza inviata con successo.');
        this.svuotaForm();
        this.loadCurrentTicket();

      }

    }, err => {

      console.log('err', err);
      this.commonService.alertError(err.messages);

    });
    this.loading = false;

  }
  setFormData(){
    this.form = this.formBuilder.group({
      testo: ['', Validators.required],
    });
  }
  svuotaForm() {
    console.log('svuotaTesto');
    //this.form.value.oggetto.setValue('') ;

    this.setFormData();
  }


}
