import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {noop} from "rxjs";

@Component({
  selector: 'app-dynamic-inputs',
  templateUrl: './dynamic-inputs.component.html',
  styleUrls: ['./dynamic-inputs.component.scss'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicInputsComponent),
      multi: true
    }
  ]

})
export class DynamicInputsComponent implements ControlValueAccessor {

  @Input() titolo!: string


  @Input() innerValue: string[] = [];
  @Output('innerValueChange') emitter: EventEmitter<string[]> = new EventEmitter<string[]>();


  addValue: string = '';


  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): string[] {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }


  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }


  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }


  addSingleVal() {
    if (!this.addValue) {
      return;
    }
    this.value.push(this.addValue);
    this.addValue = '';
  }

  removeSingleVal(index: number) {
    this.value.splice(index, 1);
    console.log('removeSingleVal');
  }
}
