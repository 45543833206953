import { Component, OnInit } from '@angular/core';
import {ChatAiService} from "../../services/chat-ai/chat-ai.service";
import {ChatAiMessaggio} from "../../models/chat-ai";

@Component({
  selector: 'app-chat-ai',
  templateUrl: './chat-ai.component.html',
  styleUrls: ['./chat-ai.component.scss']
})
export class ChatAiComponent implements OnInit {

  is_loading: boolean = false;
  messaggio_utente: string = '';

  messaggi: ChatAiMessaggio[] = [];

  constructor(
    private chatAi:ChatAiService,
  ) { }

  ngOnInit(): void {
    this.loadMessaggi();
  }

  sendMessage() {

    if(!this.messaggio_utente){
      return;
    }

    this.is_loading = true;
    let newMessaggio: ChatAiMessaggio  = {
      id: 0,
      testo: this.messaggio_utente,
      ruolo: 'user',
      versione: 'local',
      data: new Date(),
    };



    this.messaggi.push(newMessaggio);
    this.scrollDownChatBox();


    this.chatAi.post_query_chat(this.messaggio_utente).then(risp=>{

      console.log('risp', risp);


    }).finally(()=>{
      this.is_loading = false;
      this.messaggio_utente = '';
      this.loadMessaggi();
    });

  }

  loadMessaggi() {
    this.is_loading = true;
    this.chatAi.get_storico().then(risp=>{
      console.log('risp', risp);
      this.messaggi = risp;

      //reverso i risultati, li voglio dal basso all'alto
      this.messaggi.reverse();


    }).finally(()=>{
      this.is_loading = false;
      this.scrollDownChatBox();
    });
  }

  scrollDownChatBox() {
    setTimeout(()=>{
      let chatBox = document.getElementById('chat-box');
      if(chatBox) {
        chatBox.scrollTop = chatBox.scrollHeight;
      }
    }, 100);
  }
}
