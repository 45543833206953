import {Inject, LOCALE_ID, NgModule, PLATFORM_ID} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from './pages/login/login.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MAT_ICON_LOCATION, MatIconModule, MatIconRegistry} from "@angular/material/icon";
import {MatListModule} from '@angular/material/list';
import {NewsComponent} from './pages/news/news.component';
import {AuthInterceptor} from "./interceptors/httpinterceptor";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {LocalStorageService, NgxLocalStorageModule} from "ngx-localstorage";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ProfileComponent} from './pages/profile/profile.component';
import {DialogBasicComponent} from './components/dialog-basic/dialog-basic.component';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {NewsLoopComponent} from './components/news-loop/news-loop.component';
import {MatCardModule} from "@angular/material/card";
import {NewsDetailComponent} from './pages/news-detail/news-detail.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {ShareModule} from "ngx-sharebuttons";
import {ShareIconsModule} from "ngx-sharebuttons/icons";
import {ShareButtonsModule} from "ngx-sharebuttons/buttons";
import {SettingsComponent} from './pages/settings/settings.component';
import {MatSelectModule} from "@angular/material/select";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {createTranslateLoader, LanguageService} from "./services/language/language.service";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {environment} from "../environments/environment";
import {FaqComponent} from './pages/faq/faq.component';
import {RegoleComponent} from './pages/regole/regole.component';
import {InformazioniComponent} from "./pages/informazioni/informazioni.component";
import {TestComponent} from './pages/test/test.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {BannerA2hsComponent} from './components/banner-a2hs/banner-a2hs.component';
import {
  MessaggioPersonalizzatoComponent
} from './components/messaggio-personalizzato/messaggio-personalizzato.component';
import {VideoPlayerComponent} from './components/video-player/video-player.component';
import {AudioPlayerComponent} from './components/audio-player/audio-player.component';
import {SupportComponent} from './pages/support/support.component';
import {SupportDetailComponent} from './pages/support-detail/support-detail.component';
import {MessaggioTicketComponent} from './components/messaggio-ticket/messaggio-ticket.component';
import {HistoryComponent} from './pages/history/history.component';
import {HistoryDetailComponent} from './pages/history-detail/history-detail.component';
import {GraphsComponent} from './pages/graphs/graphs.component';
import {GraphDetailComponent} from './pages/graph-detail/graph-detail.component';
import {NgChartsModule} from "ng2-charts";
import {RinnovaAppComponent} from './components/rinnova-app/rinnova-app.component';
import {RecapUltimiDatiComponent} from './components/recap-ultimi-dati/recap-ultimi-dati.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MessaggioGiornoComponent} from './components/messaggio-giorno/messaggio-giorno.component';
import {AcquistaLabFamigliaComponent} from './components/acquista-lab-famiglia/acquista-lab-famiglia.component';
import {AcquistaDimagrireComponent} from './components/acquista-dimagrire/acquista-dimagrire.component';
import {UniversitaBoxComponent} from './components/universita-box/universita-box.component';
import {NewsCategorieComponent} from './components/news-categorie/news-categorie.component';
import {
  MessaggioPersonalizzatoBoxComponent
} from './components/messaggio-personalizzato-box/messaggio-personalizzato-box.component';
import {ContattiHomeComponent} from './components/contatti-home/contatti-home.component';
import {InserisciDatiComponent} from './pages/inserisci-dati/inserisci-dati.component';
import {PlyrModule} from "ngx-plyr";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ProssimiMenuComponent} from './pages/prossimi-menu/prossimi-menu.component';
import {UniversitaComponent} from './pages/universita/universita.component';
import {UniversitaLezioneComponent} from './pages/universita-lezione/universita-lezione.component';
import {UniversitaCapitoloComponent} from './pages/universita-capitolo/universita-capitolo.component';
import {
  DialogRispostaUniversitaComponent
} from './components/dialog-risposta-universita/dialog-risposta-universita.component';
import {LoaderPaginaComponent} from './components/loader-pagina/loader-pagina.component';
import {MenuBoxComponent} from './components/menu-box/menu-box.component';
import {PiattoBoxComponent} from './components/piatto-box/piatto-box.component';
import {CambioPiattoComponent} from './pages/cambio-piatto/cambio-piatto.component';
import {MatRadioModule} from "@angular/material/radio";
import {PiattiAcquistatiComponent} from './pages/piatti-acquistati/piatti-acquistati.component';
import {DialogEliminaPiattoComponent} from './components/dialog-elimina-piatto/dialog-elimina-piatto.component';
import {ListaSpesaComponent} from './pages/lista-spesa/lista-spesa.component';
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule} from "@angular/material/checkbox";
import {ChatConLemmeComponent} from './pages/chat-con-lemme/chat-con-lemme.component';
import {NgxFlagsModule} from 'ngx-flags';

import {DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';


//registro i locale
import localeIT from '@angular/common/locales/it';

registerLocaleData(localeIT);
import localeEN from '@angular/common/locales/en';

registerLocaleData(localeEN);


import {BottoneLemmeComponent} from './components/bottone-lemme/bottone-lemme.component';
import {NewsCategoriesComponent} from './pages/news-categories/news-categories.component';
import {NewsLoopListComponent} from './components/news-loop-list/news-loop-list.component';
import {PiattiProntiComponent} from './pages/piatti-pronti/piatti-pronti.component';
import {BloccoTestoComponent} from './components/blocco-testo/blocco-testo.component';
import {ShareLemmeComponent} from './components/share-lemme/share-lemme.component';
import {DialogShareButtonsComponent} from './components/dialog-share-buttons/dialog-share-buttons.component';
import {RouterModule} from "@angular/router";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {NewsLatestComponent} from './components/news-latest/news-latest.component';
import {AudioRequiredPlayerComponent} from './components/audio-required-player/audio-required-player.component';
// Import Bugsnag and the Angular integration
import Bugsnag from '@bugsnag/js'
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular'
import {ErrorHandler} from '@angular/core'
import {BUILD_INFO} from "../environments/build-info";
import {UserService} from "./services/user/user.service";
import {User} from "./models/user";
import {ChiamaLemmeComponent} from './pages/chiama-lemme/chiama-lemme.component';
import {AudioRicetteComponent} from './pages/audio-ricette/audio-ricette.component';
import {AudioRicettaDetailComponent} from './pages/audio-ricetta-detail/audio-ricetta-detail.component';
import {AudioricettaLoopComponent} from './components/audioricetta-loop/audioricetta-loop.component';
import {AudioricetteBoxComponent} from "./components/audioricette-box/audioricette-box.component";
import {ListaRicetteComponent} from './pages/lista-ricette/lista-ricette.component';
import {InserisciRicettaComponent} from './pages/inserisci-ricetta/inserisci-ricetta.component';
import {DynamicInputsComponent} from './components/dynamic-inputs/dynamic-inputs.component';
import { RicettaDetailComponent } from './pages/ricetta-detail/ricetta-detail.component';
import {DialogBasicConfirmComponent} from "./components/dialog-basic-confirm/dialog-basic-confirm.component";
import { ChatAiComponent } from './pages/chat-ai/chat-ai.component';
import { GenioAiComponent } from './pages/genio-ai/genio-ai.component';

//Configuro BUGSNAG per salvare lo storico degli errori
Bugsnag.start({
  apiKey: environment.bugsnag_api,
  appVersion: BUILD_INFO.version,
  releaseStage: environment.bugsnag_stage,
  //enabledReleaseStages: ['production', 'staging']
})

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory(userService: UserService) {
  //console.log('handleErrore1');
  try {
    // console.log('handleErrore2');
    userService.local_user().then(risp => {
      let current_user: User = risp;
      if (current_user) {
        //ad ogni chiamata /user al sito setto l'utente su bugnsag
        //sono sicuro di farne almeno una per page load
        console.log('imposto utenente su bugsnag ', current_user);
        Bugsnag.setUser(current_user.id, current_user.email, current_user.nome + ' ' + current_user.cognome);
      }

    });
  } catch (e) {
    return new BugsnagErrorHandler();
  }
  return new BugsnagErrorHandler();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NewsComponent,
    ProfileComponent,
    DialogBasicComponent,
      DialogBasicConfirmComponent,
    NewsLoopComponent,
    NewsDetailComponent,
    SettingsComponent,
    FaqComponent,
    RegoleComponent,
    InformazioniComponent,
    TestComponent,
    BannerA2hsComponent,
    MessaggioPersonalizzatoComponent,
    VideoPlayerComponent,
    AudioPlayerComponent,
    SupportComponent,
    SupportDetailComponent,
    MessaggioTicketComponent,
    HistoryComponent,
    HistoryDetailComponent,
    GraphsComponent,
    GraphDetailComponent,
    RinnovaAppComponent,
    RecapUltimiDatiComponent,

    MessaggioGiornoComponent,
    AcquistaLabFamigliaComponent,
    AcquistaDimagrireComponent,
    UniversitaBoxComponent,
    NewsCategorieComponent,
    MessaggioPersonalizzatoBoxComponent,
    ContattiHomeComponent,
    InserisciDatiComponent,
    ProssimiMenuComponent,
    UniversitaComponent,
    UniversitaLezioneComponent,
    UniversitaCapitoloComponent,
    DialogRispostaUniversitaComponent,
    LoaderPaginaComponent,
    MenuBoxComponent,
    PiattoBoxComponent,
    CambioPiattoComponent,
    PiattiAcquistatiComponent,
    DialogEliminaPiattoComponent,
    ListaSpesaComponent,
    ChatConLemmeComponent,
    BottoneLemmeComponent,
    NewsCategoriesComponent,
    NewsLoopListComponent,
    PiattiProntiComponent,
    BloccoTestoComponent,
    ShareLemmeComponent,
    DialogShareButtonsComponent,
    NewsLatestComponent,
    AudioRequiredPlayerComponent,
    ChiamaLemmeComponent,
    AudioricetteBoxComponent,
    AudioRicetteComponent,
    AudioRicettaDetailComponent,
    AudioricettaLoopComponent,
    ListaRicetteComponent,
    InserisciRicettaComponent,
    DynamicInputsComponent,
    RicettaDetailComponent,
    ChatAiComponent,
    GenioAiComponent,
  ],
  imports: [
    BrowserModule,

    TranslateModule.forRoot({
      defaultLanguage: environment.default_language,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),


    BrowserAnimationsModule,

    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,


    ReactiveFormsModule,
    FormsModule,

    ShareModule,
    ShareIconsModule,

    PlyrModule,
    NgxFlagsModule,

    NgChartsModule,


    HttpClientModule,


    NgxLocalStorageModule.forRoot(),
    MatPaginatorModule,
    ShareButtonsModule,

    NgbModule,
    MatProgressSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MatButtonToggleModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatCheckboxModule,

    NgxGoogleAnalyticsModule.forRoot(environment.google_analytics_track_id),
    NgxGoogleAnalyticsRouterModule,

    RouterModule.forRoot([
      {
        path: '',
        component: AppComponent,

      }
    ]),

    AppRoutingModule,

  ],
  providers: [
    Title,
    DatePipe,
    //uso l'errorHandler di bugsnag
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
      deps: [UserService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {
        appearance: 'standard', //'legacy' | 'standard' | 'fill' | 'outline';
        floatLabel: 'always', //'auto' | 'always' | 'never';
      }
    },
    {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'check' } as MatCheckboxDefaultOptions },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},

    //date europee su datepicker
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},


    {
      provide: LOCALE_ID,
      deps: [LanguageService],      //some service handling global settings
      useFactory: (languageService: any) => languageService.getLanguageForLocale()  //returns locale string
    },
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: ['YYYY-MM-DD']
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },


    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-icons-outlined');

  }

}
