import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class RicetteService {


  constructor(
    private api: ApiService,
  ) {
  }
  async init(): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('ricette_init')
        .subscribe((risposta: any) => {


          console.log('OK Ricette INIT');
          console.log('risposta', risposta);

          if (risposta) {
            //risolvo tutto, al componente serve sia data (notizie) che meta/links (paginazione)
            resolve(risposta.data);
          }


        }, (err: any) => {


          console.log('ERRORE news_all()');
          console.log(err);

          resolve(false);

        });
    });
  }

  async ricette_all(): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('ricette'   )
        .subscribe((risposta: any) => {


          console.log('OK ricette all');
          console.log('risposta', risposta);

          if (risposta) {
            //risolvo tutto, al componente serve sia data (notizie) che meta/links (paginazione)
            resolve(risposta.data);
          }


        }, (err: any) => {


          console.log('ERRORE news_all()');
          console.log(err);

          resolve(false);

        });
    });
  }

  async ricette_single(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.get('ricette/' + id)
        .subscribe((risposta: any) => {


          console.log('OK News1');
          console.log('risposta', risposta);

          if (risposta) {
            resolve(risposta['data']);
          }


        }, (err: any) => {


          console.log('ERRORE news_single()');
          console.log(err);

          resolve(false);

        });
    });
  }

  async ricetta_delete(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.delete('ricette/' + id)
                .subscribe((risposta: any) => {


                    console.log('OK Delete');
                    console.log('risposta', risposta);

                    resolve(risposta.data);



                }, (err: any) => {


                    console.log('ERRORE news_single()');
                    console.log(err);

                    resolve(false);

                });
        });
    }

  async send_ricetta(formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.post('ricette',formData)
        .subscribe((risposta: any) => {


          console.log('OK sendRicetta');
          console.log('risposta', risposta);

          if (risposta) {
            resolve(risposta['data']);
          }


        }, (err: any) => {


          console.log('ERRORE news_single()');
          console.log(err);

          resolve(false);

        });
    });
  }

  async send_ricetta_edit(id:number,formData: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.patch('ricette/'+id,formData)
                .subscribe((risposta: any) => {


                    console.log('OK sendRicetta');
                    console.log('risposta', risposta);

                    if (risposta) {
                        resolve(risposta['data']);
                    }


                }, (err: any) => {


                    console.log('ERRORE news_single()');
                    console.log(err);

                    resolve(false);

                });
        });
    }



}
