<app-blocco-testo
  [titolo]="'inserisci_ricetta.titolo_'+mode | translate"
  [testo]="'inserisci_ricetta.descrizione' | translate"

  xmlns="http://www.w3.org/1999/html"></app-blocco-testo>



<mat-card class="carica-foto-ext mb-3 ">

  <mat-card-content>
    <div class="form-group d-flex">
      <mat-label class="flex-grow-1  ">
        {{'inserisci_ricetta.form.vuoi_inserire_foto'|translate}}
      </mat-label>
      <mat-slide-toggle color="primary" [(ngModel)]="formData.carica_foto"></mat-slide-toggle>
    </div>

    <div class="carica-foto  " *ngIf="formData.carica_foto">


      <div class="upload-ext">

        <div class="remove-images" *ngIf="cardImageBase64" (click)="removeImage()">
          <mat-icon class="icona">close</mat-icon>
        </div>


        <div class="preview-image" *ngIf="getImageBase64()">
          <img class="img-fluid" [src]="getImageBase64()">
        </div>


        <div class="file-input-buttons d-flex align-items-center justify-content-center">
          <div class="file-input-container">
            <div (click)="fileupld.click()" for="file-upload d-flex" class="custom-file-upload">
              <mat-icon class="icona">upload</mat-icon>
              <label class="testo">{{'inserisci_dati.carica_foto_label'|translate}}</label>
            </div>
            <input id="file-upload" accept="image/*" capture="camera" #fileupld type="file"
                   (change)="fileChange($event)" placeholder="{{'inserisci_dati.carica_foto_label'|translate}}">
          </div>

        </div>


      </div>


    </div>
  </mat-card-content>

</mat-card>


<mat-card class="abilitato mb-3 ">

  <mat-card-content>
    <div class="form-group d-flex">
      <mat-label class="flex-grow-1  ">
        {{ 'inserisci_ricetta.form.abilitata' | translate }}
      </mat-label>
      <mat-slide-toggle color="primary" [(ngModel)]="formData.abilitata"></mat-slide-toggle>
    </div>


  </mat-card-content>

</mat-card>




<form class="form-ins-dati">
  <mat-card class="inserisci-dati-card mb-3">
    <mat-card-content>

      <div class="form-group">

        <mat-form-field>
          <mat-label>{{ 'inserisci_ricetta.form.titolo_ricetta' | translate }}</mat-label>

          <input name="titolo" type="text" matInput
                 [(ngModel)]="formData.titolo"/>

        </mat-form-field>







        <mat-form-field>
          <mat-label>{{ 'inserisci_ricetta.form.descrizione' | translate }}</mat-label>

          <textarea name="descrizione" matInput
                    [(ngModel)]="formData.descrizione"></textarea>

        </mat-form-field>


        <app-dynamic-inputs [(innerValue)]="formData.ingredienti"
                            [titolo]="'inserisci_ricetta.form.ingredienti' | translate"></app-dynamic-inputs>
        <app-dynamic-inputs [(innerValue)]="formData.passaggi"
                            [titolo]="'inserisci_ricetta.form.passaggi' | translate"></app-dynamic-inputs>



      </div>


    </mat-card-content>
  </mat-card>

  <mat-card class="check card tags-card mb-3">
    <mat-card-content>
      <div class="checkbox-cont" *ngIf="tags">
        <mat-label>{{ 'inserisci_ricetta.form.tags' | translate }}</mat-label>
        <div class="checkbox-list">
          <mat-checkbox (change)="changeCheckObj($event, tag, formData.tags)" *ngFor="let tag of tags; index as i;"
                        [value]="tag.id" [checked]="formData.tags.includes(tag.id)"  class="example-margin">{{tag.nome}}</mat-checkbox>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="check card pasti-card mb-3">
    <mat-card-content>
      <div class="checkbox-cont" *ngIf="pasti">
        <mat-label>{{ 'inserisci_ricetta.form.pasti' | translate }}</mat-label>
        <div class="checkbox-list">
          <mat-checkbox (change)="changeCheckStr($event, pasto, formData.pasti)"
                        *ngFor="let pasto of pasti; index as i;" [checked]="formData.pasti.includes(pasto)" [value]="pasto" class="example-margin">
            {{'generico.portate.' + pasto | translate}}
          </mat-checkbox>
        </div>
      </div>
    </mat-card-content>
  </mat-card>




</form>



<div class="invia-dati-cont container">


  <button class="btn btn-lemme-full" *ngIf="mode=='new'"
          (click)="sendData()">{{'inserisci_ricetta.form.invia'|translate}}</button>


  <button class="btn btn-lemme-full" *ngIf="mode=='edit'"
          (click)="sendDataEdit()">{{'inserisci_ricetta.form.modifica'|translate}}</button>



</div>


