import {Injectable} from '@angular/core';
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private api: ApiService,
  ) {

  }


  async post_support(richiestaAssistenza: any): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.post('assistenza', richiestaAssistenza)
        .subscribe((risposta: any) => {


         // console.log('risposta123',risposta)
          if (risposta ) {
            resolve(risposta );
          }


        }, (err: any) => {


          console.log('ERRORE post_support()');
          console.log(err);

          reject(err.error);

        });
    });
  }
  async post_risposta_support(id:string, richiestaAssistenza: any): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.post('assistenza/'+id, richiestaAssistenza)
        .subscribe((risposta: any) => {


          console.log('risposta123',risposta)
          if (risposta ) {
            resolve(risposta );
          }


        }, (err: any) => {


          console.log('ERRORE post_support()');
          console.log(err);

          reject(err.error);

        });
    });
  }


  async get_supports( ): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('assistenza' )
        .subscribe((risposta: any) => {



          if (risposta ) {
            resolve(risposta.data );
          }


        }, (err: any) => {


          console.log('ERRORE post_support()');
          console.log(err);

          reject(err.error);

        });
    });
  }

  async get_support( id:string): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('assistenza/'+id )
        .subscribe((risposta: any) => {



          if (risposta ) {
            resolve(risposta.data );
          }


        }, (err: any) => {


          console.log('ERRORE get_support()');
          console.log(err);

          reject(err.error);

        });
    });
  }





}
