<div class="video-player-outer">
  <div plyr plyrType="video" class="video-player-content"
       [plyrOptions]="videoOptions"
       [plyrPlaysInline]="true"
       [plyrSources]="videoSources"
       [plyrPoster]="poster"
       (plyrInit)="assignPlayer($event)" (plyrPlay)="played($event)"></div>
</div>
<!--
<button (click)="play()">Play</button>
-->
