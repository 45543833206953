import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterState} from "@angular/router";
import {map} from "rxjs";
import {InsdatiService} from "../../services/insdati/insdati.service";
import {User} from "../../models/user";
import {UserService} from "../../services/user/user.service";
import {ListaPiattiPronti, PiattoPronto} from "../../models/piattopronto";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-cambio-piatto',
  templateUrl: './cambio-piatto.component.html',
  styleUrls: ['./cambio-piatto.component.scss']
})
export class CambioPiattoComponent implements OnInit {

  idmenu !: number;
  portata !: string;
  sceglituAttivi   !: any;
  scegliTuUtilizzabili  : PiattoPronto[] = [];
  quantitaNecessaria: number = 1;
  scegli_tu_scelto: number = 0;
  user!: User;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private insDatiService: InsdatiService,
    private userService: UserService,
    private commonService: CommonService,
  ) {


    this.route.paramMap
      .pipe(map(() => window.history.state))
      .subscribe(state => {
        console.log('state', state);
        //this.highlight = state && state.highlight;
        this.idmenu = state.idmenu;
        this.portata = state.portata;

        //guardia che torna indietro a cambio piatti se vuoto
        if (!this.idmenu || !this.portata) {
          this.router.navigate(['/prossimi-menu']);
        }


      });


  }

  ngOnInit(): void {
    this.initCambioPiatto();
  }

  async initCambioPiatto() {
    console.log('initCambioPiatto1');
    await this.loadSceglituAttivi();
    await this.loadUser();
    await this.calcolaQuantitaNecessaria();
    await this.pulisciPiattiScegliTu();
  }

  async calcolaQuantitaNecessaria() {
    console.log('calcolaQuantitaNecessaria4');
    let faseUtente = this.user.fase.numero;
    //alert(faseUtente);
    switch (faseUtente) {
      case 1:
        this.quantitaNecessaria = 2;
        break;
      case 2:
        this.quantitaNecessaria = 1;
        break;
    }
  }

  async loadUser() {
    console.log('loadUser3');
    await this.userService.local_user().then(risp => {
      this.user = risp;
      // console.log('risp', risp);


    });
  }

  async loadSceglituAttivi() {
    console.log('loadSceglituAttivi2');
    await this.insDatiService.piatti_st_disponibili().then(risp => {
      this.sceglituAttivi = risp;
    }, err => {
      console.log('ERRORE loadSceglituAttivi', err)
    });
  }

  async pulisciPiattiScegliTu() {
    console.log('pulisciPiattiScegliTu5');
    console.log('this.sceglituAttivi', this.sceglituAttivi);


    let key = this.portata;
    if (this.sceglituAttivi.hasOwnProperty(key)) {


      //filtro solo con le quantità possibili
      this.scegliTuUtilizzabili = this.sceglituAttivi[key].filter((item: any) => {
        console.log('item.q', item.q);
        console.log('this.quantitaNecessaria', this.quantitaNecessaria);
        return (item.q >= this.quantitaNecessaria);
      });



    }


  }

  cambiaPiattoSTD() {


    this.commonService.showLoader();


    this.insDatiService.post_cambiopiatto('piatto', {
      menu: this.idmenu,
      portata: this.portata,
    }).then(risp => {
      // console.log('risp', risp);
      if (risp) {
        this.commonService.alertSuccess(risp.messages.join('<br>'));
      }
      this.commonService.hideLoader();
      this.router.navigateByUrl('/prossimi-menu');

    }, err => {
      this.commonService.hideLoader();
    });

  }

  cambiaPiattoScegliTu() {
    if (!this.scegli_tu_scelto) {
      alert('devi scegliere un piatto');
    }
    this.commonService.showLoader();
    this.insDatiService.post_cambiopiatto('piatto', {
      menu: this.idmenu,
      portata: this.portata,
      st: {
        [this.portata]: this.scegli_tu_scelto,
      }
    }).then(risp => {
      // console.log('risp', risp);
      if (risp) {
        this.commonService.alertSuccess(risp.messages.join('<br>'));
      }

      this.router.navigateByUrl('/prossimi-menu');
      this.commonService.hideLoader();
    }, err => {
      this.commonService.hideLoader();
    });

  }

  canShowScegliTuEcommerce() {
    return (this.user.ecommerce && this.user.autologins.scegli_tu)
  }

  acquistaScegliTu() {
    this.commonService.openURL(this.user.autologins.scegli_tu);
  }
}
