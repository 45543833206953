<app-blocco-testo
  [titolo]="'cambio_piatto.titolo'|translate"
  [testo]="'cambio_piatto.descrizione'|translate"
></app-blocco-testo>

<div class="spacer"></div>
<div class="portata-cont text-center">
  <h4>{{'generico.portata'|translate}} :  <strong>{{'generico.portate.'+portata | translate}}</strong></h4>
</div>
<div class="spacer"></div>


<mat-card>

  <mat-card-content>

    <div class="cambio-piatto-std">
      <div class="spiegazione">
        {{'cambio_piatto.cambio_piatto_cucino_io'|translate}}
      </div>
      <div class="spacer"></div>
      <button   class="btn-lemme-full" (click)="cambiaPiattoSTD()">
        {{'cambio_piatto.cambia_piatto'|translate}}
      </button>

    </div>



    <div class="cambio-piatto-scegli-tu" *ngIf="scegliTuUtilizzabili && scegliTuUtilizzabili.length">

      <div class="spacer"></div>
      <div class="oppure">
       <strong>{{'generico.oppure'|translate}}</strong>
      </div>


      <div class="spiegazione">
        {{'cambio_piatto.cambio_piatto_scegli_tu'|translate}}
      </div>
      <div class="spacer"></div>

      <div class="scegli-piatto-st">

        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          [(ngModel)]="scegli_tu_scelto">
          <mat-radio-button class="example-radio-button" *ngFor="let piatto of scegliTuUtilizzabili" [value]="piatto.id">

            <div class="singolo-piatto-st">
              <div class="row">
                <div class="col-3 col-sm-2">
                  <div class="img-cont" *ngIf="piatto.prodotto.imgFull">
                    <img [src]="piatto.prodotto.imgFull" class="img-fluid" alt="">
                  </div>
                </div>
                <div class="col-9 col-sm-10">
                  {{piatto.prodotto.nome}}
                </div>
              </div>
            </div>


          </mat-radio-button>
        </mat-radio-group>


      </div>

      <div class="spacer"></div>
      <button   class="btn-lemme-full" (click)="cambiaPiattoScegliTu()">
        {{'cambio_piatto.cambia_piatto'|translate}}
      </button>
    </div>


    <!--
    <pre>{{scegliTuUtilizzabili|json}}</pre>
    <div class="acquista-scegli-tu" *ngIf="scegliTuUtilizzabili && !scegliTuUtilizzabili.length  ">

      <div class="spiegazione">
        {{'cambio_piatto.acquista_scegli_tu'|translate}}
      </div>
      <button mat-button class="lemme" (click)="acquistaScegliTu()" *ngIf=" canShowScegliTuEcommerce()">
        {{'cambio_piatto.acquista_scegli_tu_bottone'|translate}}
      </button>


    </div>
    -->

  </mat-card-content>
</mat-card>
