<app-blocco-testo
[titolo]="'assistenza.titolo'|translate"
[testo]="'assistenza.descrizione'|translate"
></app-blocco-testo>
<div class="new-support-request mb-5">
  <mat-card class="support-card">
    <mat-card-title   >{{'assistenza.nuova_richiesta'|translate}}</mat-card-title>
    <mat-card-content  >
      <form class="assistenza-form "   [formGroup]="form" (ngSubmit)="onSubmit()">


        <div class="form-group  ">

          <mat-form-field class=" w-100">
            <mat-label for="username">{{'assistenza.campi.oggetto'|translate}}</mat-label>
            <input matInput type="text" formControlName="oggetto" class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && f.oggetto.errors }"
                   placeholder="{{'assistenza.campi.oggetto_placeholder'|translate}}"
            />
            <div *ngIf="submitted && f.oggetto.errors" class="invalid-feedback">
              <mat-error *ngIf="f.oggetto.errors.required">{{'assistenza.campi.oggetto_required'|translate}}</mat-error>
            </div>
          </mat-form-field>

        </div>


        <div class="form-group">


          <mat-form-field class=" w-100">
            <mat-label for="testo">{{'assistenza.campi.messaggio'|translate}}</mat-label>
            <textarea matInput formControlName="testo" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.testo.errors }"
                      placeholder="{{'assistenza.campi.messaggio_placeholder'|translate}}"
            ></textarea>
            <div *ngIf="submitted && f.testo.errors" class="invalid-feedback">
              <mat-error *ngIf="f.testo.errors.required">{{'assistenza.campi.messaggio_required'|translate}}</mat-error>
            </div>
          </mat-form-field>


        </div>
        <div class="form-group">
          <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{'generico.invio'|translate}}
          </button>
          <!--
          <a (click)="svuotaForm()">
            {{'generico.cancella'|translate}}
          </a>
          -->

        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<div class="list-support-request mb-5">

  <mat-card class="card-lista-richieste">
    <mat-card-title >{{'assistenza.lista_richieste'|translate}}</mat-card-title>
    <mat-card-content   >

      <div class="list" *ngIf="supportRequestList.length">

        <mat-nav-list>
          <mat-list-item   *ngFor="let assistenza of supportRequestList"
                           [routerLink]="['/support-detail', assistenza.id]"
          >
            <div matLine  >
              <span class="data">{{assistenza.giorno | date}}</span>
              |
              <span class="oggetto">{{assistenza.oggetto }}</span>
            </div>
            <button mat-icon-button>
              <mat-icon>chevron_right</mat-icon>
            </button>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-nav-list>

      </div>
      <div class="no-result-list" *ngIf="!supportRequestList.length">
        {{'generico.nessun_risultato'|translate}}
      </div>

    </mat-card-content>
  </mat-card>



</div>


