import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-contatti-home',
  templateUrl: './contatti-home.component.html',
  styleUrls: ['./contatti-home.component.scss']
})
export class ContattiHomeComponent implements OnInit {

  fissoLemme = '';
  waLemme = '';
  urlChat = '';
  urlFa: string = '';

  constructor(
    private commonService: CommonService,
  ) {
    this.fissoLemme = environment.lemme_whastapp_number;
    this.waLemme = environment.lemme_whatsapp_url_prefix+environment.lemme_whastapp_number;
    this.urlChat = environment.lemme_chat_link;
    this.urlFa = environment.sito_fa;
  }

  ngOnInit(): void {
  }
  openWebFA(){
    this.commonService.openURL(this.urlFa );
  }

  openWhatsapp() {
   this.commonService.openURL(this.waLemme );
  }

  openTel() {
    this.commonService.openURL('tel:'+this.fissoLemme );
  }

  openChat() {
    //alert(this.urlChat);
    this.commonService.openURL(this.urlChat );
  }
}
