

<div class="audio-player-content" plyr plyrType="audio"
     [plyrPlaysInline]="true"
     [plyrSources]="audioSources"
     [plyrOptions]="audioOptions"
     (plyrInit)="assignPlayer($event)" (plyrPlay)="played($event)"></div>

<!--
<button (click)="play()">Play</button>
-->
