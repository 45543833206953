<div class="news-container" *ngIf="news">

  <h2 *ngIf="news.titolo" class="titolo d-flex justify-content-center align-items-center">
    <mat-icon class="icona">{{listicon[news.tipo]}}</mat-icon>
    <div class="testo">{{news.titolo}}</div>
  </h2>

  <div class="image-content" *ngIf="news.img && !news.video">
    <img [src]="news.img" class="img-fluid">
  </div>

  <div class="video-content" *ngIf="news.video">
    <app-video-player [video]="news.video" [poster]="news.img"></app-video-player>
  </div>


  <div class="descrizione-contenitore">
    <div class="descrizione-inner" [innerHTML]="news.descrizione"></div>
  </div>

  <div class="audio-content" *ngIf="news.audio">
    <app-audio-player [audio]="news.audio"></app-audio-player>
  </div>




  <div class="spacer"></div>

  <app-share-lemme [url]="news.shareUrl"></app-share-lemme>


  <div class="contattami-cont">
    <h2 class="text-center">{{'news_detail.contattami_titolo'|translate}}</h2>
    <app-contatti-home></app-contatti-home>
  </div>

  <div class="spacer"></div>

  <div class="acquista-lab-news">
    <h2 class="titolo">{{'news_detail.titolo_lab_famiglia'|translate}}</h2>
    <app-acquista-lab-famiglia></app-acquista-lab-famiglia>
  </div>
</div>



