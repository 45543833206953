import {Component, OnInit} from '@angular/core';
import {AudioricetteService} from "../../services/audioricette/audioricette.service";

@Component({
  selector: 'app-audio-ricette',
  templateUrl: './audio-ricette.component.html',
  styleUrls: ['./audio-ricette.component.scss']
})
export class AudioRicetteComponent implements OnInit {

  audioricette = [];

  constructor(
    private audioRicetteService: AudioricetteService,
  ) {
  }

  ngOnInit(): void {
    this.loadAudioRicette();
  }

  private loadAudioRicette() {
    this.audioRicetteService.audioricette_all().then(risp => {
      this.audioricette = risp;
    }, err => {
      console.log('ERRORE GET all audioricette', err);
    });

  }
}
