import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {InsdatiService} from "../../services/insdati/insdati.service";
import {User} from "../../models/user";
import {UserService} from "../../services/user/user.service";
import * as moment from "moment";
import {CommonService} from "../../services/common/common.service";
import {Event, Router} from "@angular/router";
import {PiattoProntoProdotto} from "../../models/piattopronto";


@Component({
  selector: 'app-inserisci-dati',
  templateUrl: './inserisci-dati.component.html',
  styleUrls: ['./inserisci-dati.component.scss']
})
export class InserisciDatiComponent implements OnInit {
  user !: User;

  carica_foto: boolean = false;
  tipifoto = ['viso', 'intera'];

  file_audio_registrazione_peso: string | null = null;
  file_audio_registrazione_peso_ascoltato = false;

  insDatiInfo: any;
  dataNow = moment();
  reaction_possibili = [3, 2, 1];

  valoriST !: any[];
  valoriPS !: any[];

  file_audio_sgarrato: string | null = null;
  audio_sgarrato_obj: HTMLAudioElement | null = null;

  utilizzoPiatti: string = '';
  utilizzoPS: any = {};
  utilizzoST: any = {};
  /* */
  // dataNow :Date = new Date();
  formDataFile: FormData = new FormData();
  formData: any = {
    "data": '',
    "data_device": '',
    "valori": {},
    "felicita": '',
    "spunte": {
      "viaggio": false,
      "ciclo": false,
      "sgarrato": false,

    },
    "ps": null,
    "st": {},

    'tipologia_fotografia': false,
    'fotografia': '',


  }

  returnZero() {
    return 0
  };

  constructor(
    private insDatiService: InsdatiService,
    private userService: UserService,
    private commonService: CommonService,
    private route: Router,
  ) {

    this.commonService.setPageTitle('ins_dati');

    this.init();



  }

  init() {
    this.formData.data = this.formData.data_device = this.dataNow.format('YYYY-MM-DD');
    environment.misure_possibili.forEach(sing => {
      this.formData.valori[sing] = '';
    });
    this.getInsDatiInitInfo();
    this.userService.local_user().then(user => {
      this.user = user;
    })
  }

  ngOnInit(): void {
  }


  fileFinishedPlay(is_finished: boolean) {
    console.log('finito', is_finished);
    if (is_finished) {
      this.file_audio_registrazione_peso_ascoltato = true;
    }

  }

  changeNumberValue(key: any, value: any, keyarray: string) {
    console.log('changeNumberValue', value)
    this.formData[keyarray][key] = value;

    if (key == 'sgarrato' && value == 1) {
      //utente ha sgarrato, faccio partire audio
      this.playAudioSgarrato();
    }
  }

  playAudioSgarrato() {
    if (!this.file_audio_sgarrato) {
      return;
    }

    if (!this.audio_sgarrato_obj) {
      this.audio_sgarrato_obj = new Audio();
      this.audio_sgarrato_obj.src = this.file_audio_sgarrato;
      this.audio_sgarrato_obj.volume = 1;
      this.audio_sgarrato_obj.load();
      this.audio_sgarrato_obj.play();
    }

  }

  getfieldName(key: any) {
    return key as string;
  }

  getInsDatiInitInfo() {
    this.insDatiService.registrazioni_init().then(risp => {
      console.log('risp', risp);
      if (risp) {
        this.insDatiInfo = risp;
        this.formData.spunte.viaggio = risp.inviaggio ?? false;
        this.valoriST = risp.st;
        this.valoriPS = risp.ps;
        this.file_audio_sgarrato = risp.file_audio_sgarrato

        this.file_audio_registrazione_peso = risp.messaggio_registrazione_peso ?? null;

      }
    });
  }

  sendData() {
    console.log('sendData', this.formData);
    this.commonService.showLoader();

    this.insDatiService.post_dati(this.formData).then(risp => {


      this.commonService.hideLoader();

      console.log('risposta sendData', risp);
      //risposta 200, mostro messaggio positivo
      if (risp && risp.messages) {
        this.commonService.alertSuccess(risp.messages);
        this.route.navigateByUrl('/prossimi-menu');
      }


    }, err => {

      this.commonService.hideLoader();
      console.log('errore post_dati', err);

      console.log('errore sendData() - alert gestito da broadcaster su appcomponetn');

      if (err.status == 477) {
        this.route.navigateByUrl('/chiama-lemme');
      }

    });
  }


  setReaction(sing: any) {
    if (this.reaction_possibili.includes(parseInt(sing))) {
      this.formData.felicita = sing;
    }
  }

  dateModelChanged(date: any) {
    let theDate = moment(date).format('YYYY-MM-DD');
    this.formData.data = theDate;

  }

  canUsePiattiPronti() {
    return this.insDatiInfo && this.user && (!this.insDatiInfo.homenudomani) && (this.user.fase.numero == 1);
  }


  panelHasChanged($event: any) {

    //ad ogni change panel pulisco tutto cio che riguarda i formData dei piatti pronti

    this.formData.ps = null;
    this.formData.st = {};


    console.log('event', $event);
    let panelid = $event.panelId;
    let nextState = $event.nextState;
    if (nextState) {
      //un pannello è aperto
      this.utilizzoPiatti = panelid;
    } else {
      this.utilizzoPiatti = '';

    }


  }

  imageError!: string;
  isImageSaved!: boolean;
  cardImageBase64!: string | null;

  fileChange(fileInput: any): void {
    this.imageError = '';
    if (fileInput.target.files && fileInput.target.files[0]) {
      //NON FACCIO CONTROLLI li fa tutti laravel


      const reader = new FileReader();
      reader.readAsDataURL(fileInput.target.files[0]);
      let that = this;

      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function (rs: any) {
          {


            const imgBase64Path = e.target.result;
            that.cardImageBase64 = imgBase64Path;
            that.isImageSaved = true;

            that.formData.fotografia = imgBase64Path;
            // this.previewImagePath = imgBase64Path;
            return true;

          }

        };


      }

      return;
    }
  }

  public removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }


}
