import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NewsService} from "../../services/news/news.service";
import {HistoryService} from "../../services/history/history.service";

@Component({
  selector: 'app-history-detail',
  templateUrl: './history-detail.component.html',
  styleUrls: ['./history-detail.component.scss']
})
export class HistoryDetailComponent implements OnInit {
  mese !: string | null;
  meseHistory: any[] = [];
  keysToShow = [
    "peso",
    "glicemia",
    "pressione_max",
    "pressione_min",
    "collo",
    "braccia",
    "fianchi",
    "vita",
    "cosce",
    "ginocchia",
    "viaggio",
    "ciclo",
    "sgarrato",
  ];
  keysBolean = ['viaggio', 'ciclo', 'sgarrato'];

  constructor(
    private route: ActivatedRoute,
    private historyService: HistoryService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.mese = params.get('id');
      console.log('params', params);

      if (this.mese) {
        this.historyService.get_mese(this.mese).then(risp => {

          if (risp.length) {
            this.meseHistory = risp;
          } else {
            //non ho valori, torno indietro
            this.router.navigate(['/history']);
          }


        }, errore => {
          console.log(errore);
        });
      } else {
        //non ho il parametro id, torno indietro a news list
        this.router.navigate(['/history']);
      }
    })


  }

  is_boolean_key(key:any){
    return this.keysBolean.includes(key);
  }
  openGiornoDetail(giorno: any) {
    alert(giorno);
  }
}
