import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Route, Router} from "@angular/router";
import {NewsService} from "../../services/news/news.service";
import {CommonService} from "../../services/common/common.service";
import {Title} from "@angular/platform-browser";
import {RicetteService} from "../../services/ricette/ricette.service";
import {Ricetta} from "../../models/ricetta";
import {DialogBasicComponent} from "../../components/dialog-basic/dialog-basic.component";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {DialogBasicConfirmComponent} from "../../components/dialog-basic-confirm/dialog-basic-confirm.component";

@Component({
  selector: 'app-ricetta-detail',
  templateUrl: './ricetta-detail.component.html',
  styleUrls: ['./ricetta-detail.component.scss']
})
export class RicettaDetailComponent implements OnInit {

  ricetta:Ricetta|any = null;

  constructor(
      private route: ActivatedRoute,
      private ricetteService: RicetteService,
      private router: Router,
      private commonService: CommonService,
      private title:Title,
      private dialog: MatDialog,
      private translateService: TranslateService,

  ) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      let id = params.get('id');
      console.log('params', params);

      if (id) {
        this.ricetteService.ricette_single(id).then(risp => {

          this.ricetta = new Ricetta(risp);
          //imposto opengraph
          this.title.setTitle( this.ricetta.titolo);

        }, errore => {
          console.log(errore);
        });
      } else {
        //non ho il parametro id, torno indietro a news list
        this.router.navigate(['/lista-ricette']);
      }
    })


  }

  deleteRicetta() {

    let dialogRef = this.dialog.open(DialogBasicConfirmComponent, {
      data: {

        title: this.translateService.instant('vedi_ricetta.cancella.titolo'),
        message: this.translateService.instant('vedi_ricetta.cancella.messaggio'),
        button:
            this.translateService.instant('generico.conferma'),
      },
      panelClass: 'standard-dialog',
    });


    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if(result){
        this.ricetteService.ricetta_delete(this.ricetta.id).then((risp)=>{
          //ok delete
          if(risp.success){
            this.router.navigateByUrl('lista-ricette');
          }else{
            this.commonService.alertError(risp.message);
          }

          },err=>{
          //errore
          console.log('errore delete ricetta',err);



        },
        )
      }
    });


  }

  editRicetta() {
    this.router.navigateByUrl('modifica-ricetta/'+this.ricetta.id);
  }
}
