import {Fase} from "./fase";
import {Percorso} from "./percorso";


export class User {

  id!: string;
  email !: string;


  nome !: string;
  cognome !: string;

  cellulare !: string;
  cadetto !: boolean;

  permessi !: any;

  scadenza !: Date;

  peso !: number;
  altezza !: number;

  fase !: Fase;

  sottofase !: string;

  giorni_mancanti !: number;
  percorso !: Percorso;


  dieta !: {
    id: number;
    nome: string;
  };
  sesso !: string;

  citta !: string;
  occupazione !: string;

  autologins!: {
    "percorso_salute": string,
    "scegli_tu": string,
    "laboratorio_famiglia": string,
    "profilo": string,
  };
  "ecommerce"!: boolean;

  chat_ai_abilitata!: boolean;
}
