import {Component, OnInit, ViewChild} from '@angular/core';
import {MessaggioPersonalizzatoComponent} from "../messaggio-personalizzato/messaggio-personalizzato.component";
import {GeneralService} from "../../services/general/general.service";
import {UserService} from "../../services/user/user.service";
import {BroadcasterService} from "../../services/broadcaster/broadcaster.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-messaggio-personalizzato-box',
  templateUrl: './messaggio-personalizzato-box.component.html',
  styleUrls: ['./messaggio-personalizzato-box.component.scss']
})
export class MessaggioPersonalizzatoBoxComponent implements OnInit {
  messaggio_personalizzato: any = false


  constructor(
    private generalService: GeneralService,
    private userService: UserService,
    private broadcaster: BroadcasterService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {


      this.loadMsgPersonalizzato();


    //ascolto eventuali cambi stato utente
    this.broadcaster.is_user_logged$.subscribe(is_logged => {
      //è cambiato lo stato dell'utente, rifaccio la chiamata

        this.loadMsgPersonalizzato();

    });
  }


  async loadMsgPersonalizzato() {
    this.generalService.messaggio_personalizzato().then(resp => {

      this.messaggio_personalizzato = resp;


    });
  }


  apriNews() {
    if (!this.messaggio_personalizzato.notizia_id) {
      return;
    }
    this.router.navigateByUrl('news-detail/' + this.messaggio_personalizzato.notizia_id)
  }
}
