<div class="reverse-content-space-top"></div>

<div class="container-fluid p-0">

  <app-banner-a2hs></app-banner-a2hs>

  <div class="row g-0">
    <div class="col-md-12">
      <div class="inner-column ">

        <app-recap-ultimi-dati></app-recap-ultimi-dati>

      </div>
    </div>
  </div>

  <!---->
  <div class="row g-0">
    <div class="col-md-12">
      <div class="inner-column ">

        <app-messaggio-personalizzato-box></app-messaggio-personalizzato-box>
        <div class="spacer"></div>

      </div>
    </div>
  </div>


  <div class="row g-0">
    <div class="col-md-12">
      <div class="inner-column ">

        <app-messaggio-giorno></app-messaggio-giorno>

      </div>
    </div>
  </div>


  <div class="row g-0">
    <div class="col-md-12">
      <div class="inner-column ">

        <app-contatti-home></app-contatti-home>

      </div>
    </div>
  </div>

  <div class="blocco-ecommerce" *ngIf="appSettings.ecommerce_attivo" >

    <div class="titolo">
      <h2 class="titolo-tag">{{'generico.prelibatezze'| translate}}</h2>
    </div>

    <div class="row g-0" >
      <div class="col-md-12">
        <div class="inner-column ">

          <app-acquista-lab-famiglia></app-acquista-lab-famiglia>

        </div>
      </div>
    </div>


    <div class="row g-0" *ngIf="user && user.ecommerce">
      <div class="col-md-12">
        <div class="inner-column ">

          <app-acquista-dimagrire></app-acquista-dimagrire>

        </div>
      </div>
    </div>

  </div>

  <div class="row g-0">
    <div class="col-md-12">
      <div class="inner-column ">

        <app-rinnova-app></app-rinnova-app>

      </div>
    </div>
  </div>


  <div class="row g-0">
    <div class="col-md-12">
      <div class="inner-column ">

        <app-universita-box></app-universita-box>

      </div>
    </div>
  </div>

  <div class="row g-0" *ngIf="user && user.ecommerce">
    <div class="col-md-12">
      <div class="inner-column ">

        <app-audioricette-box></app-audioricette-box>

      </div>
    </div>
  </div>


  <div class="row g-0">
    <div class="col-md-12">
      <div class="inner-column ">

        <app-news-latest></app-news-latest>

      </div>
    </div>
  </div>



  <div class="spacer"></div>

  <div class="row g-0">
    <div class="col-md-12">
      <div class="inner-column ">

        <app-news-categorie></app-news-categorie>

      </div>
    </div>
  </div>



  <div class="reverse-content-space-bottom"></div>
</div><!--container-->

