import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StorageService} from "../services/storage/storage.service";

@Injectable({
  providedIn: 'root'
})
export class IsLoggedGuard implements CanActivate {


  constructor(
    private storageService: StorageService,
    private router: Router,
  ) {
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    console.log('GUARDIA IsLoggedGuard');
  //  let dati_utente = this.storageService.get('user');
    let token = this.storageService.get('token');
    console.log('token', token);


    //TODO - finire e controllare pechè da error
    if(token ){
      console.log('GUARDIA IsLoggedGuard - OK');
      return true;
    }else{
      console.log('GUARDIA IsLoggedGuard - KO');
      this.router.navigateByUrl('/login');
      return false;
    }




  }

}
