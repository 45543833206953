import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class ChatAiService {

  constructor(
    private api : ApiService
  ) { }

  async get_storico(): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('lemme_gpt/storico')
        .subscribe((risposta: any) => {


          if (risposta) {
            resolve(risposta.data);
          }


        }, (err: any) => {


          console.log('ERRORE get_Storico()');
          console.log(err);

          reject(err);

        });
    });
  }

  async post_query_genio(messaggio=''): Promise<any> {

    return new Promise((resolve, reject): any => {

      if(!messaggio){
        reject(false)
      }

      this.api.post('lemme_gpt/genio', {
        "testo":messaggio,
      })


        .subscribe((risposta: any) => {
          //console.log('post_risposta', risposta);
          if (risposta && risposta.data && risposta.success == true) {
            resolve(risposta.data);
          } else {

            resolve(false);
          }
        }, (err: any) => {

          resolve(false);

        });
    });
  }

  async post_query_chat(messaggio=''): Promise<any> {

    return new Promise((resolve, reject): any => {

      if(!messaggio){
        reject(false)
      }

      this.api.post('lemme_gpt/chat', {
        "testo":messaggio,
      })


        .subscribe((risposta: any) => {
          //console.log('post_risposta', risposta);
          if (risposta && risposta.data && risposta.success == true) {
            resolve(risposta.data);
          } else {

            resolve(false);
          }
        }, (err: any) => {

          resolve(false);

        });
    });
  }


}
