<div class="inserisci-dati-content">

  <app-blocco-testo
    [titolo]="'inserisci_dati.titolo'|translate"
    [testo]="'inserisci_dati.descrizione'|translate"
  >
  </app-blocco-testo>


  <mat-card class="carica-foto-ext  ">

    <mat-card-content>
      <div class="form-group d-flex">
        <mat-label class="flex-grow-1  ">
          {{'inserisci_dati.vuoi_inserire_foto'|translate}}
        </mat-label>
        <mat-slide-toggle color="primary" [(ngModel)]="carica_foto"></mat-slide-toggle>
      </div>

      <div class="carica-foto  " *ngIf="carica_foto">


        <div class="tipo-foto form-group  ">

          <div class="row align-items-center">
            <div class="col-6">
              <mat-label>{{'inserisci_dati.tipo_foto'|translate}}</mat-label>
            </div>
            <div class="col-6">
              <div class="  select-small">
                <mat-form-field appearance="standard">
                  <mat-select [placeholder]="'inserisci_dati.tipo_foto_scegli'|translate" [required]="carica_foto"
                              [(ngModel)]="formData.tipologia_fotografia">
                    <mat-option *ngFor="let tipo of tipifoto" [value]="tipo">
                      {{'inserisci_dati.tipi_foto.' + tipo|translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>


        </div>

        <div class="upload-ext" *ngIf="formData.tipologia_fotografia">

          <div class="remove-images" *ngIf="cardImageBase64" (click)="removeImage()">
            <mat-icon class="icona">close</mat-icon>
          </div>


          <div class="preview-image" *ngIf="cardImageBase64">
            <img class="img-fluid" [src]="cardImageBase64">
          </div>


          <div class="file-input-buttons d-flex align-items-center justify-content-center">
            <div class="file-input-container">
              <div (click)="fileupld.click()" for="file-upload d-flex" class="custom-file-upload">
                <mat-icon class="icona">upload</mat-icon>
                <label class="testo">{{'inserisci_dati.carica_foto_label'|translate}}</label>
              </div>
              <input id="file-upload" accept="image/*" capture="camera" #fileupld type="file"
                     (change)="fileChange($event)" placeholder="{{'inserisci_dati.carica_foto_label'|translate}}">
            </div>

          </div>


        </div>


      </div>
    </mat-card-content>

  </mat-card>

  <div class="spacer"></div>

  <form class="form-ins-dati">
    <mat-card class="inserisci-dati-card">
      <mat-card-content>


        <div class="form-group">
          <mat-form-field>
            <mat-label>{{ 'generico.data' | translate }}</mat-label>


            <input [name]="'data'" matInput [(ngModel)]="formData.data" (ngModelChange)="dateModelChanged($event)"
                   [matDatepicker]="picker">
            <mat-hint>gg/mm/aaaa</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

          </mat-form-field>
        </div>


        <div class="form-group" *ngFor="let dato of   formData.valori   | keyvalue: returnZero">
          <mat-form-field>
            <mat-label>{{ 'generico.misure.' + dato.key + '.nome' | translate }}
              <span class="um">
              ({{ 'generico.misure.' + dato.key + '.um' | translate }})
            </span>
            </mat-label>

            <input [name]=getfieldName(dato.key) min="0" matInput
                   (change)="changeNumberValue(dato.key,dato.value,'valori')"
                   type="number"
                   [(ngModel)]="dato.value"/>

          </mat-form-field>

        </div>


        <div class="blocco-spunte">
          <div class="form-group d-flex" *ngFor="let dato of formData.spunte | keyvalue: returnZero"
               [hidden]="(user && user.sesso != 'F')&&(dato.key=='ciclo')">
            <mat-label class="flex-grow-1">{{'inserisci_dati.spunte.' + dato.key|translate}}</mat-label>
            <mat-slide-toggle color="primary" [name]="getfieldName(dato.key)"
                              (change)="changeNumberValue(dato.key,dato.value,'spunte')"
                              [(ngModel)]="dato.value"></mat-slide-toggle>
          </div>
        </div>


      </mat-card-content>
    </mat-card>


    <div class="spacer"></div>

    <mat-card class="card-piatti-pronti" *ngIf="canUsePiattiPronti()">

      <mat-card-content>

        <div class="descpiattipronti">
          {{'inserisci_dati.piatti_pronti_desc'|translate}}
        </div>
        <br>


        <div class="utilizzo-piatti-pronti">


          <ngb-accordion (panelChange)="panelHasChanged($event)" [closeOthers]="true">

            <ngb-panel id="st" *ngIf="valoriST">
              <ng-template ngbPanelTitle>
                {{'inserisci_dati.st'|translate}}

              </ng-template>
              <ng-template ngbPanelContent>

                <div class="list-portate">
                  <div class="singola-portata" *ngFor="let portata of valoriST|keyvalue: returnZero">


                    <label id="radio-group-label">{{'generico.portate.' + portata.key|translate}}</label>
                    <mat-radio-group *ngIf="portata.value"
                                     [name]="'st_' + portata.key"

                                     aria-labelledby="radio-group-label"
                                     class="radio-group"
                                     [(ngModel)]="this.formData.st[portata.key]">

                      <mat-radio-button class="radio-button" [value]="0">
                        {{'inserisci_dati.drlemmegenera'|translate}}
                      </mat-radio-button>
                      <mat-radio-button class="radio-button" *ngFor="let piatto of portata.value"
                                        [value]="piatto.id">
                        {{piatto.prodotto.nome }}
                      </mat-radio-button>
                    </mat-radio-group>


                  </div>
                </div>

              </ng-template>
            </ngb-panel>

            <ngb-panel id="ps" *ngIf="valoriPS">
              <ng-template ngbPanelTitle>
                {{'inserisci_dati.ps'|translate}}
              </ng-template>
              <ng-template ngbPanelContent>


                <mat-radio-group *ngIf="valoriPS"
                                 name="'ps_values"

                                 aria-labelledby="ps-label"
                                 class="radio-group"
                                 [(ngModel)]="this.formData.ps">


                  <mat-radio-button class="radio-button" *ngFor="let ps of valoriPS" [value]="ps.id">
                    {{ps.codice }}
                  </mat-radio-button>
                </mat-radio-group>


              </ng-template>
            </ngb-panel>


          </ngb-accordion>


        </div>
      </mat-card-content>
    </mat-card>


    <div class="spacer"></div>


    <div class="smaller-container">

      <mat-card class="card-reaction">
        <mat-card-content>
          <div class="reaction-content">
            <h3 class="titolo">{{ 'inserisci_dati.come_ti_senti' | translate }}</h3>
            <div class="boxReaction row">
              <div class="col" *ngFor="let sing of reaction_possibili" text-right>
                <div class="box-reaction-single" [class]="formData.felicita == sing?'attivo':''">
                  <img (click)="setReaction(sing)" class="img-faccina img-fluid"
                       [src]="'assets/images/reaction/'+sing+'.png'">
                  <!--
                  <mat-icon class="checkFaccina" [hidden]="formData.felicita != sing">check_circle</mat-icon>
-->
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <div class="spacer"></div>


      <div class="invia-dati-cont">
        <div class="ins-dati-audio-obb" *ngIf="file_audio_registrazione_peso">
          <h3>{{'inserisci_dati.audio_per_menu'|translate}}</h3>
          <app-audio-required-player
            [audio]="file_audio_registrazione_peso"
            (fileEndedEvent)="fileFinishedPlay($event)"
          ></app-audio-required-player>

          <div class="spacer"></div>
        </div>

        <!--
        <pre>{{file_audio_registrazione_peso|json}}</pre>
        <pre>{{file_audio_registrazione_peso_ascoltato|json}}</pre>
-->


        <button class="btn btn-lemme-full"
                [disabled]="(file_audio_registrazione_peso && !file_audio_registrazione_peso_ascoltato) || (file_audio_registrazione_peso && !file_audio_registrazione_peso_ascoltato)"
                (click)="sendData()">{{'inserisci_dati.invia'|translate}}</button>
      </div>


    </div>

  </form>


</div>
