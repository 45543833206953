import {Injectable} from '@angular/core';
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private api: ApiService,
  ) {
  }

  async get_mesi(): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('storico/mesi')
        .subscribe((risposta: any) => {

          if (risposta) {
            resolve(risposta.data);
          }
        }, (err: any) => {


          console.log('ERRORE lista_categorie()');
          console.log(err);

          resolve(false);

        });
    });
  }
  async get_mese(mese:string): Promise<any> {
    return new Promise((resolve, reject): any => {


      this.api.get('storico/mesi/'+mese)
        .subscribe((risposta: any) => {

          if (risposta) {
            resolve(risposta.data);
          }
        }, (err: any) => {


          console.log('ERRORE lista_categorie()');
          console.log(err);

          resolve(false);

        });
    });
  }


}
