import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from "../services/user/user.service";
import {StorageService} from "../services/storage/storage.service";

@Injectable({
  providedIn: 'root'
})
export class CanAiGuard implements CanActivate {

  constructor(
    private userService:UserService,
    private router:Router,
    private  storageService:StorageService
  ) {
  }



  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {




    console.log('GUARDIA CanAI');
    //  let dati_utente = this.storageService.get('user');
    let user = this.storageService.get('user');


    if (user) {
      if (user.chat_ai_abilitata) {
        console.log('CanAI-OK');
        return true;
      }
    }
    console.log('CanAI-KO');
    this.router.navigateByUrl('/');
    return false;







  }

}
