<app-blocco-testo
[titolo]="'lista_spesa.titolo'|translate"
[testo]="'lista_spesa.descrizione'|translate"
></app-blocco-testo>

<mat-card>

  <mat-card-content>

    <div class="lista_spesa-cont">


      <div class="pdf-link-cont mb-5" *ngIf="link_pdf">


        <app-bottone-lemme
          (click)="openLink(link_pdf)"
          [titolo]="'lista_spesa.link_pdf'|translate"
          [icona]="'description'"
          [color]="'secondary'"
        ></app-bottone-lemme>


      </div>

      <div class="lista-spesa-list" *ngIf="lista_spesa">

        <div class=" listaSpesa">



          <section class="example-section">

            <div class="checkbox-content"  *ngFor="let item of lista_spesa  ">
              <div class="checkbox-outer d-flex justify-content-between">



                  <mat-checkbox [checked]="ingredienti_salvati.includes(item.nome)" (change)="toggleCheck(item.nome)">

                    <span class="elem-list">
                      <span class="name">
                        {{item.nome}}
                      </span>
                      <span class="qta">
                        x{{item.qta}}
                      </span>
                    </span>

                  </mat-checkbox>

                <mat-icon matListIcon   (click)="openPasti(item.pasti)">info</mat-icon>





              </div>
            </div>

          </section>


        </div>


      </div>


      <div class="nessuna-lista" *ngIf="lista_spesa && !lista_spesa.length">
        <div class="nessuna-lista-inner text-center">
          {{'lista_spesa.nessuna_lista'|translate}}
        </div>


        <div class="cta-inserisci">

          <app-bottone-lemme [titolo]="'generico.inserisci_dati'|translate"
                             [sottotitolo]="'generico.inserisci_dati_sottotitolo'|translate"
                             [icona]="'edit_note'"
                             [routerLink]="'/inserisci-dati'"
                             [color]="'secondary'"
          ></app-bottone-lemme>

        </div>


      </div>



    </div>
  </mat-card-content>
</mat-card>
