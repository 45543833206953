import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor}
  from '@angular/common/http';
import {HttpRequest} from '@angular/common/http';

import {StorageService} from '../services/storage/storage.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BroadcasterService} from "../services/broadcaster/broadcaster.service";
import {CommonService} from "../services/common/common.service";
import {BUILD_INFO} from "../../environments/build-info";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  locked:boolean = false;
  internalRequest: any;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private broadcaster: BroadcasterService,

  ) {
    //console.log('constructorINTERCEPTOR');
  }


  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {


    // console.log('INTERCEPT 123');

    /* */
    //console.log(req,'req');

    this.internalRequest =req;

    this.internalRequest =   this.internalRequest.clone({
      setHeaders: {
        Accept: 'application/json',
        // Pippo: 'pluto'
      }
    });


    // const dati_utente = this.storageService.get('user');
    // console.log('dati_utente', dati_utente);

    let token = this.storageService.get('token');
    let lingua = this.storageService.get('language');
    let versioneApp  = BUILD_INFO.version;


    if (lingua) {
      this.internalRequest = this.internalRequest.clone({
        setHeaders: {
          linguaApp: lingua,
        }
      });
    }

    if (versioneApp) {
      this.internalRequest = this.internalRequest.clone({
        setHeaders: {
          versioneApp: versioneApp,
        }
      });
    }

    /* */
    if (token) {
      this.internalRequest = this.internalRequest.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }


    //console.log('storeageServicegetutente');
    // console.log('new headers', this.internalRequest.headers.keys());
    //return next.handle(this.internalRequest);


    return next.handle(this.internalRequest).pipe(tap(() => {
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {

          console.log('lancio broadcast', err.status);
          this.broadcaster.http_status(err);

          if (err.status == 401) {


            if (token) {

              //errore 401 mostrato solo se in partenza ho inviato un token,
              // se una chiamata senza token torna 401 (es login errato) viene gestita diversamente


              if(!this.locked){
                this.locked = true;

                //TODO tradurre e capire se posso lanciare modal
               // this.commonService.alertError('Errore di autenticazione, effettuare il login.')

                this.storageService.remove('token');
                this.storageService.remove('user');
                this.router.navigateByUrl('/login');


              }

              this.locked = false;

            }

          }

          if (err.status == 422) {

          }


        }
      }));

  }


}
