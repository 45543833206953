import { Component, OnInit } from '@angular/core';
import {BroadcasterService} from "../../services/broadcaster/broadcaster.service";

@Component({
  selector: 'app-loader-pagina',
  templateUrl: './loader-pagina.component.html',
  styleUrls: ['./loader-pagina.component.scss']
})
export class LoaderPaginaComponent implements OnInit {

  showloader: boolean = false;
  timeout :number  = 10000;

  constructor(
    private broadcaster: BroadcasterService,
  ) {
    this.listenBroadcaster();
  }



  ngOnInit(): void {
  }

  showLoader() {
    console.log('showLoader')
    this.showloader = true;
    setTimeout(() => {
      if(this.showloader){
        console.log('showLoader - TIMEOUT')
        this.hideLoader();
      }

    }, this.timeout);
  }

  hideLoader() {
    console.log('hideLoader')
    this.showloader = false;
  }


  listenBroadcaster() {
    //ascolto eventuali boradcast per il loader
    this.broadcaster.is_loader_shown$.subscribe(to_show => {
      //è cambiato lo stato dell'utente, rifaccio la chiamata
      if (to_show) {
        this.showLoader();
      } else {
        this.hideLoader()
      }
    });
  }
}
