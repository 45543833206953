<app-blocco-testo
  [titolo]="'lista_ricette.titolo'|translate"
  [testo]="'lista_ricette.descrizione'|translate"
></app-blocco-testo>


<div class="ricettario-content" *ngIf="user">



  <div class="ricette-abilitate" *ngIf="userCanRicettario()">

    <div class="cta-inserisci">
      <app-bottone-lemme [titolo]="'lista_ricette.inserisci_ricetta'|translate"
                         [icona]="'edit_note'"
                         [routerLink]="'/inserisci-ricetta'"
                         [color]="'secondary'"
      ></app-bottone-lemme>
    </div>

    <div class="spacer"></div>

    <mat-card class="lista-ricette-ext" >

      <mat-card-content>



        <div class="lista_ricette-cont">

          <div class="list" *ngIf="lista_ricette.length">

            <mat-nav-list>
              <mat-list-item   *ngFor="let ricetta of lista_ricette"
                               [routerLink]="['/ricetta-detail', ricetta.id]"
              >
                <div matLine  >
                  <div class="row  align-items-center">
                    <div class="col-3">

                      <div class="foto">
                        <img class="img-fluid" [src]="ricetta.foto" />
                      </div>

                    </div>
                    <div class="col-9">

                      <div class="testo">
                        <div class="titolo">{{ricetta.titolo }}</div>
                        <div class="pasti" *ngIf="ricetta.pasti">{{ricetta.pasti.join(', ')}}</div>

                        <div class="abilitato">
                          <mat-icon *ngIf="ricetta.abilitata">check_circle</mat-icon>
                          <mat-icon *ngIf="!ricetta.abilitata">pause_circle</mat-icon>
                        </div>
                      </div>

                    </div>
                  </div>




                </div>
                <button mat-icon-button>
                  <mat-icon>chevron_right</mat-icon>
                </button>
                <mat-divider></mat-divider>
              </mat-list-item>
            </mat-nav-list>

          </div>





          <div class="nessuna-lista" *ngIf="lista_ricette && !lista_ricette.length">
            <div class="nessuna-lista-inner text-center">
              {{'lista_ricette.nessuna_lista'|translate}}
            </div>


          </div>


        </div>
      </mat-card-content>
    </mat-card>


  </div>



  <div class="ricettario-disabilitato" *ngIf="!userCanRicettario()">
    <mat-card class="user-locked-ricettario" >

      <mat-card-content>

        <div class="errore-acesso-ricettario">
          <div class="testo" [innerHTML]="'lista_ricette.blocco_sezione'|translate"></div>
        </div>

      </mat-card-content>
    </mat-card>
  </div>



</div>

