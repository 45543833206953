import {Component, Input, OnInit} from '@angular/core';
import {Notizia} from "../../models/notizia";

@Component({
  selector: 'app-news-loop-list',
  templateUrl: './news-loop-list.component.html',
  styleUrls: ['./news-loop-list.component.scss']
})
export class NewsLoopListComponent implements OnInit {

  @Input() news !:Notizia ; // decorate the property with @Input()


  constructor() { }

  ngOnInit(): void {
  }


}
