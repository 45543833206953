<mat-card>
  <mat-card-title>{{'universita.titolo'|translate}}</mat-card-title>
  <mat-card-content>
    <div class="universita-cont">

      <div class="descrizione">
        {{'universita.descrizione'|translate}}
      </div>
      <hr>
      <div class="no-result-list" *ngIf="lezioni &&  lezioni.length==0">
        {{'universita.nessuna_lezione'|translate}}
      </div>

      <div class="universita-menu-inner">


        <div class="list" *ngIf="lezioni && lezioni.length">

          <mat-nav-list>
            <mat-list-item *ngFor="let lezione of lezioni"
                           [routerLink]="['/universita/lezione', lezione.id]"
            >
              <div matLine>

                <span class="titolo">{{lezione.titolo}}</span>
              </div>
              <button mat-icon-button>
                <mat-icon>chevron_right</mat-icon>
              </button>
              <mat-divider></mat-divider>
            </mat-list-item>
          </mat-nav-list>

        </div>


      </div>

    </div>
  </mat-card-content>
</mat-card>


<div class="no-result-contact" *ngIf="lezioni &&  lezioni.length==0">
  <app-contatti-home></app-contatti-home>
</div>
