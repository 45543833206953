
import { Component, OnInit } from '@angular/core';
import {ChatAiService} from "../../services/chat-ai/chat-ai.service";

@Component({
  selector: 'app-genio-ai',
  templateUrl: './genio-ai.component.html',
  styleUrls: ['./genio-ai.component.scss']
})
export class GenioAiComponent implements OnInit {

  is_loading: boolean = false;
  messaggio_utente: string = '';
  risposta: any;

  constructor(
    private chatAi:ChatAiService,
  ) { }

  ngOnInit(): void {
  }

  sendMessage() {
    console.log(this.messaggio_utente);

    if(!this.messaggio_utente){
      return;
    }

    
    this.is_loading = true;
    this.risposta = '';

    this.chatAi.post_query_genio(this.messaggio_utente).then(risp=>{

      console.log('risp', risp);
      this.risposta =  risp;
    }).finally(()=>{
      this.is_loading = false;
    });

  }

  resetDomanda() {
    this.messaggio_utente  = '';
    this.risposta = '';
  }
}
