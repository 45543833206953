<mat-card *ngIf="categorie" class="full flat">
  <mat-card-content>

    <mat-list>
      <mat-list-item *ngFor="let categoria of categorie;let i = index">
        <a class="link-cat" [routerLink]="['/news-list/'+categoria.id]">
          <div class="testo">
            {{categoria.nome}}
          </div>
        </a>
        <div class="immagine">
          <!--
          <img [src]="'assets/images/news/'+categoria.id+'.png'" alt="">

          -->

          <mat-icon>{{iconlist[categoria.id]}}</mat-icon>
        </div>


      </mat-list-item>
    </mat-list>

  </mat-card-content>
</mat-card>
