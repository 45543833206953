<div class="share-button-content">

  <h2 class="titolo-condividi">{{'generico.condividi'|translate}}</h2>
  <div class="share-inner">
    <share-buttons
      [include]="['facebook', 'twitter', 'whatsapp', 'telegram', 'linkedin','messenger']"
      [url]="url"
    ></share-buttons>

  </div>
</div>
