<mat-card class="news-latest" *ngIf="news">

  <mat-card-header>
    <mat-card-title>{{news.titolo}}</mat-card-title>
  </mat-card-header>
  <img mat-card-image [src]="news.img" *ngIf="news.img">

  <!--  -->
  <mat-card-content>
    <div class="contenuto-news" [innerHTML]="news.descrizione_breve"></div>
  </mat-card-content>


  <app-bottone-lemme
    [routerLink]="['/news-detail', news.id]"
    [titolo]="'generico.scopri_piu'|translate"
    icona="feed"
  ></app-bottone-lemme>

</mat-card>
