<h1 mat-dialog-title>{{'singolo_capitolo.'+data.tipo|translate}}</h1>
<div mat-dialog-content>
  <div class="form-risposta-cont">
    {{'singolo_capitolo.scrivi_form_'+data.tipo|translate}}
  </div>


  <form class="assistenza-form " [formGroup]="form" (ngSubmit)="onSubmit()">

    <div class="form-group">

      <mat-form-field class=" w-100">
        <mat-label for="testo">{{'universita.messaggio'|translate}}</mat-label>
        <textarea matInput formControlName="testo" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.testo.errors }"

        ></textarea>
        <div *ngIf="submitted && f.testo.errors" class="invalid-feedback">
          <mat-error *ngIf="f.testo.errors.required">{{'universita.messaggio_richiesto'|translate}}</mat-error>
        </div>

      </mat-form-field>

    </div>
    <div class="form-group">
      <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{'generico.invio'|translate}}
      </button>
      <button mat-button mat-dialog-close>{{'generico.chiudi'|translate}}</button>

    </div>
  </form>


</div>

